/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {};
export var AccessibilityIssueSolutionSuggestionOrderField;
(function (AccessibilityIssueSolutionSuggestionOrderField) {
    AccessibilityIssueSolutionSuggestionOrderField["CreatedAt"] = "createdAt";
    AccessibilityIssueSolutionSuggestionOrderField["UpdatedAt"] = "updatedAt";
})(AccessibilityIssueSolutionSuggestionOrderField || (AccessibilityIssueSolutionSuggestionOrderField = {}));
export var AccountFeatureFlagOrderField;
(function (AccountFeatureFlagOrderField) {
    AccountFeatureFlagOrderField["AccountId"] = "accountId";
    AccountFeatureFlagOrderField["CreatedAt"] = "createdAt";
    AccountFeatureFlagOrderField["Enabled"] = "enabled";
    AccountFeatureFlagOrderField["Name"] = "name";
    AccountFeatureFlagOrderField["UpdatedAt"] = "updatedAt";
})(AccountFeatureFlagOrderField || (AccountFeatureFlagOrderField = {}));
export var AccountOrderField;
(function (AccountOrderField) {
    AccountOrderField["Active"] = "active";
    AccountOrderField["AiFeaturesEnabled"] = "aiFeaturesEnabled";
    AccountOrderField["CreatedAt"] = "createdAt";
    AccountOrderField["Id"] = "id";
    AccountOrderField["Name"] = "name";
    AccountOrderField["PackagePlan"] = "packagePlan";
    AccountOrderField["UpdatedAt"] = "updatedAt";
})(AccountOrderField || (AccountOrderField = {}));
export var AccountPackageType;
(function (AccountPackageType) {
    AccountPackageType["Archived"] = "Archived";
    AccountPackageType["PartnerSubscription"] = "PartnerSubscription";
    AccountPackageType["Subscription"] = "Subscription";
    AccountPackageType["Trial"] = "Trial";
})(AccountPackageType || (AccountPackageType = {}));
export var AccountSsoClientOrderField;
(function (AccountSsoClientOrderField) {
    AccountSsoClientOrderField["AccountId"] = "accountId";
    AccountSsoClientOrderField["SsoClientId"] = "ssoClientId";
})(AccountSsoClientOrderField || (AccountSsoClientOrderField = {}));
export var AccountSettingDataType;
(function (AccountSettingDataType) {
    AccountSettingDataType["Boolean"] = "Boolean";
    AccountSettingDataType["Number"] = "Number";
})(AccountSettingDataType || (AccountSettingDataType = {}));
export var AccountSettingSource;
(function (AccountSettingSource) {
    AccountSettingSource["Manual"] = "Manual";
    AccountSettingSource["Package"] = "Package";
})(AccountSettingSource || (AccountSettingSource = {}));
export var AccountSettingType;
(function (AccountSettingType) {
    AccountSettingType["IntegrationAddons"] = "IntegrationAddons";
    AccountSettingType["SubscriptionSettings"] = "SubscriptionSettings";
})(AccountSettingType || (AccountSettingType = {}));
export var AddonType;
(function (AddonType) {
    AddonType["IntegrationAddon"] = "IntegrationAddon";
    AddonType["ProjectAddon"] = "ProjectAddon";
    AddonType["UrlAddon"] = "UrlAddon";
})(AddonType || (AddonType = {}));
export var AdobeAnalyticsProjectReportSuiteOrderField;
(function (AdobeAnalyticsProjectReportSuiteOrderField) {
    AdobeAnalyticsProjectReportSuiteOrderField["AdobeConnectionId"] = "adobeConnectionId";
    AdobeAnalyticsProjectReportSuiteOrderField["AdobeJwtConnectionId"] = "adobeJwtConnectionId";
    AdobeAnalyticsProjectReportSuiteOrderField["AdobeOauthServerToServerConnectionId"] = "adobeOauthServerToServerConnectionId";
    AdobeAnalyticsProjectReportSuiteOrderField["CreatedAt"] = "createdAt";
    AdobeAnalyticsProjectReportSuiteOrderField["MaxRows"] = "maxRows";
    AdobeAnalyticsProjectReportSuiteOrderField["MinimumVisits"] = "minimumVisits";
    AdobeAnalyticsProjectReportSuiteOrderField["NumberOfDays"] = "numberOfDays";
    AdobeAnalyticsProjectReportSuiteOrderField["PerPage"] = "perPage";
    AdobeAnalyticsProjectReportSuiteOrderField["ReportSuiteId"] = "reportSuiteId";
    AdobeAnalyticsProjectReportSuiteOrderField["SuiteName"] = "suiteName";
    AdobeAnalyticsProjectReportSuiteOrderField["UpdatedAt"] = "updatedAt";
})(AdobeAnalyticsProjectReportSuiteOrderField || (AdobeAnalyticsProjectReportSuiteOrderField = {}));
export var AdobeConnectionOrderField;
(function (AdobeConnectionOrderField) {
    AdobeConnectionOrderField["Company"] = "company";
    AdobeConnectionOrderField["CreatedAt"] = "createdAt";
    AdobeConnectionOrderField["UpdatedAt"] = "updatedAt";
    AdobeConnectionOrderField["Username"] = "username";
})(AdobeConnectionOrderField || (AdobeConnectionOrderField = {}));
export var AdobeJwtConnectionOrderField;
(function (AdobeJwtConnectionOrderField) {
    AdobeJwtConnectionOrderField["ClientId"] = "clientId";
    AdobeJwtConnectionOrderField["CreatedAt"] = "createdAt";
    AdobeJwtConnectionOrderField["OrgId"] = "orgId";
    AdobeJwtConnectionOrderField["TechnicalAccountId"] = "technicalAccountId";
    AdobeJwtConnectionOrderField["UpdatedAt"] = "updatedAt";
})(AdobeJwtConnectionOrderField || (AdobeJwtConnectionOrderField = {}));
export var AdobeOauthServerToServerConnectionOrderField;
(function (AdobeOauthServerToServerConnectionOrderField) {
    AdobeOauthServerToServerConnectionOrderField["ClientId"] = "clientId";
    AdobeOauthServerToServerConnectionOrderField["CreatedAt"] = "createdAt";
    AdobeOauthServerToServerConnectionOrderField["UpdatedAt"] = "updatedAt";
})(AdobeOauthServerToServerConnectionOrderField || (AdobeOauthServerToServerConnectionOrderField = {}));
export var AdvancedCrawlRateDay;
(function (AdvancedCrawlRateDay) {
    AdvancedCrawlRateDay["Friday"] = "Friday";
    AdvancedCrawlRateDay["Monday"] = "Monday";
    AdvancedCrawlRateDay["Saturday"] = "Saturday";
    AdvancedCrawlRateDay["Sunday"] = "Sunday";
    AdvancedCrawlRateDay["Thursday"] = "Thursday";
    AdvancedCrawlRateDay["Tuesday"] = "Tuesday";
    AdvancedCrawlRateDay["Wednesday"] = "Wednesday";
})(AdvancedCrawlRateDay || (AdvancedCrawlRateDay = {}));
export var AdvancedCrawlRateType;
(function (AdvancedCrawlRateType) {
    AdvancedCrawlRateType["Daily"] = "Daily";
    AdvancedCrawlRateType["Weekly"] = "Weekly";
})(AdvancedCrawlRateType || (AdvancedCrawlRateType = {}));
export var AlertSettingCode;
(function (AlertSettingCode) {
    AlertSettingCode["Always"] = "Always";
    AlertSettingCode["Never"] = "Never";
})(AlertSettingCode || (AlertSettingCode = {}));
export var AlertType;
(function (AlertType) {
    AlertType["Fail"] = "Fail";
    AlertType["Pass"] = "Pass";
    AlertType["Warning"] = "Warning";
})(AlertType || (AlertType = {}));
export var BuildOrderField;
(function (BuildOrderField) {
    BuildOrderField["CiBuildId"] = "ciBuildId";
    BuildOrderField["CreatedAt"] = "createdAt";
    BuildOrderField["FailedHealthScoreTestCount"] = "failedHealthScoreTestCount";
    BuildOrderField["FailedTestCount"] = "failedTestCount";
    BuildOrderField["FinishedAt"] = "finishedAt";
    BuildOrderField["Id"] = "id";
    BuildOrderField["MissingHealthScoreTestCount"] = "missingHealthScoreTestCount";
    BuildOrderField["MissingTestCount"] = "missingTestCount";
    BuildOrderField["Passed"] = "passed";
    BuildOrderField["PassedHealthScoreTestCount"] = "passedHealthScoreTestCount";
    BuildOrderField["PassedTestCount"] = "passedTestCount";
    BuildOrderField["Status"] = "status";
    BuildOrderField["UpdatedAt"] = "updatedAt";
    BuildOrderField["WarnedHealthScoreTestCount"] = "warnedHealthScoreTestCount";
    BuildOrderField["WarnedTestCount"] = "warnedTestCount";
})(BuildOrderField || (BuildOrderField = {}));
export var BuildScheduleOrderField;
(function (BuildScheduleOrderField) {
    BuildScheduleOrderField["CreatedAt"] = "createdAt";
    BuildScheduleOrderField["Id"] = "id";
    BuildScheduleOrderField["LastRunStartedAt"] = "lastRunStartedAt";
    BuildScheduleOrderField["Name"] = "name";
    BuildScheduleOrderField["NextRunAt"] = "nextRunAt";
    BuildScheduleOrderField["StartAt"] = "startAt";
    BuildScheduleOrderField["UpdatedAt"] = "updatedAt";
})(BuildScheduleOrderField || (BuildScheduleOrderField = {}));
export var BuildScheduleRepetitionRate;
(function (BuildScheduleRepetitionRate) {
    BuildScheduleRepetitionRate["Daily"] = "Daily";
    BuildScheduleRepetitionRate["EveryFourHours"] = "EveryFourHours";
    BuildScheduleRepetitionRate["EveryTwelveHours"] = "EveryTwelveHours";
    BuildScheduleRepetitionRate["EveryWeekday"] = "EveryWeekday";
    BuildScheduleRepetitionRate["Hourly"] = "Hourly";
    BuildScheduleRepetitionRate["Monthly"] = "Monthly";
    BuildScheduleRepetitionRate["Weekly"] = "Weekly";
})(BuildScheduleRepetitionRate || (BuildScheduleRepetitionRate = {}));
export var BuildScheduleRunOrderField;
(function (BuildScheduleRunOrderField) {
    BuildScheduleRunOrderField["CreatedAt"] = "createdAt";
    BuildScheduleRunOrderField["Id"] = "id";
    BuildScheduleRunOrderField["StartedAt"] = "startedAt";
    BuildScheduleRunOrderField["UpdatedAt"] = "updatedAt";
})(BuildScheduleRunOrderField || (BuildScheduleRunOrderField = {}));
export var BuildScheduleTestSuiteOrderField;
(function (BuildScheduleTestSuiteOrderField) {
    BuildScheduleTestSuiteOrderField["CreatedAt"] = "createdAt";
    BuildScheduleTestSuiteOrderField["Id"] = "id";
})(BuildScheduleTestSuiteOrderField || (BuildScheduleTestSuiteOrderField = {}));
export var BuildStatus;
(function (BuildStatus) {
    BuildStatus["Aborted"] = "Aborted";
    BuildStatus["Cancelled"] = "Cancelled";
    BuildStatus["Finished"] = "Finished";
    BuildStatus["Queued"] = "Queued";
    BuildStatus["Running"] = "Running";
})(BuildStatus || (BuildStatus = {}));
export var BuildTriggerType;
(function (BuildTriggerType) {
    BuildTriggerType["Ci"] = "CI";
    BuildTriggerType["Manual"] = "Manual";
    BuildTriggerType["Schedule"] = "Schedule";
})(BuildTriggerType || (BuildTriggerType = {}));
export var ChargebeeSiteRef;
(function (ChargebeeSiteRef) {
    ChargebeeSiteRef["Eur"] = "EUR";
    ChargebeeSiteRef["Gbp"] = "GBP";
    ChargebeeSiteRef["Jpy"] = "JPY";
    ChargebeeSiteRef["Usd"] = "USD";
    ChargebeeSiteRef["Usd2"] = "USD2";
})(ChargebeeSiteRef || (ChargebeeSiteRef = {}));
export var CompareToCrawlType;
(function (CompareToCrawlType) {
    CompareToCrawlType["LastCrawl"] = "LastCrawl";
    CompareToCrawlType["None"] = "None";
})(CompareToCrawlType || (CompareToCrawlType = {}));
export var ConnectionPredicate;
(function (ConnectionPredicate) {
    ConnectionPredicate["ArrayContains"] = "arrayContains";
    ConnectionPredicate["ArrayContainsLike"] = "arrayContainsLike";
    ConnectionPredicate["ArrayNotContains"] = "arrayNotContains";
    ConnectionPredicate["ArrayNotContainsLike"] = "arrayNotContainsLike";
    ConnectionPredicate["BeginsWith"] = "beginsWith";
    ConnectionPredicate["Contains"] = "contains";
    ConnectionPredicate["EndsWith"] = "endsWith";
    ConnectionPredicate["Eq"] = "eq";
    ConnectionPredicate["Ge"] = "ge";
    ConnectionPredicate["Gt"] = "gt";
    ConnectionPredicate["In"] = "in";
    ConnectionPredicate["IsEmpty"] = "isEmpty";
    ConnectionPredicate["IsNull"] = "isNull";
    ConnectionPredicate["Le"] = "le";
    ConnectionPredicate["Lt"] = "lt";
    ConnectionPredicate["MatchesRegex"] = "matchesRegex";
    ConnectionPredicate["Ne"] = "ne";
    ConnectionPredicate["NotContains"] = "notContains";
    ConnectionPredicate["NotIn"] = "notIn";
    ConnectionPredicate["NotMatchesRegex"] = "notMatchesRegex";
})(ConnectionPredicate || (ConnectionPredicate = {}));
export var CrawlAccessibilityIssueInstanceOrderField;
(function (CrawlAccessibilityIssueInstanceOrderField) {
    CrawlAccessibilityIssueInstanceOrderField["AxeResultsGroup"] = "axeResultsGroup";
    CrawlAccessibilityIssueInstanceOrderField["BgAverage"] = "bgAverage";
    CrawlAccessibilityIssueInstanceOrderField["BgMaxColor"] = "bgMaxColor";
    CrawlAccessibilityIssueInstanceOrderField["BgMinColor"] = "bgMinColor";
    CrawlAccessibilityIssueInstanceOrderField["BgStdv"] = "bgStdv";
    CrawlAccessibilityIssueInstanceOrderField["Category"] = "category";
    CrawlAccessibilityIssueInstanceOrderField["ComputedDescription"] = "computedDescription";
    CrawlAccessibilityIssueInstanceOrderField["ComputedName"] = "computedName";
    CrawlAccessibilityIssueInstanceOrderField["ContrastRatio"] = "contrastRatio";
    CrawlAccessibilityIssueInstanceOrderField["ContrastRatioAverage"] = "contrastRatioAverage";
    CrawlAccessibilityIssueInstanceOrderField["ElementSelector"] = "elementSelector";
    CrawlAccessibilityIssueInstanceOrderField["ElementSelectorDigest"] = "elementSelectorDigest";
    CrawlAccessibilityIssueInstanceOrderField["ElementSource"] = "elementSource";
    CrawlAccessibilityIssueInstanceOrderField["FontColor"] = "fontColor";
    CrawlAccessibilityIssueInstanceOrderField["FontSize"] = "fontSize";
    CrawlAccessibilityIssueInstanceOrderField["FontWeight"] = "fontWeight";
    CrawlAccessibilityIssueInstanceOrderField["GaPageviews"] = "gaPageviews";
    CrawlAccessibilityIssueInstanceOrderField["GaVisits"] = "gaVisits";
    CrawlAccessibilityIssueInstanceOrderField["GscClicks"] = "gscClicks";
    CrawlAccessibilityIssueInstanceOrderField["InstanceIndex"] = "instanceIndex";
    CrawlAccessibilityIssueInstanceOrderField["IsFontLarge"] = "isFontLarge";
    CrawlAccessibilityIssueInstanceOrderField["IsImageInAnchor"] = "isImageInAnchor";
    CrawlAccessibilityIssueInstanceOrderField["IssueDescription"] = "issueDescription";
    CrawlAccessibilityIssueInstanceOrderField["IssueDigest"] = "issueDigest";
    CrawlAccessibilityIssueInstanceOrderField["IssueSeverity"] = "issueSeverity";
    CrawlAccessibilityIssueInstanceOrderField["IssueType"] = "issueType";
    CrawlAccessibilityIssueInstanceOrderField["LegalCount"] = "legalCount";
    CrawlAccessibilityIssueInstanceOrderField["NeedsManualReview"] = "needsManualReview";
    CrawlAccessibilityIssueInstanceOrderField["PassProbability"] = "passProbability";
    CrawlAccessibilityIssueInstanceOrderField["RuleId"] = "ruleId";
    CrawlAccessibilityIssueInstanceOrderField["SafeContrastZone"] = "safeContrastZone";
    CrawlAccessibilityIssueInstanceOrderField["SegmentIdsCount"] = "segmentIdsCount";
    CrawlAccessibilityIssueInstanceOrderField["SegmentNamesCount"] = "segmentNamesCount";
    CrawlAccessibilityIssueInstanceOrderField["StDevWeightedRgb"] = "stDevWeightedRgb";
    CrawlAccessibilityIssueInstanceOrderField["SurroundingTextColor"] = "surroundingTextColor";
    CrawlAccessibilityIssueInstanceOrderField["Url"] = "url";
    CrawlAccessibilityIssueInstanceOrderField["UrlDigest"] = "urlDigest";
    CrawlAccessibilityIssueInstanceOrderField["WcagLevel"] = "wcagLevel";
    CrawlAccessibilityIssueInstanceOrderField["WcagSuccessCriteria"] = "wcagSuccessCriteria";
    CrawlAccessibilityIssueInstanceOrderField["WcagVersion"] = "wcagVersion";
})(CrawlAccessibilityIssueInstanceOrderField || (CrawlAccessibilityIssueInstanceOrderField = {}));
export var CrawlAccessibilityIssueOrderField;
(function (CrawlAccessibilityIssueOrderField) {
    CrawlAccessibilityIssueOrderField["AxeResultsGroup"] = "axeResultsGroup";
    CrawlAccessibilityIssueOrderField["BgAverage"] = "bgAverage";
    CrawlAccessibilityIssueOrderField["BgMaxColor"] = "bgMaxColor";
    CrawlAccessibilityIssueOrderField["BgMinColor"] = "bgMinColor";
    CrawlAccessibilityIssueOrderField["BgStdv"] = "bgStdv";
    CrawlAccessibilityIssueOrderField["Category"] = "category";
    CrawlAccessibilityIssueOrderField["ComputedDescription"] = "computedDescription";
    CrawlAccessibilityIssueOrderField["ComputedName"] = "computedName";
    CrawlAccessibilityIssueOrderField["ContrastRatio"] = "contrastRatio";
    CrawlAccessibilityIssueOrderField["ContrastRatioAverage"] = "contrastRatioAverage";
    CrawlAccessibilityIssueOrderField["ElementSelectorCount"] = "elementSelectorCount";
    CrawlAccessibilityIssueOrderField["ElementSource"] = "elementSource";
    CrawlAccessibilityIssueOrderField["ExampleElementSelector"] = "exampleElementSelector";
    CrawlAccessibilityIssueOrderField["ExampleUrl"] = "exampleUrl";
    CrawlAccessibilityIssueOrderField["ExampleUrlDigest"] = "exampleUrlDigest";
    CrawlAccessibilityIssueOrderField["FontColor"] = "fontColor";
    CrawlAccessibilityIssueOrderField["FontSize"] = "fontSize";
    CrawlAccessibilityIssueOrderField["FontWeight"] = "fontWeight";
    CrawlAccessibilityIssueOrderField["GaPageviews"] = "gaPageviews";
    CrawlAccessibilityIssueOrderField["GaVisits"] = "gaVisits";
    CrawlAccessibilityIssueOrderField["GscClicks"] = "gscClicks";
    CrawlAccessibilityIssueOrderField["IsFontLarge"] = "isFontLarge";
    CrawlAccessibilityIssueOrderField["IsImageInAnchor"] = "isImageInAnchor";
    CrawlAccessibilityIssueOrderField["IssueDescription"] = "issueDescription";
    CrawlAccessibilityIssueOrderField["IssueDigest"] = "issueDigest";
    CrawlAccessibilityIssueOrderField["IssueSeverity"] = "issueSeverity";
    CrawlAccessibilityIssueOrderField["IssueType"] = "issueType";
    CrawlAccessibilityIssueOrderField["LegalCount"] = "legalCount";
    CrawlAccessibilityIssueOrderField["ManualReviewNeededCount"] = "manualReviewNeededCount";
    CrawlAccessibilityIssueOrderField["NeedsManualReview"] = "needsManualReview";
    CrawlAccessibilityIssueOrderField["NoManualReviewNeededCount"] = "noManualReviewNeededCount";
    CrawlAccessibilityIssueOrderField["PassProbability"] = "passProbability";
    CrawlAccessibilityIssueOrderField["RuleId"] = "ruleId";
    CrawlAccessibilityIssueOrderField["SafeContrastZone"] = "safeContrastZone";
    CrawlAccessibilityIssueOrderField["SegmentIdsCount"] = "segmentIdsCount";
    CrawlAccessibilityIssueOrderField["SegmentNamesCount"] = "segmentNamesCount";
    CrawlAccessibilityIssueOrderField["StDevWeightedRgb"] = "stDevWeightedRgb";
    CrawlAccessibilityIssueOrderField["SurroundingTextColor"] = "surroundingTextColor";
    CrawlAccessibilityIssueOrderField["UrlCount"] = "urlCount";
    CrawlAccessibilityIssueOrderField["WcagLevel"] = "wcagLevel";
    CrawlAccessibilityIssueOrderField["WcagSuccessCriteria"] = "wcagSuccessCriteria";
    CrawlAccessibilityIssueOrderField["WcagVersion"] = "wcagVersion";
})(CrawlAccessibilityIssueOrderField || (CrawlAccessibilityIssueOrderField = {}));
export var CrawlDatasourceOrderField;
(function (CrawlDatasourceOrderField) {
    CrawlDatasourceOrderField["DatasourceCode"] = "datasourceCode";
})(CrawlDatasourceOrderField || (CrawlDatasourceOrderField = {}));
export var CrawlDuplicateUrlOrderField;
(function (CrawlDuplicateUrlOrderField) {
    CrawlDuplicateUrlOrderField["CanonicalUrl"] = "canonicalUrl";
    CrawlDuplicateUrlOrderField["ContentSize"] = "contentSize";
    CrawlDuplicateUrlOrderField["Deeprank"] = "deeprank";
    CrawlDuplicateUrlOrderField["Description"] = "description";
    CrawlDuplicateUrlOrderField["DescriptionLength"] = "descriptionLength";
    CrawlDuplicateUrlOrderField["DescriptionLengthPx"] = "descriptionLengthPx";
    CrawlDuplicateUrlOrderField["DuplicateCount"] = "duplicateCount";
    CrawlDuplicateUrlOrderField["DuplicateNonIndexable"] = "duplicateNonIndexable";
    CrawlDuplicateUrlOrderField["DuplicateType"] = "duplicateType";
    CrawlDuplicateUrlOrderField["ExampleDuplicate1"] = "exampleDuplicate1";
    CrawlDuplicateUrlOrderField["ExampleDuplicate2"] = "exampleDuplicate2";
    CrawlDuplicateUrlOrderField["FoundAtSitemap"] = "foundAtSitemap";
    CrawlDuplicateUrlOrderField["FoundAtUrl"] = "foundAtUrl";
    CrawlDuplicateUrlOrderField["H1TagCount"] = "h1TagCount";
    CrawlDuplicateUrlOrderField["HtmlSize"] = "htmlSize";
    CrawlDuplicateUrlOrderField["HttpStatusCode"] = "httpStatusCode";
    CrawlDuplicateUrlOrderField["Indexable"] = "indexable";
    CrawlDuplicateUrlOrderField["Level"] = "level";
    CrawlDuplicateUrlOrderField["OgTitle"] = "ogTitle";
    CrawlDuplicateUrlOrderField["PageTitle"] = "pageTitle";
    CrawlDuplicateUrlOrderField["PageTitleLength"] = "pageTitleLength";
    CrawlDuplicateUrlOrderField["PageTitleLengthPx"] = "pageTitleLengthPx";
    CrawlDuplicateUrlOrderField["PrimaryUrl"] = "primaryUrl";
    CrawlDuplicateUrlOrderField["WordCount"] = "wordCount";
})(CrawlDuplicateUrlOrderField || (CrawlDuplicateUrlOrderField = {}));
export var CrawlFsmState;
(function (CrawlFsmState) {
    CrawlFsmState["Archived"] = "Archived";
    CrawlFsmState["Archiving"] = "Archiving";
    CrawlFsmState["Canceled"] = "Canceled";
    CrawlFsmState["Canceling"] = "Canceling";
    CrawlFsmState["Crawled1"] = "Crawled1";
    CrawlFsmState["Crawled2"] = "Crawled2";
    CrawlFsmState["Crawling1"] = "Crawling1";
    CrawlFsmState["Crawling2"] = "Crawling2";
    CrawlFsmState["Deleting"] = "Deleting";
    CrawlFsmState["DiscoveredUrLs"] = "DiscoveredURLs";
    CrawlFsmState["DiscoveringUrLs"] = "DiscoveringURLs";
    CrawlFsmState["Draft"] = "Draft";
    CrawlFsmState["Errored"] = "Errored";
    CrawlFsmState["Finished"] = "Finished";
    CrawlFsmState["Migrated"] = "Migrated";
    CrawlFsmState["Migrating"] = "Migrating";
    CrawlFsmState["Paused1"] = "Paused1";
    CrawlFsmState["Paused2"] = "Paused2";
    CrawlFsmState["Populated"] = "Populated";
    CrawlFsmState["Populating"] = "Populating";
    CrawlFsmState["PostTransformed"] = "PostTransformed";
    CrawlFsmState["PostTransforming"] = "PostTransforming";
    CrawlFsmState["Processed"] = "Processed";
    CrawlFsmState["Processing"] = "Processing";
    CrawlFsmState["Queued"] = "Queued";
    CrawlFsmState["Queueing"] = "Queueing";
    CrawlFsmState["Transformed"] = "Transformed";
    CrawlFsmState["Transforming"] = "Transforming";
})(CrawlFsmState || (CrawlFsmState = {}));
export var CrawlHreflangsOrderField;
(function (CrawlHreflangsOrderField) {
    CrawlHreflangsOrderField["HreflangsCount"] = "hreflangsCount";
    CrawlHreflangsOrderField["UrlCount"] = "urlCount";
})(CrawlHreflangsOrderField || (CrawlHreflangsOrderField = {}));
export var CrawlLinkOrderField;
(function (CrawlLinkOrderField) {
    CrawlLinkOrderField["AnchorText"] = "anchorText";
    CrawlLinkOrderField["AttrRel"] = "attrRel";
    CrawlLinkOrderField["AttrType"] = "attrType";
    CrawlLinkOrderField["DominantLanguagesHreflangMismatch"] = "dominantLanguagesHreflangMismatch";
    CrawlLinkOrderField["Handheld"] = "handheld";
    CrawlLinkOrderField["Hreflang"] = "hreflang";
    CrawlLinkOrderField["HreflangLanguage"] = "hreflangLanguage";
    CrawlLinkOrderField["HreflangReciprocate"] = "hreflangReciprocate";
    CrawlLinkOrderField["IdentifiedHreflangLanguageMismatch"] = "identifiedHreflangLanguageMismatch";
    CrawlLinkOrderField["Internal"] = "internal";
    CrawlLinkOrderField["IsHreflang"] = "isHreflang";
    CrawlLinkOrderField["IsRedirect"] = "isRedirect";
    CrawlLinkOrderField["IsRestricted"] = "isRestricted";
    CrawlLinkOrderField["IsValidHreflang"] = "isValidHreflang";
    CrawlLinkOrderField["LinkType"] = "linkType";
    CrawlLinkOrderField["Mobile"] = "mobile";
    CrawlLinkOrderField["RelUrlFrom"] = "relUrlFrom";
    CrawlLinkOrderField["Tag"] = "tag";
    CrawlLinkOrderField["UrlFrom"] = "urlFrom";
    CrawlLinkOrderField["UrlFromAlias"] = "urlFromAlias";
    CrawlLinkOrderField["UrlFromDeeprank"] = "urlFromDeeprank";
    CrawlLinkOrderField["UrlFromHttps"] = "urlFromHttps";
    CrawlLinkOrderField["UrlFromStatusCode"] = "urlFromStatusCode";
    CrawlLinkOrderField["UrlFromTitle"] = "urlFromTitle";
    CrawlLinkOrderField["UrlTo"] = "urlTo";
    CrawlLinkOrderField["UrlToAlias"] = "urlToAlias";
    CrawlLinkOrderField["UrlToDeeprank"] = "urlToDeeprank";
    CrawlLinkOrderField["UrlToHttps"] = "urlToHttps";
    CrawlLinkOrderField["UrlToIdentifiedLanguage"] = "urlToIdentifiedLanguage";
    CrawlLinkOrderField["UrlToStatusCode"] = "urlToStatusCode";
    CrawlLinkOrderField["UrlToTitle"] = "urlToTitle";
})(CrawlLinkOrderField || (CrawlLinkOrderField = {}));
export var CrawlLinkedDomainOrderField;
(function (CrawlLinkedDomainOrderField) {
    CrawlLinkedDomainOrderField["LinkCount"] = "linkCount";
})(CrawlLinkedDomainOrderField || (CrawlLinkedDomainOrderField = {}));
export var CrawlOrderField;
(function (CrawlOrderField) {
    CrawlOrderField["ArchivedAt"] = "archivedAt";
    CrawlOrderField["ArchivingAt"] = "archivingAt";
    CrawlOrderField["BrokenAt"] = "brokenAt";
    CrawlOrderField["BucketSlot"] = "bucketSlot";
    CrawlOrderField["CanceledAt"] = "canceledAt";
    CrawlOrderField["CancelingAt"] = "cancelingAt";
    CrawlOrderField["CrawlLevels"] = "crawlLevels";
    CrawlOrderField["CrawlUrlsTotal"] = "crawlUrlsTotal";
    CrawlOrderField["Crawlability"] = "crawlability";
    CrawlOrderField["CrawlabilityTrend"] = "crawlabilityTrend";
    CrawlOrderField["CrawledAt"] = "crawledAt";
    CrawlOrderField["CrawledPhase1At"] = "crawledPhase1At";
    CrawlOrderField["CrawledPhase2At"] = "crawledPhase2At";
    CrawlOrderField["CrawlingAt"] = "crawlingAt";
    CrawlOrderField["CrawlingPhase1At"] = "crawlingPhase1At";
    CrawlOrderField["CrawlingPhase2At"] = "crawlingPhase2At";
    CrawlOrderField["CreatedAt"] = "createdAt";
    CrawlOrderField["DiscoveredUrlsAt"] = "discoveredUrlsAt";
    CrawlOrderField["DiscoveringUrlsAt"] = "discoveringUrlsAt";
    CrawlOrderField["ErrorMessage"] = "errorMessage";
    CrawlOrderField["ErroredAt"] = "erroredAt";
    CrawlOrderField["FinishedAt"] = "finishedAt";
    CrawlOrderField["FsmExtTransitionedAt"] = "fsmExtTransitionedAt";
    CrawlOrderField["FsmState"] = "fsmState";
    CrawlOrderField["FsmTransitionedAt"] = "fsmTransitionedAt";
    CrawlOrderField["Id"] = "id";
    CrawlOrderField["Incomplete"] = "incomplete";
    /** @deprecated No longer in use. */
    CrawlOrderField["IsContainer"] = "isContainer";
    CrawlOrderField["IsScheduled"] = "isScheduled";
    CrawlOrderField["LanguageSettings"] = "languageSettings";
    CrawlOrderField["LanguageSettingsAdded"] = "languageSettingsAdded";
    CrawlOrderField["LanguageSettingsRemoved"] = "languageSettingsRemoved";
    CrawlOrderField["LimitLevelsMax"] = "limitLevelsMax";
    CrawlOrderField["LimitPagesMax"] = "limitPagesMax";
    CrawlOrderField["MaxProcessLinks"] = "maxProcessLinks";
    CrawlOrderField["MigratingAt"] = "migratingAt";
    CrawlOrderField["PageGroupings"] = "pageGroupings";
    CrawlOrderField["PausedReminderSent"] = "pausedReminderSent";
    CrawlOrderField["PopulatedAt"] = "populatedAt";
    CrawlOrderField["PopulatingAt"] = "populatingAt";
    CrawlOrderField["PostPopulatedAt"] = "postPopulatedAt";
    CrawlOrderField["PostPopulatingAt"] = "postPopulatingAt";
    CrawlOrderField["PostTransformedAt"] = "postTransformedAt";
    CrawlOrderField["PostTransformingAt"] = "postTransformingAt";
    CrawlOrderField["Priority"] = "priority";
    CrawlOrderField["ProcessInstanceType"] = "processInstanceType";
    CrawlOrderField["ProcessedAt"] = "processedAt";
    CrawlOrderField["ProcessingAt"] = "processingAt";
    CrawlOrderField["QueuedAt"] = "queuedAt";
    CrawlOrderField["QueueingAt"] = "queueingAt";
    CrawlOrderField["ReadyAt"] = "readyAt";
    CrawlOrderField["RefineryEnabled"] = "refineryEnabled";
    CrawlOrderField["RefineryProcessedAt"] = "refineryProcessedAt";
    CrawlOrderField["RefineryProcessingAt"] = "refineryProcessingAt";
    CrawlOrderField["RefineryProcessingEnabled"] = "refineryProcessingEnabled";
    CrawlOrderField["RobotsOverwrite"] = "robotsOverwrite";
    CrawlOrderField["ScheduleRunningEmailSentAt"] = "scheduleRunningEmailSentAt";
    CrawlOrderField["Scheduled"] = "scheduled";
    CrawlOrderField["SiteExplorer"] = "siteExplorer";
    CrawlOrderField["StartedByApi"] = "startedByApi";
    CrawlOrderField["Status"] = "status";
    CrawlOrderField["StatusEnum"] = "statusEnum";
    CrawlOrderField["TotalStepLinks"] = "totalStepLinks";
    CrawlOrderField["TotalSteps"] = "totalSteps";
    CrawlOrderField["TransformedAt"] = "transformedAt";
    CrawlOrderField["TransformingAt"] = "transformingAt";
    CrawlOrderField["UpdatedAt"] = "updatedAt";
    CrawlOrderField["UseEsReportStats"] = "useEsReportStats";
    CrawlOrderField["UseRewriteRules"] = "useRewriteRules";
    CrawlOrderField["UseRobotsOverwrite"] = "useRobotsOverwrite";
    CrawlOrderField["Version"] = "version";
})(CrawlOrderField || (CrawlOrderField = {}));
export var CrawlOriginOrderField;
(function (CrawlOriginOrderField) {
    CrawlOriginOrderField["CruxCls"] = "cruxCls";
    CrawlOriginOrderField["CruxClsGood"] = "cruxClsGood";
    CrawlOriginOrderField["CruxClsModerate"] = "cruxClsModerate";
    CrawlOriginOrderField["CruxClsPoor"] = "cruxClsPoor";
    CrawlOriginOrderField["CruxFcp"] = "cruxFcp";
    CrawlOriginOrderField["CruxFcpGood"] = "cruxFcpGood";
    CrawlOriginOrderField["CruxFcpModerate"] = "cruxFcpModerate";
    CrawlOriginOrderField["CruxFcpPoor"] = "cruxFcpPoor";
    CrawlOriginOrderField["CruxItnp"] = "cruxItnp";
    CrawlOriginOrderField["CruxItnpGood"] = "cruxItnpGood";
    CrawlOriginOrderField["CruxItnpModerate"] = "cruxItnpModerate";
    CrawlOriginOrderField["CruxItnpPoor"] = "cruxItnpPoor";
    CrawlOriginOrderField["CruxLcp"] = "cruxLcp";
    CrawlOriginOrderField["CruxLcpGood"] = "cruxLcpGood";
    CrawlOriginOrderField["CruxLcpModerate"] = "cruxLcpModerate";
    CrawlOriginOrderField["CruxLcpPoor"] = "cruxLcpPoor";
    CrawlOriginOrderField["CruxTtfb"] = "cruxTtfb";
    CrawlOriginOrderField["CruxTtfbGood"] = "cruxTtfbGood";
    CrawlOriginOrderField["CruxTtfbModerate"] = "cruxTtfbModerate";
    CrawlOriginOrderField["CruxTtfbPoor"] = "cruxTtfbPoor";
    CrawlOriginOrderField["Origin"] = "origin";
    CrawlOriginOrderField["UrlsCount"] = "urlsCount";
})(CrawlOriginOrderField || (CrawlOriginOrderField = {}));
export var CrawlPriority;
(function (CrawlPriority) {
    CrawlPriority["Default"] = "Default";
    CrawlPriority["High"] = "High";
})(CrawlPriority || (CrawlPriority = {}));
export var CrawlReportTemplateOrderField;
(function (CrawlReportTemplateOrderField) {
    CrawlReportTemplateOrderField["QueryVersion"] = "queryVersion";
    CrawlReportTemplateOrderField["ReportTemplateCode"] = "reportTemplateCode";
})(CrawlReportTemplateOrderField || (CrawlReportTemplateOrderField = {}));
export var CrawlSearchQueryOrderField;
(function (CrawlSearchQueryOrderField) {
    CrawlSearchQueryOrderField["Clicks"] = "clicks";
    CrawlSearchQueryOrderField["Ctr"] = "ctr";
    CrawlSearchQueryOrderField["Impressions"] = "impressions";
    CrawlSearchQueryOrderField["LandingPagesCount"] = "landingPagesCount";
    CrawlSearchQueryOrderField["Position"] = "position";
    CrawlSearchQueryOrderField["SearchQuery"] = "searchQuery";
})(CrawlSearchQueryOrderField || (CrawlSearchQueryOrderField = {}));
export var CrawlSearchQueryWithLandingPagesOrderField;
(function (CrawlSearchQueryWithLandingPagesOrderField) {
    CrawlSearchQueryWithLandingPagesOrderField["Clicks"] = "clicks";
    CrawlSearchQueryWithLandingPagesOrderField["Ctr"] = "ctr";
    CrawlSearchQueryWithLandingPagesOrderField["Impressions"] = "impressions";
    CrawlSearchQueryWithLandingPagesOrderField["Position"] = "position";
    CrawlSearchQueryWithLandingPagesOrderField["SearchQuery"] = "searchQuery";
    CrawlSearchQueryWithLandingPagesOrderField["Url"] = "url";
})(CrawlSearchQueryWithLandingPagesOrderField || (CrawlSearchQueryWithLandingPagesOrderField = {}));
export var CrawlSegmentOrderField;
(function (CrawlSegmentOrderField) {
    CrawlSegmentOrderField["CreatedAt"] = "createdAt";
    CrawlSegmentOrderField["FailedAt"] = "failedAt";
    CrawlSegmentOrderField["FailureReason"] = "failureReason";
    CrawlSegmentOrderField["GeneratedAt"] = "generatedAt";
    CrawlSegmentOrderField["GeneratingAt"] = "generatingAt";
    CrawlSegmentOrderField["SegmentVersion"] = "segmentVersion";
    CrawlSegmentOrderField["UpdatedAt"] = "updatedAt";
})(CrawlSegmentOrderField || (CrawlSegmentOrderField = {}));
export var CrawlSettingOrderField;
(function (CrawlSettingOrderField) {
    CrawlSettingOrderField["AiFeaturesEnabled"] = "aiFeaturesEnabled";
    CrawlSettingOrderField["DuplicatePrecision"] = "duplicatePrecision";
    CrawlSettingOrderField["EmptyPageThreshold"] = "emptyPageThreshold";
    CrawlSettingOrderField["EnableKeyValueStore"] = "enableKeyValueStore";
    CrawlSettingOrderField["FlattenIframes"] = "flattenIframes";
    CrawlSettingOrderField["FlattenShadowDom"] = "flattenShadowDom";
    /** @deprecated Renamed to logSummaryRequestsHigh. */
    CrawlSettingOrderField["HighLogSummaryRequests"] = "highLogSummaryRequests";
    CrawlSettingOrderField["IncludeBestPractices"] = "includeBestPractices";
    CrawlSettingOrderField["LogSummaryRequestsHigh"] = "logSummaryRequestsHigh";
    CrawlSettingOrderField["LogSummaryRequestsLow"] = "logSummaryRequestsLow";
    /** @deprecated Renamed to logSummaryRequestsLow. */
    CrawlSettingOrderField["LowLogSummaryRequests"] = "lowLogSummaryRequests";
    CrawlSettingOrderField["MaxBodyContentLength"] = "maxBodyContentLength";
    /** @deprecated Renamed to maxBodyContentLength. */
    CrawlSettingOrderField["MaxContentThreshold"] = "maxContentThreshold";
    CrawlSettingOrderField["MaxDescriptionLength"] = "maxDescriptionLength";
    CrawlSettingOrderField["MaxFollowedExternalLinks"] = "maxFollowedExternalLinks";
    CrawlSettingOrderField["MaxHtmlSize"] = "maxHtmlSize";
    CrawlSettingOrderField["MaxLinks"] = "maxLinks";
    CrawlSettingOrderField["MaxLoadTime"] = "maxLoadTime";
    CrawlSettingOrderField["MaxRedirections"] = "maxRedirections";
    CrawlSettingOrderField["MaxTitleWidth"] = "maxTitleWidth";
    CrawlSettingOrderField["MaxUrlLength"] = "maxUrlLength";
    CrawlSettingOrderField["MinContentRatio"] = "minContentRatio";
    CrawlSettingOrderField["MinDescriptionLength"] = "minDescriptionLength";
    CrawlSettingOrderField["MinTitleLength"] = "minTitleLength";
    CrawlSettingOrderField["RenderWithImages"] = "renderWithImages";
    CrawlSettingOrderField["RenderingRobotsCheckMode"] = "renderingRobotsCheckMode";
    /** @deprecated Legacy setting, no longer used. Will be removed. */
    CrawlSettingOrderField["SafeTitleWidth"] = "safeTitleWidth";
    CrawlSettingOrderField["TargetMaxUncrawledUrlsCount"] = "targetMaxUncrawledUrlsCount";
    CrawlSettingOrderField["ThinPageThreshold"] = "thinPageThreshold";
    /** @deprecated Legacy setting, no longer used. Will be removed. */
    CrawlSettingOrderField["TwitterDescriptionLength"] = "twitterDescriptionLength";
})(CrawlSettingOrderField || (CrawlSettingOrderField = {}));
/** Used for grouping the CrawlSiteSpeedAudit aggregates results. */
export var CrawlSiteSpeedAuditAggregateDimension;
(function (CrawlSiteSpeedAuditAggregateDimension) {
    CrawlSiteSpeedAuditAggregateDimension["AuditId"] = "auditId";
    CrawlSiteSpeedAuditAggregateDimension["AuditResult"] = "auditResult";
    CrawlSiteSpeedAuditAggregateDimension["Title"] = "title";
})(CrawlSiteSpeedAuditAggregateDimension || (CrawlSiteSpeedAuditAggregateDimension = {}));
export var CrawlSiteSpeedAuditAggregateOrderField;
(function (CrawlSiteSpeedAuditAggregateOrderField) {
    CrawlSiteSpeedAuditAggregateOrderField["AuditId"] = "auditId";
    CrawlSiteSpeedAuditAggregateOrderField["AuditResult"] = "auditResult";
    CrawlSiteSpeedAuditAggregateOrderField["AvgGscClicks"] = "avgGscClicks";
    CrawlSiteSpeedAuditAggregateOrderField["AvgItemsCount"] = "avgItemsCount";
    CrawlSiteSpeedAuditAggregateOrderField["AvgMetricSavingsCls"] = "avgMetricSavingsCls";
    CrawlSiteSpeedAuditAggregateOrderField["AvgMetricSavingsFcp"] = "avgMetricSavingsFcp";
    CrawlSiteSpeedAuditAggregateOrderField["AvgMetricSavingsInp"] = "avgMetricSavingsInp";
    CrawlSiteSpeedAuditAggregateOrderField["AvgMetricSavingsLcp"] = "avgMetricSavingsLcp";
    CrawlSiteSpeedAuditAggregateOrderField["AvgMetricSavingsTbt"] = "avgMetricSavingsTbt";
    CrawlSiteSpeedAuditAggregateOrderField["AvgNumericValue"] = "avgNumericValue";
    CrawlSiteSpeedAuditAggregateOrderField["AvgPageviews"] = "avgPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["AvgProductOfSavingsKibAndPageviews"] = "avgProductOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["AvgProductOfSavingsSecsAndPageviews"] = "avgProductOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["AvgSavingsKib"] = "avgSavingsKib";
    CrawlSiteSpeedAuditAggregateOrderField["AvgSavingsScore"] = "avgSavingsScore";
    CrawlSiteSpeedAuditAggregateOrderField["AvgSavingsSecs"] = "avgSavingsSecs";
    CrawlSiteSpeedAuditAggregateOrderField["AvgScore"] = "avgScore";
    CrawlSiteSpeedAuditAggregateOrderField["AvgTotalSizeKib"] = "avgTotalSizeKib";
    CrawlSiteSpeedAuditAggregateOrderField["CountGscClicks"] = "countGscClicks";
    CrawlSiteSpeedAuditAggregateOrderField["CountItemsCount"] = "countItemsCount";
    CrawlSiteSpeedAuditAggregateOrderField["CountMetricSavingsCls"] = "countMetricSavingsCls";
    CrawlSiteSpeedAuditAggregateOrderField["CountMetricSavingsFcp"] = "countMetricSavingsFcp";
    CrawlSiteSpeedAuditAggregateOrderField["CountMetricSavingsInp"] = "countMetricSavingsInp";
    CrawlSiteSpeedAuditAggregateOrderField["CountMetricSavingsLcp"] = "countMetricSavingsLcp";
    CrawlSiteSpeedAuditAggregateOrderField["CountMetricSavingsTbt"] = "countMetricSavingsTbt";
    CrawlSiteSpeedAuditAggregateOrderField["CountNumericValue"] = "countNumericValue";
    CrawlSiteSpeedAuditAggregateOrderField["CountPageviews"] = "countPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["CountProductOfSavingsKibAndPageviews"] = "countProductOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["CountProductOfSavingsSecsAndPageviews"] = "countProductOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["CountSavingsKib"] = "countSavingsKib";
    CrawlSiteSpeedAuditAggregateOrderField["CountSavingsScore"] = "countSavingsScore";
    CrawlSiteSpeedAuditAggregateOrderField["CountSavingsSecs"] = "countSavingsSecs";
    CrawlSiteSpeedAuditAggregateOrderField["CountScore"] = "countScore";
    CrawlSiteSpeedAuditAggregateOrderField["CountTotalSizeKib"] = "countTotalSizeKib";
    CrawlSiteSpeedAuditAggregateOrderField["MaxGscClicks"] = "maxGscClicks";
    CrawlSiteSpeedAuditAggregateOrderField["MaxItemsCount"] = "maxItemsCount";
    CrawlSiteSpeedAuditAggregateOrderField["MaxMetricSavingsCls"] = "maxMetricSavingsCls";
    CrawlSiteSpeedAuditAggregateOrderField["MaxMetricSavingsFcp"] = "maxMetricSavingsFcp";
    CrawlSiteSpeedAuditAggregateOrderField["MaxMetricSavingsInp"] = "maxMetricSavingsInp";
    CrawlSiteSpeedAuditAggregateOrderField["MaxMetricSavingsLcp"] = "maxMetricSavingsLcp";
    CrawlSiteSpeedAuditAggregateOrderField["MaxMetricSavingsTbt"] = "maxMetricSavingsTbt";
    CrawlSiteSpeedAuditAggregateOrderField["MaxNumericValue"] = "maxNumericValue";
    CrawlSiteSpeedAuditAggregateOrderField["MaxPageviews"] = "maxPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MaxProductOfSavingsKibAndPageviews"] = "maxProductOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MaxProductOfSavingsSecsAndPageviews"] = "maxProductOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MaxSavingsKib"] = "maxSavingsKib";
    CrawlSiteSpeedAuditAggregateOrderField["MaxSavingsScore"] = "maxSavingsScore";
    CrawlSiteSpeedAuditAggregateOrderField["MaxSavingsSecs"] = "maxSavingsSecs";
    CrawlSiteSpeedAuditAggregateOrderField["MaxScore"] = "maxScore";
    CrawlSiteSpeedAuditAggregateOrderField["MaxTotalSizeKib"] = "maxTotalSizeKib";
    CrawlSiteSpeedAuditAggregateOrderField["MinGscClicks"] = "minGscClicks";
    CrawlSiteSpeedAuditAggregateOrderField["MinItemsCount"] = "minItemsCount";
    CrawlSiteSpeedAuditAggregateOrderField["MinMetricSavingsCls"] = "minMetricSavingsCls";
    CrawlSiteSpeedAuditAggregateOrderField["MinMetricSavingsFcp"] = "minMetricSavingsFcp";
    CrawlSiteSpeedAuditAggregateOrderField["MinMetricSavingsInp"] = "minMetricSavingsInp";
    CrawlSiteSpeedAuditAggregateOrderField["MinMetricSavingsLcp"] = "minMetricSavingsLcp";
    CrawlSiteSpeedAuditAggregateOrderField["MinMetricSavingsTbt"] = "minMetricSavingsTbt";
    CrawlSiteSpeedAuditAggregateOrderField["MinNumericValue"] = "minNumericValue";
    CrawlSiteSpeedAuditAggregateOrderField["MinPageviews"] = "minPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MinProductOfSavingsKibAndPageviews"] = "minProductOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MinProductOfSavingsSecsAndPageviews"] = "minProductOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["MinSavingsKib"] = "minSavingsKib";
    CrawlSiteSpeedAuditAggregateOrderField["MinSavingsScore"] = "minSavingsScore";
    CrawlSiteSpeedAuditAggregateOrderField["MinSavingsSecs"] = "minSavingsSecs";
    CrawlSiteSpeedAuditAggregateOrderField["MinScore"] = "minScore";
    CrawlSiteSpeedAuditAggregateOrderField["MinTotalSizeKib"] = "minTotalSizeKib";
    CrawlSiteSpeedAuditAggregateOrderField["SumGscClicks"] = "sumGscClicks";
    CrawlSiteSpeedAuditAggregateOrderField["SumItemsCount"] = "sumItemsCount";
    CrawlSiteSpeedAuditAggregateOrderField["SumMetricSavingsCls"] = "sumMetricSavingsCls";
    CrawlSiteSpeedAuditAggregateOrderField["SumMetricSavingsFcp"] = "sumMetricSavingsFcp";
    CrawlSiteSpeedAuditAggregateOrderField["SumMetricSavingsInp"] = "sumMetricSavingsInp";
    CrawlSiteSpeedAuditAggregateOrderField["SumMetricSavingsLcp"] = "sumMetricSavingsLcp";
    CrawlSiteSpeedAuditAggregateOrderField["SumMetricSavingsTbt"] = "sumMetricSavingsTbt";
    CrawlSiteSpeedAuditAggregateOrderField["SumNumericValue"] = "sumNumericValue";
    CrawlSiteSpeedAuditAggregateOrderField["SumPageviews"] = "sumPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["SumProductOfSavingsKibAndPageviews"] = "sumProductOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["SumProductOfSavingsSecsAndPageviews"] = "sumProductOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditAggregateOrderField["SumSavingsKib"] = "sumSavingsKib";
    CrawlSiteSpeedAuditAggregateOrderField["SumSavingsScore"] = "sumSavingsScore";
    CrawlSiteSpeedAuditAggregateOrderField["SumSavingsSecs"] = "sumSavingsSecs";
    CrawlSiteSpeedAuditAggregateOrderField["SumScore"] = "sumScore";
    CrawlSiteSpeedAuditAggregateOrderField["SumTotalSizeKib"] = "sumTotalSizeKib";
    CrawlSiteSpeedAuditAggregateOrderField["Title"] = "title";
})(CrawlSiteSpeedAuditAggregateOrderField || (CrawlSiteSpeedAuditAggregateOrderField = {}));
export var CrawlSiteSpeedAuditItemOrderField;
(function (CrawlSiteSpeedAuditItemOrderField) {
    CrawlSiteSpeedAuditItemOrderField["AuditId"] = "auditId";
    CrawlSiteSpeedAuditItemOrderField["AuditItemDigest"] = "auditItemDigest";
    CrawlSiteSpeedAuditItemOrderField["AuditResult"] = "auditResult";
    CrawlSiteSpeedAuditItemOrderField["AuditScore"] = "auditScore";
    CrawlSiteSpeedAuditItemOrderField["BlockingTime"] = "blockingTime";
    CrawlSiteSpeedAuditItemOrderField["BlockingTimeSecs"] = "blockingTimeSecs";
    CrawlSiteSpeedAuditItemOrderField["CacheHitProbability"] = "cacheHitProbability";
    CrawlSiteSpeedAuditItemOrderField["CacheLifetimeMs"] = "cacheLifetimeMs";
    CrawlSiteSpeedAuditItemOrderField["DebugDataMaxAge"] = "debugDataMaxAge";
    CrawlSiteSpeedAuditItemOrderField["DebugDataType"] = "debugDataType";
    CrawlSiteSpeedAuditItemOrderField["Description"] = "description";
    CrawlSiteSpeedAuditItemOrderField["DisplayValue"] = "displayValue";
    CrawlSiteSpeedAuditItemOrderField["DurationMs"] = "durationMs";
    CrawlSiteSpeedAuditItemOrderField["Element"] = "element";
    CrawlSiteSpeedAuditItemOrderField["FailureType"] = "failureType";
    CrawlSiteSpeedAuditItemOrderField["FromProtocol"] = "fromProtocol";
    CrawlSiteSpeedAuditItemOrderField["GscClicks"] = "gscClicks";
    CrawlSiteSpeedAuditItemOrderField["Hostname"] = "hostname";
    CrawlSiteSpeedAuditItemOrderField["IsCrossOrigin"] = "isCrossOrigin";
    CrawlSiteSpeedAuditItemOrderField["ItemIndex"] = "itemIndex";
    CrawlSiteSpeedAuditItemOrderField["LayoutShiftImpact"] = "layoutShiftImpact";
    CrawlSiteSpeedAuditItemOrderField["NodeNodeLabel"] = "nodeNodeLabel";
    CrawlSiteSpeedAuditItemOrderField["NodePath"] = "nodePath";
    CrawlSiteSpeedAuditItemOrderField["NodeSelector"] = "nodeSelector";
    CrawlSiteSpeedAuditItemOrderField["NodeSnippet"] = "nodeSnippet";
    CrawlSiteSpeedAuditItemOrderField["OpportunityDigest"] = "opportunityDigest";
    CrawlSiteSpeedAuditItemOrderField["Pageviews"] = "pageviews";
    CrawlSiteSpeedAuditItemOrderField["ProductOfWastedKibAndPageviews"] = "productOfWastedKibAndPageviews";
    CrawlSiteSpeedAuditItemOrderField["ProductOfWastedSecsAndPageviews"] = "productOfWastedSecsAndPageviews";
    CrawlSiteSpeedAuditItemOrderField["Protocol"] = "protocol";
    CrawlSiteSpeedAuditItemOrderField["ProtocolReason"] = "protocolReason";
    CrawlSiteSpeedAuditItemOrderField["Reason"] = "reason";
    CrawlSiteSpeedAuditItemOrderField["RequestStartTime"] = "requestStartTime";
    CrawlSiteSpeedAuditItemOrderField["ResourceUrl"] = "resourceUrl";
    CrawlSiteSpeedAuditItemOrderField["SavingsScore"] = "savingsScore";
    CrawlSiteSpeedAuditItemOrderField["ScriptEvaluationTimeMs"] = "scriptEvaluationTimeMs";
    CrawlSiteSpeedAuditItemOrderField["ScriptParseTimeMs"] = "scriptParseTimeMs";
    CrawlSiteSpeedAuditItemOrderField["SegmentIdsCount"] = "segmentIdsCount";
    CrawlSiteSpeedAuditItemOrderField["SegmentNamesCount"] = "segmentNamesCount";
    CrawlSiteSpeedAuditItemOrderField["SourceColumn"] = "sourceColumn";
    CrawlSiteSpeedAuditItemOrderField["SourceLine"] = "sourceLine";
    CrawlSiteSpeedAuditItemOrderField["SourceUrl"] = "sourceUrl";
    CrawlSiteSpeedAuditItemOrderField["StatisticValue"] = "statisticValue";
    CrawlSiteSpeedAuditItemOrderField["SubItemsCount"] = "subItemsCount";
    CrawlSiteSpeedAuditItemOrderField["TbtImpact"] = "tbtImpact";
    CrawlSiteSpeedAuditItemOrderField["Title"] = "title";
    CrawlSiteSpeedAuditItemOrderField["TotalBytes"] = "totalBytes";
    CrawlSiteSpeedAuditItemOrderField["TotalCpuTimeMs"] = "totalCpuTimeMs";
    CrawlSiteSpeedAuditItemOrderField["TotalSizeKib"] = "totalSizeKib";
    CrawlSiteSpeedAuditItemOrderField["TransferSize"] = "transferSize";
    CrawlSiteSpeedAuditItemOrderField["Url"] = "url";
    CrawlSiteSpeedAuditItemOrderField["UrlDigest"] = "urlDigest";
    CrawlSiteSpeedAuditItemOrderField["WastedKib"] = "wastedKib";
    CrawlSiteSpeedAuditItemOrderField["WastedPercent"] = "wastedPercent";
    CrawlSiteSpeedAuditItemOrderField["WastedSecs"] = "wastedSecs";
    CrawlSiteSpeedAuditItemOrderField["WastedWebpBytes"] = "wastedWebpBytes";
})(CrawlSiteSpeedAuditItemOrderField || (CrawlSiteSpeedAuditItemOrderField = {}));
/** Used for grouping the CrawlSiteSpeedAuditOpportunity aggregates results. */
export var CrawlSiteSpeedAuditOpportunityAggregateDimension;
(function (CrawlSiteSpeedAuditOpportunityAggregateDimension) {
    CrawlSiteSpeedAuditOpportunityAggregateDimension["AuditId"] = "auditId";
    CrawlSiteSpeedAuditOpportunityAggregateDimension["Title"] = "title";
})(CrawlSiteSpeedAuditOpportunityAggregateDimension || (CrawlSiteSpeedAuditOpportunityAggregateDimension = {}));
export var CrawlSiteSpeedAuditOpportunityAggregateOrderField;
(function (CrawlSiteSpeedAuditOpportunityAggregateOrderField) {
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AuditId"] = "auditId";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgAuditScoreAvg"] = "avgAuditScoreAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgBlockingTimeSecsAvg"] = "avgBlockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgBlockingTimeSecsSum"] = "avgBlockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgBlockingTimeSum"] = "avgBlockingTimeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgCacheHitProbabilityAvg"] = "avgCacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgCacheLifetimeMsSum"] = "avgCacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgDebugDataMaxAge"] = "avgDebugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgFailedAuditsCount"] = "avgFailedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgGaPageviewsSum"] = "avgGaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgGscClicksSum"] = "avgGscClicksSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgInfoAuditsCount"] = "avgInfoAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgInstanceCount"] = "avgInstanceCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgLayoutShiftImpactAvg"] = "avgLayoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgLayoutShiftImpactSum"] = "avgLayoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgPassedAuditsCount"] = "avgPassedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgProductOfWastedKibAndPageviewsSum"] = "avgProductOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgProductOfWastedSecsAndPageviewsSum"] = "avgProductOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgSavingsScoreSum"] = "avgSavingsScoreSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgSubItemsCount"] = "avgSubItemsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgTotalBytesAvg"] = "avgTotalBytesAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgTotalBytesSum"] = "avgTotalBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgTotalSizeKibAvg"] = "avgTotalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgTotalSizeKibSum"] = "avgTotalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgTransferSizeSum"] = "avgTransferSizeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgUrlCount"] = "avgUrlCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWarningAuditsCount"] = "avgWarningAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedKibAvg"] = "avgWastedKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedKibSum"] = "avgWastedKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedPercentAvg"] = "avgWastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedSecsAvg"] = "avgWastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedSecsSum"] = "avgWastedSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWastedWebpBytesSum"] = "avgWastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["AvgWeightedScore"] = "avgWeightedScore";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountAuditScoreAvg"] = "countAuditScoreAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountBlockingTimeSecsAvg"] = "countBlockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountBlockingTimeSecsSum"] = "countBlockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountBlockingTimeSum"] = "countBlockingTimeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountCacheHitProbabilityAvg"] = "countCacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountCacheLifetimeMsSum"] = "countCacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountDebugDataMaxAge"] = "countDebugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountFailedAuditsCount"] = "countFailedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountGaPageviewsSum"] = "countGaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountGscClicksSum"] = "countGscClicksSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountInfoAuditsCount"] = "countInfoAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountInstanceCount"] = "countInstanceCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountLayoutShiftImpactAvg"] = "countLayoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountLayoutShiftImpactSum"] = "countLayoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountPassedAuditsCount"] = "countPassedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountProductOfWastedKibAndPageviewsSum"] = "countProductOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountProductOfWastedSecsAndPageviewsSum"] = "countProductOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountSavingsScoreSum"] = "countSavingsScoreSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountSubItemsCount"] = "countSubItemsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountTotalBytesAvg"] = "countTotalBytesAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountTotalBytesSum"] = "countTotalBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountTotalSizeKibAvg"] = "countTotalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountTotalSizeKibSum"] = "countTotalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountTransferSizeSum"] = "countTransferSizeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountUrlCount"] = "countUrlCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWarningAuditsCount"] = "countWarningAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedKibAvg"] = "countWastedKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedKibSum"] = "countWastedKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedPercentAvg"] = "countWastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedSecsAvg"] = "countWastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedSecsSum"] = "countWastedSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWastedWebpBytesSum"] = "countWastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["CountWeightedScore"] = "countWeightedScore";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxAuditScoreAvg"] = "maxAuditScoreAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxBlockingTimeSecsAvg"] = "maxBlockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxBlockingTimeSecsSum"] = "maxBlockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxBlockingTimeSum"] = "maxBlockingTimeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxCacheHitProbabilityAvg"] = "maxCacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxCacheLifetimeMsSum"] = "maxCacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxDebugDataMaxAge"] = "maxDebugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxFailedAuditsCount"] = "maxFailedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxGaPageviewsSum"] = "maxGaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxGscClicksSum"] = "maxGscClicksSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxInfoAuditsCount"] = "maxInfoAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxInstanceCount"] = "maxInstanceCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxLayoutShiftImpactAvg"] = "maxLayoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxLayoutShiftImpactSum"] = "maxLayoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxPassedAuditsCount"] = "maxPassedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxProductOfWastedKibAndPageviewsSum"] = "maxProductOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxProductOfWastedSecsAndPageviewsSum"] = "maxProductOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxSavingsScoreSum"] = "maxSavingsScoreSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxSubItemsCount"] = "maxSubItemsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxTotalBytesAvg"] = "maxTotalBytesAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxTotalBytesSum"] = "maxTotalBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxTotalSizeKibAvg"] = "maxTotalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxTotalSizeKibSum"] = "maxTotalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxTransferSizeSum"] = "maxTransferSizeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxUrlCount"] = "maxUrlCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWarningAuditsCount"] = "maxWarningAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedKibAvg"] = "maxWastedKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedKibSum"] = "maxWastedKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedPercentAvg"] = "maxWastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedSecsAvg"] = "maxWastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedSecsSum"] = "maxWastedSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWastedWebpBytesSum"] = "maxWastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MaxWeightedScore"] = "maxWeightedScore";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinAuditScoreAvg"] = "minAuditScoreAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinBlockingTimeSecsAvg"] = "minBlockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinBlockingTimeSecsSum"] = "minBlockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinBlockingTimeSum"] = "minBlockingTimeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinCacheHitProbabilityAvg"] = "minCacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinCacheLifetimeMsSum"] = "minCacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinDebugDataMaxAge"] = "minDebugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinFailedAuditsCount"] = "minFailedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinGaPageviewsSum"] = "minGaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinGscClicksSum"] = "minGscClicksSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinInfoAuditsCount"] = "minInfoAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinInstanceCount"] = "minInstanceCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinLayoutShiftImpactAvg"] = "minLayoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinLayoutShiftImpactSum"] = "minLayoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinPassedAuditsCount"] = "minPassedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinProductOfWastedKibAndPageviewsSum"] = "minProductOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinProductOfWastedSecsAndPageviewsSum"] = "minProductOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinSavingsScoreSum"] = "minSavingsScoreSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinSubItemsCount"] = "minSubItemsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinTotalBytesAvg"] = "minTotalBytesAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinTotalBytesSum"] = "minTotalBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinTotalSizeKibAvg"] = "minTotalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinTotalSizeKibSum"] = "minTotalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinTransferSizeSum"] = "minTransferSizeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinUrlCount"] = "minUrlCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWarningAuditsCount"] = "minWarningAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedKibAvg"] = "minWastedKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedKibSum"] = "minWastedKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedPercentAvg"] = "minWastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedSecsAvg"] = "minWastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedSecsSum"] = "minWastedSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWastedWebpBytesSum"] = "minWastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["MinWeightedScore"] = "minWeightedScore";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumAuditScoreAvg"] = "sumAuditScoreAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumBlockingTimeSecsAvg"] = "sumBlockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumBlockingTimeSecsSum"] = "sumBlockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumBlockingTimeSum"] = "sumBlockingTimeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumCacheHitProbabilityAvg"] = "sumCacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumCacheLifetimeMsSum"] = "sumCacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumDebugDataMaxAge"] = "sumDebugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumFailedAuditsCount"] = "sumFailedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumGaPageviewsSum"] = "sumGaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumGscClicksSum"] = "sumGscClicksSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumInfoAuditsCount"] = "sumInfoAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumInstanceCount"] = "sumInstanceCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumLayoutShiftImpactAvg"] = "sumLayoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumLayoutShiftImpactSum"] = "sumLayoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumPassedAuditsCount"] = "sumPassedAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumProductOfWastedKibAndPageviewsSum"] = "sumProductOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumProductOfWastedSecsAndPageviewsSum"] = "sumProductOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumSavingsScoreSum"] = "sumSavingsScoreSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumSubItemsCount"] = "sumSubItemsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumTotalBytesAvg"] = "sumTotalBytesAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumTotalBytesSum"] = "sumTotalBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumTotalSizeKibAvg"] = "sumTotalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumTotalSizeKibSum"] = "sumTotalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumTransferSizeSum"] = "sumTransferSizeSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumUrlCount"] = "sumUrlCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWarningAuditsCount"] = "sumWarningAuditsCount";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedKibAvg"] = "sumWastedKibAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedKibSum"] = "sumWastedKibSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedPercentAvg"] = "sumWastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedSecsAvg"] = "sumWastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedSecsSum"] = "sumWastedSecsSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWastedWebpBytesSum"] = "sumWastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["SumWeightedScore"] = "sumWeightedScore";
    CrawlSiteSpeedAuditOpportunityAggregateOrderField["Title"] = "title";
})(CrawlSiteSpeedAuditOpportunityAggregateOrderField || (CrawlSiteSpeedAuditOpportunityAggregateOrderField = {}));
export var CrawlSiteSpeedAuditOpportunityOrderField;
(function (CrawlSiteSpeedAuditOpportunityOrderField) {
    CrawlSiteSpeedAuditOpportunityOrderField["AuditId"] = "auditId";
    CrawlSiteSpeedAuditOpportunityOrderField["AuditScoreAvg"] = "auditScoreAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["BlockingTimeSecsAvg"] = "blockingTimeSecsAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["BlockingTimeSecsSum"] = "blockingTimeSecsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["BlockingTimeSum"] = "blockingTimeSum";
    CrawlSiteSpeedAuditOpportunityOrderField["CacheHitProbabilityAvg"] = "cacheHitProbabilityAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["CacheLifetimeMsSum"] = "cacheLifetimeMsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["DebugDataMaxAge"] = "debugDataMaxAge";
    CrawlSiteSpeedAuditOpportunityOrderField["DebugDataType"] = "debugDataType";
    CrawlSiteSpeedAuditOpportunityOrderField["Description"] = "description";
    CrawlSiteSpeedAuditOpportunityOrderField["Element"] = "element";
    CrawlSiteSpeedAuditOpportunityOrderField["ExampleUrl"] = "exampleUrl";
    CrawlSiteSpeedAuditOpportunityOrderField["ExampleUrlDigest"] = "exampleUrlDigest";
    CrawlSiteSpeedAuditOpportunityOrderField["FailedAuditsCount"] = "failedAuditsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["FailureType"] = "failureType";
    CrawlSiteSpeedAuditOpportunityOrderField["FromProtocol"] = "fromProtocol";
    CrawlSiteSpeedAuditOpportunityOrderField["GaPageviewsSum"] = "gaPageviewsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["GscClicksSum"] = "gscClicksSum";
    CrawlSiteSpeedAuditOpportunityOrderField["Hostname"] = "hostname";
    CrawlSiteSpeedAuditOpportunityOrderField["InfoAuditsCount"] = "infoAuditsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["InstanceCount"] = "instanceCount";
    CrawlSiteSpeedAuditOpportunityOrderField["IsCrossOrigin"] = "isCrossOrigin";
    CrawlSiteSpeedAuditOpportunityOrderField["LayoutShiftImpactAvg"] = "layoutShiftImpactAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["LayoutShiftImpactSum"] = "layoutShiftImpactSum";
    CrawlSiteSpeedAuditOpportunityOrderField["NodeNodeLabel"] = "nodeNodeLabel";
    CrawlSiteSpeedAuditOpportunityOrderField["NodePath"] = "nodePath";
    CrawlSiteSpeedAuditOpportunityOrderField["NodeSelector"] = "nodeSelector";
    CrawlSiteSpeedAuditOpportunityOrderField["NodeSnippet"] = "nodeSnippet";
    CrawlSiteSpeedAuditOpportunityOrderField["OpportunityDigest"] = "opportunityDigest";
    CrawlSiteSpeedAuditOpportunityOrderField["PassedAuditsCount"] = "passedAuditsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["ProductOfWastedKibAndPageviewsSum"] = "productOfWastedKibAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["ProductOfWastedSecsAndPageviewsSum"] = "productOfWastedSecsAndPageviewsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["Protocol"] = "protocol";
    CrawlSiteSpeedAuditOpportunityOrderField["ProtocolReason"] = "protocolReason";
    CrawlSiteSpeedAuditOpportunityOrderField["Reason"] = "reason";
    CrawlSiteSpeedAuditOpportunityOrderField["RequestStartTime"] = "requestStartTime";
    CrawlSiteSpeedAuditOpportunityOrderField["SavingsScoreSum"] = "savingsScoreSum";
    CrawlSiteSpeedAuditOpportunityOrderField["SegmentIdsCount"] = "segmentIdsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["SegmentNamesCount"] = "segmentNamesCount";
    CrawlSiteSpeedAuditOpportunityOrderField["SourceColumn"] = "sourceColumn";
    CrawlSiteSpeedAuditOpportunityOrderField["SourceLine"] = "sourceLine";
    CrawlSiteSpeedAuditOpportunityOrderField["SourceUrl"] = "sourceUrl";
    CrawlSiteSpeedAuditOpportunityOrderField["SubItemsCount"] = "subItemsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["Title"] = "title";
    CrawlSiteSpeedAuditOpportunityOrderField["TotalBytesAvg"] = "totalBytesAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["TotalBytesSum"] = "totalBytesSum";
    CrawlSiteSpeedAuditOpportunityOrderField["TotalSizeKibAvg"] = "totalSizeKibAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["TotalSizeKibSum"] = "totalSizeKibSum";
    CrawlSiteSpeedAuditOpportunityOrderField["TransferSizeSum"] = "transferSizeSum";
    CrawlSiteSpeedAuditOpportunityOrderField["UrlCount"] = "urlCount";
    CrawlSiteSpeedAuditOpportunityOrderField["WarningAuditsCount"] = "warningAuditsCount";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedKibAvg"] = "wastedKibAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedKibSum"] = "wastedKibSum";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedPercentAvg"] = "wastedPercentAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedSecsAvg"] = "wastedSecsAvg";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedSecsSum"] = "wastedSecsSum";
    CrawlSiteSpeedAuditOpportunityOrderField["WastedWebpBytesSum"] = "wastedWebpBytesSum";
    CrawlSiteSpeedAuditOpportunityOrderField["WeightedScore"] = "weightedScore";
})(CrawlSiteSpeedAuditOpportunityOrderField || (CrawlSiteSpeedAuditOpportunityOrderField = {}));
export var CrawlSiteSpeedAuditOrderField;
(function (CrawlSiteSpeedAuditOrderField) {
    CrawlSiteSpeedAuditOrderField["AuditDigest"] = "auditDigest";
    CrawlSiteSpeedAuditOrderField["AuditId"] = "auditId";
    CrawlSiteSpeedAuditOrderField["AuditResult"] = "auditResult";
    CrawlSiteSpeedAuditOrderField["Description"] = "description";
    CrawlSiteSpeedAuditOrderField["DisplayValue"] = "displayValue";
    CrawlSiteSpeedAuditOrderField["GscClicks"] = "gscClicks";
    CrawlSiteSpeedAuditOrderField["GuidanceLevel"] = "guidanceLevel";
    CrawlSiteSpeedAuditOrderField["ItemsCount"] = "itemsCount";
    CrawlSiteSpeedAuditOrderField["MetricSavingsCls"] = "metricSavingsCls";
    CrawlSiteSpeedAuditOrderField["MetricSavingsFcp"] = "metricSavingsFcp";
    CrawlSiteSpeedAuditOrderField["MetricSavingsInp"] = "metricSavingsInp";
    CrawlSiteSpeedAuditOrderField["MetricSavingsLcp"] = "metricSavingsLcp";
    CrawlSiteSpeedAuditOrderField["MetricSavingsTbt"] = "metricSavingsTbt";
    CrawlSiteSpeedAuditOrderField["NumericUnit"] = "numericUnit";
    CrawlSiteSpeedAuditOrderField["NumericValue"] = "numericValue";
    CrawlSiteSpeedAuditOrderField["Pageviews"] = "pageviews";
    CrawlSiteSpeedAuditOrderField["ProductOfSavingsKibAndPageviews"] = "productOfSavingsKibAndPageviews";
    CrawlSiteSpeedAuditOrderField["ProductOfSavingsSecsAndPageviews"] = "productOfSavingsSecsAndPageviews";
    CrawlSiteSpeedAuditOrderField["RedirectsChainCount"] = "redirectsChainCount";
    CrawlSiteSpeedAuditOrderField["SavingsKib"] = "savingsKib";
    CrawlSiteSpeedAuditOrderField["SavingsScore"] = "savingsScore";
    CrawlSiteSpeedAuditOrderField["SavingsSecs"] = "savingsSecs";
    CrawlSiteSpeedAuditOrderField["Score"] = "score";
    CrawlSiteSpeedAuditOrderField["ScoreDisplayMode"] = "scoreDisplayMode";
    CrawlSiteSpeedAuditOrderField["ScoringOptionsMedian"] = "scoringOptionsMedian";
    CrawlSiteSpeedAuditOrderField["ScoringOptionsPercentile"] = "scoringOptionsPercentile";
    CrawlSiteSpeedAuditOrderField["SegmentIdsCount"] = "segmentIdsCount";
    CrawlSiteSpeedAuditOrderField["SegmentNamesCount"] = "segmentNamesCount";
    CrawlSiteSpeedAuditOrderField["Title"] = "title";
    CrawlSiteSpeedAuditOrderField["TotalSizeKib"] = "totalSizeKib";
    CrawlSiteSpeedAuditOrderField["Url"] = "url";
    CrawlSiteSpeedAuditOrderField["UrlDigest"] = "urlDigest";
    CrawlSiteSpeedAuditOrderField["Warnings"] = "warnings";
})(CrawlSiteSpeedAuditOrderField || (CrawlSiteSpeedAuditOrderField = {}));
export var CrawlSitemapOrderField;
(function (CrawlSitemapOrderField) {
    CrawlSitemapOrderField["ContentEncoding"] = "contentEncoding";
    CrawlSitemapOrderField["ContentLength"] = "contentLength";
    CrawlSitemapOrderField["Encoding"] = "encoding";
    CrawlSitemapOrderField["Extension"] = "extension";
    CrawlSitemapOrderField["FetchError"] = "fetchError";
    CrawlSitemapOrderField["FoundAt"] = "foundAt";
    CrawlSitemapOrderField["FoundAtSource"] = "foundAtSource";
    CrawlSitemapOrderField["FullyQualifiedLocsCount"] = "fullyQualifiedLocsCount";
    CrawlSitemapOrderField["HttpStatusCode"] = "httpStatusCode";
    CrawlSitemapOrderField["LinksCount"] = "linksCount";
    CrawlSitemapOrderField["LogRequestsTotal"] = "logRequestsTotal";
    CrawlSitemapOrderField["NonFullyQualifiedUrlsCount"] = "nonFullyQualifiedUrlsCount";
    CrawlSitemapOrderField["OutsidePathLocsCount"] = "outsidePathLocsCount";
    CrawlSitemapOrderField["ParseError"] = "parseError";
    CrawlSitemapOrderField["RestrictedReason"] = "restrictedReason";
    CrawlSitemapOrderField["SitemapType"] = "sitemapType";
    /** @deprecated Use httpStatusCode instead. */
    CrawlSitemapOrderField["StatusCode"] = "statusCode";
    CrawlSitemapOrderField["Url"] = "url";
    CrawlSitemapOrderField["UrlAlias"] = "urlAlias";
    CrawlSitemapOrderField["UrlCount"] = "urlCount";
})(CrawlSitemapOrderField || (CrawlSitemapOrderField = {}));
export var CrawlStatus;
(function (CrawlStatus) {
    CrawlStatus["Archived"] = "Archived";
    CrawlStatus["Archiving"] = "Archiving";
    CrawlStatus["Crawling"] = "Crawling";
    CrawlStatus["Discovering"] = "Discovering";
    CrawlStatus["Draft"] = "Draft";
    CrawlStatus["Errored"] = "Errored";
    CrawlStatus["Finalizing"] = "Finalizing";
    CrawlStatus["Finished"] = "Finished";
    CrawlStatus["Paused"] = "Paused";
    CrawlStatus["Queued"] = "Queued";
    CrawlStatus["Queuing"] = "Queuing";
})(CrawlStatus || (CrawlStatus = {}));
export var CrawlType;
(function (CrawlType) {
    CrawlType["Backlinks"] = "Backlinks";
    CrawlType["CustomMetricsUpload"] = "CustomMetricsUpload";
    CrawlType["GoogleAnalytics"] = "GoogleAnalytics";
    CrawlType["GoogleSearchConsole"] = "GoogleSearchConsole";
    CrawlType["GoogleSearchConsoleSearchQueries"] = "GoogleSearchConsoleSearchQueries";
    CrawlType["List"] = "List";
    CrawlType["LogSummary"] = "LogSummary";
    CrawlType["Sitemap"] = "Sitemap";
    CrawlType["Task"] = "Task";
    CrawlType["Web"] = "Web";
})(CrawlType || (CrawlType = {}));
export var CrawlTypeOrderField;
(function (CrawlTypeOrderField) {
    CrawlTypeOrderField["Code"] = "code";
    CrawlTypeOrderField["Name"] = "name";
    CrawlTypeOrderField["Phase"] = "phase";
})(CrawlTypeOrderField || (CrawlTypeOrderField = {}));
export var CrawlUncrawledUrlOrderField;
(function (CrawlUncrawledUrlOrderField) {
    CrawlUncrawledUrlOrderField["FileExtension"] = "fileExtension";
    CrawlUncrawledUrlOrderField["FoundAtSitemap"] = "foundAtSitemap";
    CrawlUncrawledUrlOrderField["FoundAtUrl"] = "foundAtUrl";
    CrawlUncrawledUrlOrderField["FoundInBacklinks"] = "foundInBacklinks";
    CrawlUncrawledUrlOrderField["FoundInCustomMetricsUpload"] = "foundInCustomMetricsUpload";
    CrawlUncrawledUrlOrderField["FoundInGoogleAnalytics"] = "foundInGoogleAnalytics";
    CrawlUncrawledUrlOrderField["FoundInGoogleSearchConsole"] = "foundInGoogleSearchConsole";
    CrawlUncrawledUrlOrderField["FoundInList"] = "foundInList";
    CrawlUncrawledUrlOrderField["FoundInLogSummary"] = "foundInLogSummary";
    CrawlUncrawledUrlOrderField["FoundInSitemap"] = "foundInSitemap";
    CrawlUncrawledUrlOrderField["FoundInWebCrawl"] = "foundInWebCrawl";
    CrawlUncrawledUrlOrderField["Level"] = "level";
    CrawlUncrawledUrlOrderField["RestrictedReason"] = "restrictedReason";
    CrawlUncrawledUrlOrderField["RewriteChainCount"] = "rewriteChainCount";
    CrawlUncrawledUrlOrderField["RobotsTxtRuleMatch"] = "robotsTxtRuleMatch";
    CrawlUncrawledUrlOrderField["Tag"] = "tag";
    CrawlUncrawledUrlOrderField["Url"] = "url";
    CrawlUncrawledUrlOrderField["UrlAlias"] = "urlAlias";
})(CrawlUncrawledUrlOrderField || (CrawlUncrawledUrlOrderField = {}));
export var CrawlUniqueLinkOrderField;
(function (CrawlUniqueLinkOrderField) {
    CrawlUniqueLinkOrderField["AnchorText"] = "anchorText";
    CrawlUniqueLinkOrderField["AttrRel"] = "attrRel";
    CrawlUniqueLinkOrderField["HasImageAltText"] = "hasImageAltText";
    CrawlUniqueLinkOrderField["HasImageLink"] = "hasImageLink";
    CrawlUniqueLinkOrderField["Hreflang"] = "hreflang";
    CrawlUniqueLinkOrderField["InstanceCount"] = "instanceCount";
    CrawlUniqueLinkOrderField["Internal"] = "internal";
    CrawlUniqueLinkOrderField["IsHreflang"] = "isHreflang";
    CrawlUniqueLinkOrderField["IsImageAltTextEmpty"] = "isImageAltTextEmpty";
    CrawlUniqueLinkOrderField["IsRedirect"] = "isRedirect";
    CrawlUniqueLinkOrderField["IsRestricted"] = "isRestricted";
    CrawlUniqueLinkOrderField["LinkType"] = "linkType";
    CrawlUniqueLinkOrderField["Nofollow"] = "nofollow";
    CrawlUniqueLinkOrderField["PrimaryUrlFrom"] = "primaryUrlFrom";
    CrawlUniqueLinkOrderField["PrimaryUrlFromAlias"] = "primaryUrlFromAlias";
    CrawlUniqueLinkOrderField["PrimaryUrlFromDeeprank"] = "primaryUrlFromDeeprank";
    CrawlUniqueLinkOrderField["PrimaryUrlFromHttps"] = "primaryUrlFromHttps";
    CrawlUniqueLinkOrderField["PrimaryUrlFromStatusCode"] = "primaryUrlFromStatusCode";
    CrawlUniqueLinkOrderField["PrimaryUrlFromTitle"] = "primaryUrlFromTitle";
    CrawlUniqueLinkOrderField["Tag"] = "tag";
    CrawlUniqueLinkOrderField["UrlTo"] = "urlTo";
    CrawlUniqueLinkOrderField["UrlToAlias"] = "urlToAlias";
    CrawlUniqueLinkOrderField["UrlToDeeprank"] = "urlToDeeprank";
    CrawlUniqueLinkOrderField["UrlToHttps"] = "urlToHttps";
    CrawlUniqueLinkOrderField["UrlToStatusCode"] = "urlToStatusCode";
    CrawlUniqueLinkOrderField["UrlToTitle"] = "urlToTitle";
})(CrawlUniqueLinkOrderField || (CrawlUniqueLinkOrderField = {}));
/** Used for grouping the CrawlUrl aggregates results. */
export var CrawlUrlAggregateDimension;
(function (CrawlUrlAggregateDimension) {
    CrawlUrlAggregateDimension["AdultRating"] = "adultRating";
    CrawlUrlAggregateDimension["Amphtml"] = "amphtml";
    CrawlUrlAggregateDimension["AmphtmlReciprocate"] = "amphtmlReciprocate";
    CrawlUrlAggregateDimension["Breadcrumb01"] = "breadcrumb01";
    CrawlUrlAggregateDimension["Breadcrumb02"] = "breadcrumb02";
    CrawlUrlAggregateDimension["Breadcrumb03"] = "breadcrumb03";
    CrawlUrlAggregateDimension["Breadcrumb04"] = "breadcrumb04";
    CrawlUrlAggregateDimension["Breadcrumb05"] = "breadcrumb05";
    CrawlUrlAggregateDimension["Breadcrumb06"] = "breadcrumb06";
    CrawlUrlAggregateDimension["Breadcrumb07"] = "breadcrumb07";
    CrawlUrlAggregateDimension["Breadcrumb08"] = "breadcrumb08";
    CrawlUrlAggregateDimension["CacheControl"] = "cacheControl";
    CrawlUrlAggregateDimension["CanonicalUrlIsConsistent"] = "canonicalUrlIsConsistent";
    CrawlUrlAggregateDimension["CanonicalizedPage"] = "canonicalizedPage";
    CrawlUrlAggregateDimension["Css"] = "css";
    CrawlUrlAggregateDimension["CustomExtractionMatch"] = "customExtractionMatch";
    CrawlUrlAggregateDimension["DisallowedPage"] = "disallowedPage";
    CrawlUrlAggregateDimension["DuplicateBody"] = "duplicateBody";
    CrawlUrlAggregateDimension["DuplicateBodyNonIndexable"] = "duplicateBodyNonIndexable";
    CrawlUrlAggregateDimension["DuplicateDescription"] = "duplicateDescription";
    CrawlUrlAggregateDimension["DuplicateDescriptionNonIndexable"] = "duplicateDescriptionNonIndexable";
    CrawlUrlAggregateDimension["DuplicatePage"] = "duplicatePage";
    CrawlUrlAggregateDimension["DuplicatePageNonIndexable"] = "duplicatePageNonIndexable";
    CrawlUrlAggregateDimension["DuplicateTitle"] = "duplicateTitle";
    CrawlUrlAggregateDimension["DuplicateTitleNonIndexable"] = "duplicateTitleNonIndexable";
    CrawlUrlAggregateDimension["DuplicateUrl"] = "duplicateUrl";
    CrawlUrlAggregateDimension["DuplicateUrlNonIndexable"] = "duplicateUrlNonIndexable";
    CrawlUrlAggregateDimension["FailedReason"] = "failedReason";
    CrawlUrlAggregateDimension["FileExtension"] = "fileExtension";
    CrawlUrlAggregateDimension["HeaderContentType"] = "headerContentType";
    CrawlUrlAggregateDimension["HttpStatusCode"] = "httpStatusCode";
    CrawlUrlAggregateDimension["Https"] = "https";
    CrawlUrlAggregateDimension["Indexable"] = "indexable";
    CrawlUrlAggregateDimension["IsImage"] = "isImage";
    CrawlUrlAggregateDimension["IsSelfCanonical"] = "isSelfCanonical";
    CrawlUrlAggregateDimension["IsWebLinked"] = "isWebLinked";
    CrawlUrlAggregateDimension["Js"] = "js";
    CrawlUrlAggregateDimension["Level"] = "level";
    CrawlUrlAggregateDimension["MetaContentType"] = "metaContentType";
    CrawlUrlAggregateDimension["NofollowedPage"] = "nofollowedPage";
    CrawlUrlAggregateDimension["Noindex"] = "noindex";
    CrawlUrlAggregateDimension["OgLocale"] = "ogLocale";
    CrawlUrlAggregateDimension["OgType"] = "ogType";
    CrawlUrlAggregateDimension["PageGroup"] = "pageGroup";
    CrawlUrlAggregateDimension["Path0"] = "path0";
    CrawlUrlAggregateDimension["Path1"] = "path1";
    CrawlUrlAggregateDimension["Path2"] = "path2";
    CrawlUrlAggregateDimension["Path3"] = "path3";
    CrawlUrlAggregateDimension["Path4"] = "path4";
    CrawlUrlAggregateDimension["Path5"] = "path5";
    CrawlUrlAggregateDimension["Path6"] = "path6";
    CrawlUrlAggregateDimension["Path7"] = "path7";
    CrawlUrlAggregateDimension["Path8"] = "path8";
    CrawlUrlAggregateDimension["Path9"] = "path9";
    CrawlUrlAggregateDimension["UrlContainsRepeatedPaths"] = "urlContainsRepeatedPaths";
    CrawlUrlAggregateDimension["UrlContainsUnderscores"] = "urlContainsUnderscores";
    CrawlUrlAggregateDimension["UrlDoubleEncoded"] = "urlDoubleEncoded";
    CrawlUrlAggregateDimension["UrlPathContainsDoubleSlashes"] = "urlPathContainsDoubleSlashes";
    CrawlUrlAggregateDimension["Viewport"] = "viewport";
})(CrawlUrlAggregateDimension || (CrawlUrlAggregateDimension = {}));
export var CrawlUrlAggregateOrderField;
(function (CrawlUrlAggregateOrderField) {
    CrawlUrlAggregateOrderField["AdultRating"] = "adultRating";
    CrawlUrlAggregateOrderField["Amphtml"] = "amphtml";
    CrawlUrlAggregateOrderField["AmphtmlReciprocate"] = "amphtmlReciprocate";
    CrawlUrlAggregateOrderField["AvgAccessibilityAIssuesCount"] = "avgAccessibilityAIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityAaIssuesCount"] = "avgAccessibilityAaIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityAaaIssuesCount"] = "avgAccessibilityAaaIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityBpIssuesCount"] = "avgAccessibilityBpIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityCriticalIssuesCount"] = "avgAccessibilityCriticalIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityIncompleteIssuesCount"] = "avgAccessibilityIncompleteIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityIssuesCount"] = "avgAccessibilityIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityMinorIssuesCount"] = "avgAccessibilityMinorIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilityModerateIssuesCount"] = "avgAccessibilityModerateIssuesCount";
    CrawlUrlAggregateOrderField["AvgAccessibilitySeriousIssuesCount"] = "avgAccessibilitySeriousIssuesCount";
    CrawlUrlAggregateOrderField["AvgBacklinkCount"] = "avgBacklinkCount";
    CrawlUrlAggregateOrderField["AvgBacklinkDomainCount"] = "avgBacklinkDomainCount";
    CrawlUrlAggregateOrderField["AvgBodyTagsInHeadCount"] = "avgBodyTagsInHeadCount";
    CrawlUrlAggregateOrderField["AvgBreadcrumbCount"] = "avgBreadcrumbCount";
    CrawlUrlAggregateOrderField["AvgBreadcrumbSchemaCount"] = "avgBreadcrumbSchemaCount";
    CrawlUrlAggregateOrderField["AvgCanonicalLinksInCount"] = "avgCanonicalLinksInCount";
    CrawlUrlAggregateOrderField["AvgContentHtmlRatio"] = "avgContentHtmlRatio";
    CrawlUrlAggregateOrderField["AvgContentSize"] = "avgContentSize";
    CrawlUrlAggregateOrderField["AvgCumulativeLayoutShift"] = "avgCumulativeLayoutShift";
    CrawlUrlAggregateOrderField["AvgCumulativeLayoutShiftMainFrame"] = "avgCumulativeLayoutShiftMainFrame";
    CrawlUrlAggregateOrderField["AvgCumulativeLayoutShiftScore"] = "avgCumulativeLayoutShiftScore";
    CrawlUrlAggregateOrderField["AvgDeeprank"] = "avgDeeprank";
    CrawlUrlAggregateOrderField["AvgDescriptionLength"] = "avgDescriptionLength";
    CrawlUrlAggregateOrderField["AvgDescriptionLengthPx"] = "avgDescriptionLengthPx";
    CrawlUrlAggregateOrderField["AvgDuplicateBodyCount"] = "avgDuplicateBodyCount";
    CrawlUrlAggregateOrderField["AvgDuplicateDescriptionCount"] = "avgDuplicateDescriptionCount";
    CrawlUrlAggregateOrderField["AvgDuplicatePageCount"] = "avgDuplicatePageCount";
    CrawlUrlAggregateOrderField["AvgDuplicateTitleCount"] = "avgDuplicateTitleCount";
    CrawlUrlAggregateOrderField["AvgDuplicateUrlCount"] = "avgDuplicateUrlCount";
    CrawlUrlAggregateOrderField["AvgEventSchemaCount"] = "avgEventSchemaCount";
    CrawlUrlAggregateOrderField["AvgExternalLinksCount"] = "avgExternalLinksCount";
    CrawlUrlAggregateOrderField["AvgFailedAuditsCount"] = "avgFailedAuditsCount";
    CrawlUrlAggregateOrderField["AvgFaqpageSchemaCount"] = "avgFaqpageSchemaCount";
    CrawlUrlAggregateOrderField["AvgFetchTime"] = "avgFetchTime";
    CrawlUrlAggregateOrderField["AvgFirstContentfulPaint"] = "avgFirstContentfulPaint";
    CrawlUrlAggregateOrderField["AvgFirstContentfulPaintScore"] = "avgFirstContentfulPaintScore";
    CrawlUrlAggregateOrderField["AvgFirstMeaningfulPaint"] = "avgFirstMeaningfulPaint";
    CrawlUrlAggregateOrderField["AvgFolderCount"] = "avgFolderCount";
    CrawlUrlAggregateOrderField["AvgFollowedExternalLinksOutCount"] = "avgFollowedExternalLinksOutCount";
    CrawlUrlAggregateOrderField["AvgFollowedLinksInCount"] = "avgFollowedLinksInCount";
    CrawlUrlAggregateOrderField["AvgGaAvgPageLoadTime"] = "avgGaAvgPageLoadTime";
    CrawlUrlAggregateOrderField["AvgGaAvgTimeOnPage"] = "avgGaAvgTimeOnPage";
    CrawlUrlAggregateOrderField["AvgGaPageviewsPerVisits"] = "avgGaPageviewsPerVisits";
    CrawlUrlAggregateOrderField["AvgGaVisitBounceRate"] = "avgGaVisitBounceRate";
    CrawlUrlAggregateOrderField["AvgGaVisits"] = "avgGaVisits";
    CrawlUrlAggregateOrderField["AvgH1Count"] = "avgH1Count";
    CrawlUrlAggregateOrderField["AvgH1Length"] = "avgH1Length";
    CrawlUrlAggregateOrderField["AvgHeadTagsInBodyCount"] = "avgHeadTagsInBodyCount";
    CrawlUrlAggregateOrderField["AvgHowtoSchemaCount"] = "avgHowtoSchemaCount";
    CrawlUrlAggregateOrderField["AvgHreflangLinksInCount"] = "avgHreflangLinksInCount";
    CrawlUrlAggregateOrderField["AvgHtmlSize"] = "avgHtmlSize";
    CrawlUrlAggregateOrderField["AvgInfoAuditsCount"] = "avgInfoAuditsCount";
    CrawlUrlAggregateOrderField["AvgInteractive"] = "avgInteractive";
    CrawlUrlAggregateOrderField["AvgInternalLinksCount"] = "avgInternalLinksCount";
    CrawlUrlAggregateOrderField["AvgLargestContentfulPaint"] = "avgLargestContentfulPaint";
    CrawlUrlAggregateOrderField["AvgLargestContentfulPaintScore"] = "avgLargestContentfulPaintScore";
    CrawlUrlAggregateOrderField["AvgLcpLoadEnd"] = "avgLcpLoadEnd";
    CrawlUrlAggregateOrderField["AvgLcpLoadStart"] = "avgLcpLoadStart";
    CrawlUrlAggregateOrderField["AvgLinksInCount"] = "avgLinksInCount";
    CrawlUrlAggregateOrderField["AvgLinksOutCount"] = "avgLinksOutCount";
    CrawlUrlAggregateOrderField["AvgLogRequestsDesktop"] = "avgLogRequestsDesktop";
    CrawlUrlAggregateOrderField["AvgLogRequestsMobile"] = "avgLogRequestsMobile";
    CrawlUrlAggregateOrderField["AvgLogRequestsTotal"] = "avgLogRequestsTotal";
    CrawlUrlAggregateOrderField["AvgMaxPotentialFid"] = "avgMaxPotentialFid";
    CrawlUrlAggregateOrderField["AvgMobileDesktopContentDifference"] = "avgMobileDesktopContentDifference";
    CrawlUrlAggregateOrderField["AvgMobileDesktopLinksInDifference"] = "avgMobileDesktopLinksInDifference";
    CrawlUrlAggregateOrderField["AvgMobileDesktopLinksOutDifference"] = "avgMobileDesktopLinksOutDifference";
    CrawlUrlAggregateOrderField["AvgMobileLinksInCount"] = "avgMobileLinksInCount";
    CrawlUrlAggregateOrderField["AvgNewsarticleSchemaCount"] = "avgNewsarticleSchemaCount";
    CrawlUrlAggregateOrderField["AvgNonReciprocateHreflangOutCount"] = "avgNonReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["AvgPageTitleLength"] = "avgPageTitleLength";
    CrawlUrlAggregateOrderField["AvgPageTitleLengthPx"] = "avgPageTitleLengthPx";
    CrawlUrlAggregateOrderField["AvgPaginatedLinksInCount"] = "avgPaginatedLinksInCount";
    CrawlUrlAggregateOrderField["AvgParamCount"] = "avgParamCount";
    CrawlUrlAggregateOrderField["AvgPassedAuditsCount"] = "avgPassedAuditsCount";
    CrawlUrlAggregateOrderField["AvgPerformanceCls"] = "avgPerformanceCls";
    CrawlUrlAggregateOrderField["AvgPerformanceDcl"] = "avgPerformanceDcl";
    CrawlUrlAggregateOrderField["AvgPerformanceFcp"] = "avgPerformanceFcp";
    CrawlUrlAggregateOrderField["AvgPerformanceLcp"] = "avgPerformanceLcp";
    CrawlUrlAggregateOrderField["AvgPerformanceTtfb"] = "avgPerformanceTtfb";
    CrawlUrlAggregateOrderField["AvgPerformanceTti"] = "avgPerformanceTti";
    CrawlUrlAggregateOrderField["AvgProductOfSavingsKibAndPageviews"] = "avgProductOfSavingsKibAndPageviews";
    CrawlUrlAggregateOrderField["AvgProductOfSavingsSecsAndPageviews"] = "avgProductOfSavingsSecsAndPageviews";
    CrawlUrlAggregateOrderField["AvgProductPriceSchemaValue"] = "avgProductPriceSchemaValue";
    CrawlUrlAggregateOrderField["AvgProductRatingSchemaValue"] = "avgProductRatingSchemaValue";
    CrawlUrlAggregateOrderField["AvgProductSchemaCount"] = "avgProductSchemaCount";
    CrawlUrlAggregateOrderField["AvgQapageSchemaCount"] = "avgQapageSchemaCount";
    CrawlUrlAggregateOrderField["AvgRecipeSchemaCount"] = "avgRecipeSchemaCount";
    CrawlUrlAggregateOrderField["AvgReciprocateHreflangOutCount"] = "avgReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["AvgRedirectCount"] = "avgRedirectCount";
    CrawlUrlAggregateOrderField["AvgRedirectsInCount"] = "avgRedirectsInCount";
    CrawlUrlAggregateOrderField["AvgRelLinksInCount"] = "avgRelLinksInCount";
    CrawlUrlAggregateOrderField["AvgRenderedLinkCountDifference"] = "avgRenderedLinkCountDifference";
    CrawlUrlAggregateOrderField["AvgRenderedWordCountDifference"] = "avgRenderedWordCountDifference";
    CrawlUrlAggregateOrderField["AvgResourceLinksInCount"] = "avgResourceLinksInCount";
    CrawlUrlAggregateOrderField["AvgReviewSchemaCount"] = "avgReviewSchemaCount";
    CrawlUrlAggregateOrderField["AvgSavingsKib"] = "avgSavingsKib";
    CrawlUrlAggregateOrderField["AvgSavingsScore"] = "avgSavingsScore";
    CrawlUrlAggregateOrderField["AvgSavingsSecs"] = "avgSavingsSecs";
    CrawlUrlAggregateOrderField["AvgSearchConsoleDesktopClicks"] = "avgSearchConsoleDesktopClicks";
    CrawlUrlAggregateOrderField["AvgSearchConsoleDesktopCtr"] = "avgSearchConsoleDesktopCtr";
    CrawlUrlAggregateOrderField["AvgSearchConsoleDesktopImpressions"] = "avgSearchConsoleDesktopImpressions";
    CrawlUrlAggregateOrderField["AvgSearchConsoleDesktopPosition"] = "avgSearchConsoleDesktopPosition";
    CrawlUrlAggregateOrderField["AvgSearchConsoleMobileClicks"] = "avgSearchConsoleMobileClicks";
    CrawlUrlAggregateOrderField["AvgSearchConsoleMobileCtr"] = "avgSearchConsoleMobileCtr";
    CrawlUrlAggregateOrderField["AvgSearchConsoleMobileImpressions"] = "avgSearchConsoleMobileImpressions";
    CrawlUrlAggregateOrderField["AvgSearchConsoleMobilePosition"] = "avgSearchConsoleMobilePosition";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTabletClicks"] = "avgSearchConsoleTabletClicks";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTabletCtr"] = "avgSearchConsoleTabletCtr";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTabletImpressions"] = "avgSearchConsoleTabletImpressions";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTabletPosition"] = "avgSearchConsoleTabletPosition";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTotalClicks"] = "avgSearchConsoleTotalClicks";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTotalCtr"] = "avgSearchConsoleTotalCtr";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTotalImpressions"] = "avgSearchConsoleTotalImpressions";
    CrawlUrlAggregateOrderField["AvgSearchConsoleTotalPosition"] = "avgSearchConsoleTotalPosition";
    CrawlUrlAggregateOrderField["AvgSitemapsInCount"] = "avgSitemapsInCount";
    CrawlUrlAggregateOrderField["AvgSitespeedCls"] = "avgSitespeedCls";
    CrawlUrlAggregateOrderField["AvgSitespeedClsMainFrame"] = "avgSitespeedClsMainFrame";
    CrawlUrlAggregateOrderField["AvgSitespeedClsScore"] = "avgSitespeedClsScore";
    CrawlUrlAggregateOrderField["AvgSitespeedFcp"] = "avgSitespeedFcp";
    CrawlUrlAggregateOrderField["AvgSitespeedFcpScore"] = "avgSitespeedFcpScore";
    CrawlUrlAggregateOrderField["AvgSitespeedFmp"] = "avgSitespeedFmp";
    CrawlUrlAggregateOrderField["AvgSitespeedLcp"] = "avgSitespeedLcp";
    CrawlUrlAggregateOrderField["AvgSitespeedLcpScore"] = "avgSitespeedLcpScore";
    CrawlUrlAggregateOrderField["AvgSitespeedMaxPotentialFid"] = "avgSitespeedMaxPotentialFid";
    CrawlUrlAggregateOrderField["AvgSitespeedScore"] = "avgSitespeedScore";
    CrawlUrlAggregateOrderField["AvgSitespeedSpeedIndex"] = "avgSitespeedSpeedIndex";
    CrawlUrlAggregateOrderField["AvgSitespeedSpeedIndexScore"] = "avgSitespeedSpeedIndexScore";
    CrawlUrlAggregateOrderField["AvgSitespeedTbt"] = "avgSitespeedTbt";
    CrawlUrlAggregateOrderField["AvgSitespeedTbtScore"] = "avgSitespeedTbtScore";
    CrawlUrlAggregateOrderField["AvgSitespeedTtfb"] = "avgSitespeedTtfb";
    CrawlUrlAggregateOrderField["AvgSitespeedTti"] = "avgSitespeedTti";
    CrawlUrlAggregateOrderField["AvgSpeedIndex"] = "avgSpeedIndex";
    CrawlUrlAggregateOrderField["AvgSpeedIndexScore"] = "avgSpeedIndexScore";
    CrawlUrlAggregateOrderField["AvgTimeToFirstByte"] = "avgTimeToFirstByte";
    CrawlUrlAggregateOrderField["AvgTotalBlockingTime"] = "avgTotalBlockingTime";
    CrawlUrlAggregateOrderField["AvgTotalBlockingTimeScore"] = "avgTotalBlockingTimeScore";
    CrawlUrlAggregateOrderField["AvgUniqueLinksInCount"] = "avgUniqueLinksInCount";
    CrawlUrlAggregateOrderField["AvgVideoobjectSchemaCount"] = "avgVideoobjectSchemaCount";
    CrawlUrlAggregateOrderField["AvgWarningAuditsCount"] = "avgWarningAuditsCount";
    CrawlUrlAggregateOrderField["AvgWordCount"] = "avgWordCount";
    CrawlUrlAggregateOrderField["Breadcrumb01"] = "breadcrumb01";
    CrawlUrlAggregateOrderField["Breadcrumb02"] = "breadcrumb02";
    CrawlUrlAggregateOrderField["Breadcrumb03"] = "breadcrumb03";
    CrawlUrlAggregateOrderField["Breadcrumb04"] = "breadcrumb04";
    CrawlUrlAggregateOrderField["Breadcrumb05"] = "breadcrumb05";
    CrawlUrlAggregateOrderField["Breadcrumb06"] = "breadcrumb06";
    CrawlUrlAggregateOrderField["Breadcrumb07"] = "breadcrumb07";
    CrawlUrlAggregateOrderField["Breadcrumb08"] = "breadcrumb08";
    CrawlUrlAggregateOrderField["CacheControl"] = "cacheControl";
    CrawlUrlAggregateOrderField["CanonicalUrlIsConsistent"] = "canonicalUrlIsConsistent";
    CrawlUrlAggregateOrderField["CanonicalizedPage"] = "canonicalizedPage";
    CrawlUrlAggregateOrderField["CountAccessibilityAIssuesCount"] = "countAccessibilityAIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityAaIssuesCount"] = "countAccessibilityAaIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityAaaIssuesCount"] = "countAccessibilityAaaIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityBpIssuesCount"] = "countAccessibilityBpIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityCriticalIssuesCount"] = "countAccessibilityCriticalIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityIncompleteIssuesCount"] = "countAccessibilityIncompleteIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityIssuesCount"] = "countAccessibilityIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityMinorIssuesCount"] = "countAccessibilityMinorIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilityModerateIssuesCount"] = "countAccessibilityModerateIssuesCount";
    CrawlUrlAggregateOrderField["CountAccessibilitySeriousIssuesCount"] = "countAccessibilitySeriousIssuesCount";
    CrawlUrlAggregateOrderField["CountBacklinkCount"] = "countBacklinkCount";
    CrawlUrlAggregateOrderField["CountBacklinkDomainCount"] = "countBacklinkDomainCount";
    CrawlUrlAggregateOrderField["CountBodyTagsInHeadCount"] = "countBodyTagsInHeadCount";
    CrawlUrlAggregateOrderField["CountBreadcrumbCount"] = "countBreadcrumbCount";
    CrawlUrlAggregateOrderField["CountBreadcrumbSchemaCount"] = "countBreadcrumbSchemaCount";
    CrawlUrlAggregateOrderField["CountCanonicalLinksInCount"] = "countCanonicalLinksInCount";
    CrawlUrlAggregateOrderField["CountContentHtmlRatio"] = "countContentHtmlRatio";
    CrawlUrlAggregateOrderField["CountContentSize"] = "countContentSize";
    CrawlUrlAggregateOrderField["CountCumulativeLayoutShift"] = "countCumulativeLayoutShift";
    CrawlUrlAggregateOrderField["CountCumulativeLayoutShiftMainFrame"] = "countCumulativeLayoutShiftMainFrame";
    CrawlUrlAggregateOrderField["CountCumulativeLayoutShiftScore"] = "countCumulativeLayoutShiftScore";
    CrawlUrlAggregateOrderField["CountDeeprank"] = "countDeeprank";
    CrawlUrlAggregateOrderField["CountDescriptionLength"] = "countDescriptionLength";
    CrawlUrlAggregateOrderField["CountDescriptionLengthPx"] = "countDescriptionLengthPx";
    CrawlUrlAggregateOrderField["CountDuplicateBodyCount"] = "countDuplicateBodyCount";
    CrawlUrlAggregateOrderField["CountDuplicateDescriptionCount"] = "countDuplicateDescriptionCount";
    CrawlUrlAggregateOrderField["CountDuplicatePageCount"] = "countDuplicatePageCount";
    CrawlUrlAggregateOrderField["CountDuplicateTitleCount"] = "countDuplicateTitleCount";
    CrawlUrlAggregateOrderField["CountDuplicateUrlCount"] = "countDuplicateUrlCount";
    CrawlUrlAggregateOrderField["CountEventSchemaCount"] = "countEventSchemaCount";
    CrawlUrlAggregateOrderField["CountExternalLinksCount"] = "countExternalLinksCount";
    CrawlUrlAggregateOrderField["CountFailedAuditsCount"] = "countFailedAuditsCount";
    CrawlUrlAggregateOrderField["CountFaqpageSchemaCount"] = "countFaqpageSchemaCount";
    CrawlUrlAggregateOrderField["CountFetchTime"] = "countFetchTime";
    CrawlUrlAggregateOrderField["CountFirstContentfulPaint"] = "countFirstContentfulPaint";
    CrawlUrlAggregateOrderField["CountFirstContentfulPaintScore"] = "countFirstContentfulPaintScore";
    CrawlUrlAggregateOrderField["CountFirstMeaningfulPaint"] = "countFirstMeaningfulPaint";
    CrawlUrlAggregateOrderField["CountFolderCount"] = "countFolderCount";
    CrawlUrlAggregateOrderField["CountFollowedExternalLinksOutCount"] = "countFollowedExternalLinksOutCount";
    CrawlUrlAggregateOrderField["CountFollowedLinksInCount"] = "countFollowedLinksInCount";
    CrawlUrlAggregateOrderField["CountFoundAtSitemap"] = "countFoundAtSitemap";
    CrawlUrlAggregateOrderField["CountGaAvgPageLoadTime"] = "countGaAvgPageLoadTime";
    CrawlUrlAggregateOrderField["CountGaAvgTimeOnPage"] = "countGaAvgTimeOnPage";
    CrawlUrlAggregateOrderField["CountGaPageviewsPerVisits"] = "countGaPageviewsPerVisits";
    CrawlUrlAggregateOrderField["CountGaVisitBounceRate"] = "countGaVisitBounceRate";
    CrawlUrlAggregateOrderField["CountGaVisits"] = "countGaVisits";
    CrawlUrlAggregateOrderField["CountH1Count"] = "countH1Count";
    CrawlUrlAggregateOrderField["CountH1Length"] = "countH1Length";
    CrawlUrlAggregateOrderField["CountHeadTagsInBodyCount"] = "countHeadTagsInBodyCount";
    CrawlUrlAggregateOrderField["CountHowtoSchemaCount"] = "countHowtoSchemaCount";
    CrawlUrlAggregateOrderField["CountHreflangLinksInCount"] = "countHreflangLinksInCount";
    CrawlUrlAggregateOrderField["CountHtmlSize"] = "countHtmlSize";
    CrawlUrlAggregateOrderField["CountInfoAuditsCount"] = "countInfoAuditsCount";
    CrawlUrlAggregateOrderField["CountInteractive"] = "countInteractive";
    CrawlUrlAggregateOrderField["CountInternalLinksCount"] = "countInternalLinksCount";
    CrawlUrlAggregateOrderField["CountLargestContentfulPaint"] = "countLargestContentfulPaint";
    CrawlUrlAggregateOrderField["CountLargestContentfulPaintScore"] = "countLargestContentfulPaintScore";
    CrawlUrlAggregateOrderField["CountLcpLoadEnd"] = "countLcpLoadEnd";
    CrawlUrlAggregateOrderField["CountLcpLoadStart"] = "countLcpLoadStart";
    CrawlUrlAggregateOrderField["CountLinksInCount"] = "countLinksInCount";
    CrawlUrlAggregateOrderField["CountLinksOutCount"] = "countLinksOutCount";
    CrawlUrlAggregateOrderField["CountLogRequestsDesktop"] = "countLogRequestsDesktop";
    CrawlUrlAggregateOrderField["CountLogRequestsMobile"] = "countLogRequestsMobile";
    CrawlUrlAggregateOrderField["CountLogRequestsTotal"] = "countLogRequestsTotal";
    CrawlUrlAggregateOrderField["CountMaxPotentialFid"] = "countMaxPotentialFid";
    CrawlUrlAggregateOrderField["CountMobileDesktopContentDifference"] = "countMobileDesktopContentDifference";
    CrawlUrlAggregateOrderField["CountMobileDesktopLinksInDifference"] = "countMobileDesktopLinksInDifference";
    CrawlUrlAggregateOrderField["CountMobileDesktopLinksOutDifference"] = "countMobileDesktopLinksOutDifference";
    CrawlUrlAggregateOrderField["CountMobileLinksInCount"] = "countMobileLinksInCount";
    CrawlUrlAggregateOrderField["CountNewsarticleSchemaCount"] = "countNewsarticleSchemaCount";
    CrawlUrlAggregateOrderField["CountNonReciprocateHreflangOutCount"] = "countNonReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["CountPageTitleLength"] = "countPageTitleLength";
    CrawlUrlAggregateOrderField["CountPageTitleLengthPx"] = "countPageTitleLengthPx";
    CrawlUrlAggregateOrderField["CountPaginatedLinksInCount"] = "countPaginatedLinksInCount";
    CrawlUrlAggregateOrderField["CountParamCount"] = "countParamCount";
    CrawlUrlAggregateOrderField["CountPassedAuditsCount"] = "countPassedAuditsCount";
    CrawlUrlAggregateOrderField["CountPerformanceCls"] = "countPerformanceCls";
    CrawlUrlAggregateOrderField["CountPerformanceDcl"] = "countPerformanceDcl";
    CrawlUrlAggregateOrderField["CountPerformanceFcp"] = "countPerformanceFcp";
    CrawlUrlAggregateOrderField["CountPerformanceLcp"] = "countPerformanceLcp";
    CrawlUrlAggregateOrderField["CountPerformanceTtfb"] = "countPerformanceTtfb";
    CrawlUrlAggregateOrderField["CountPerformanceTti"] = "countPerformanceTti";
    CrawlUrlAggregateOrderField["CountProductOfSavingsKibAndPageviews"] = "countProductOfSavingsKibAndPageviews";
    CrawlUrlAggregateOrderField["CountProductOfSavingsSecsAndPageviews"] = "countProductOfSavingsSecsAndPageviews";
    CrawlUrlAggregateOrderField["CountProductPriceSchemaValue"] = "countProductPriceSchemaValue";
    CrawlUrlAggregateOrderField["CountProductRatingSchemaValue"] = "countProductRatingSchemaValue";
    CrawlUrlAggregateOrderField["CountProductSchemaCount"] = "countProductSchemaCount";
    CrawlUrlAggregateOrderField["CountQapageSchemaCount"] = "countQapageSchemaCount";
    CrawlUrlAggregateOrderField["CountRecipeSchemaCount"] = "countRecipeSchemaCount";
    CrawlUrlAggregateOrderField["CountReciprocateHreflangOutCount"] = "countReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["CountRedirectCount"] = "countRedirectCount";
    CrawlUrlAggregateOrderField["CountRedirectsInCount"] = "countRedirectsInCount";
    CrawlUrlAggregateOrderField["CountRelLinksInCount"] = "countRelLinksInCount";
    CrawlUrlAggregateOrderField["CountRenderedLinkCountDifference"] = "countRenderedLinkCountDifference";
    CrawlUrlAggregateOrderField["CountRenderedWordCountDifference"] = "countRenderedWordCountDifference";
    CrawlUrlAggregateOrderField["CountResourceLinksInCount"] = "countResourceLinksInCount";
    CrawlUrlAggregateOrderField["CountReviewSchemaCount"] = "countReviewSchemaCount";
    CrawlUrlAggregateOrderField["CountSavingsKib"] = "countSavingsKib";
    CrawlUrlAggregateOrderField["CountSavingsScore"] = "countSavingsScore";
    CrawlUrlAggregateOrderField["CountSavingsSecs"] = "countSavingsSecs";
    CrawlUrlAggregateOrderField["CountSearchConsoleDesktopClicks"] = "countSearchConsoleDesktopClicks";
    CrawlUrlAggregateOrderField["CountSearchConsoleDesktopCtr"] = "countSearchConsoleDesktopCtr";
    CrawlUrlAggregateOrderField["CountSearchConsoleDesktopImpressions"] = "countSearchConsoleDesktopImpressions";
    CrawlUrlAggregateOrderField["CountSearchConsoleDesktopPosition"] = "countSearchConsoleDesktopPosition";
    CrawlUrlAggregateOrderField["CountSearchConsoleMobileClicks"] = "countSearchConsoleMobileClicks";
    CrawlUrlAggregateOrderField["CountSearchConsoleMobileCtr"] = "countSearchConsoleMobileCtr";
    CrawlUrlAggregateOrderField["CountSearchConsoleMobileImpressions"] = "countSearchConsoleMobileImpressions";
    CrawlUrlAggregateOrderField["CountSearchConsoleMobilePosition"] = "countSearchConsoleMobilePosition";
    CrawlUrlAggregateOrderField["CountSearchConsoleTabletClicks"] = "countSearchConsoleTabletClicks";
    CrawlUrlAggregateOrderField["CountSearchConsoleTabletCtr"] = "countSearchConsoleTabletCtr";
    CrawlUrlAggregateOrderField["CountSearchConsoleTabletImpressions"] = "countSearchConsoleTabletImpressions";
    CrawlUrlAggregateOrderField["CountSearchConsoleTabletPosition"] = "countSearchConsoleTabletPosition";
    CrawlUrlAggregateOrderField["CountSearchConsoleTotalClicks"] = "countSearchConsoleTotalClicks";
    CrawlUrlAggregateOrderField["CountSearchConsoleTotalCtr"] = "countSearchConsoleTotalCtr";
    CrawlUrlAggregateOrderField["CountSearchConsoleTotalImpressions"] = "countSearchConsoleTotalImpressions";
    CrawlUrlAggregateOrderField["CountSearchConsoleTotalPosition"] = "countSearchConsoleTotalPosition";
    CrawlUrlAggregateOrderField["CountSitemapsInCount"] = "countSitemapsInCount";
    CrawlUrlAggregateOrderField["CountSitespeedCls"] = "countSitespeedCls";
    CrawlUrlAggregateOrderField["CountSitespeedClsMainFrame"] = "countSitespeedClsMainFrame";
    CrawlUrlAggregateOrderField["CountSitespeedClsScore"] = "countSitespeedClsScore";
    CrawlUrlAggregateOrderField["CountSitespeedFcp"] = "countSitespeedFcp";
    CrawlUrlAggregateOrderField["CountSitespeedFcpScore"] = "countSitespeedFcpScore";
    CrawlUrlAggregateOrderField["CountSitespeedFmp"] = "countSitespeedFmp";
    CrawlUrlAggregateOrderField["CountSitespeedLcp"] = "countSitespeedLcp";
    CrawlUrlAggregateOrderField["CountSitespeedLcpScore"] = "countSitespeedLcpScore";
    CrawlUrlAggregateOrderField["CountSitespeedMaxPotentialFid"] = "countSitespeedMaxPotentialFid";
    CrawlUrlAggregateOrderField["CountSitespeedScore"] = "countSitespeedScore";
    CrawlUrlAggregateOrderField["CountSitespeedSpeedIndex"] = "countSitespeedSpeedIndex";
    CrawlUrlAggregateOrderField["CountSitespeedSpeedIndexScore"] = "countSitespeedSpeedIndexScore";
    CrawlUrlAggregateOrderField["CountSitespeedTbt"] = "countSitespeedTbt";
    CrawlUrlAggregateOrderField["CountSitespeedTbtScore"] = "countSitespeedTbtScore";
    CrawlUrlAggregateOrderField["CountSitespeedTtfb"] = "countSitespeedTtfb";
    CrawlUrlAggregateOrderField["CountSitespeedTti"] = "countSitespeedTti";
    CrawlUrlAggregateOrderField["CountSpeedIndex"] = "countSpeedIndex";
    CrawlUrlAggregateOrderField["CountSpeedIndexScore"] = "countSpeedIndexScore";
    CrawlUrlAggregateOrderField["CountTimeToFirstByte"] = "countTimeToFirstByte";
    CrawlUrlAggregateOrderField["CountTotalBlockingTime"] = "countTotalBlockingTime";
    CrawlUrlAggregateOrderField["CountTotalBlockingTimeScore"] = "countTotalBlockingTimeScore";
    CrawlUrlAggregateOrderField["CountUniqueLinksInCount"] = "countUniqueLinksInCount";
    CrawlUrlAggregateOrderField["CountUrl"] = "countUrl";
    CrawlUrlAggregateOrderField["CountVideoobjectSchemaCount"] = "countVideoobjectSchemaCount";
    CrawlUrlAggregateOrderField["CountWarningAuditsCount"] = "countWarningAuditsCount";
    CrawlUrlAggregateOrderField["CountWordCount"] = "countWordCount";
    CrawlUrlAggregateOrderField["Css"] = "css";
    CrawlUrlAggregateOrderField["CustomExtractionMatch"] = "customExtractionMatch";
    CrawlUrlAggregateOrderField["DisallowedPage"] = "disallowedPage";
    CrawlUrlAggregateOrderField["DuplicateBody"] = "duplicateBody";
    CrawlUrlAggregateOrderField["DuplicateBodyNonIndexable"] = "duplicateBodyNonIndexable";
    CrawlUrlAggregateOrderField["DuplicateDescription"] = "duplicateDescription";
    CrawlUrlAggregateOrderField["DuplicateDescriptionNonIndexable"] = "duplicateDescriptionNonIndexable";
    CrawlUrlAggregateOrderField["DuplicatePage"] = "duplicatePage";
    CrawlUrlAggregateOrderField["DuplicatePageNonIndexable"] = "duplicatePageNonIndexable";
    CrawlUrlAggregateOrderField["DuplicateTitle"] = "duplicateTitle";
    CrawlUrlAggregateOrderField["DuplicateTitleNonIndexable"] = "duplicateTitleNonIndexable";
    CrawlUrlAggregateOrderField["DuplicateUrl"] = "duplicateUrl";
    CrawlUrlAggregateOrderField["DuplicateUrlNonIndexable"] = "duplicateUrlNonIndexable";
    CrawlUrlAggregateOrderField["FailedReason"] = "failedReason";
    CrawlUrlAggregateOrderField["FileExtension"] = "fileExtension";
    CrawlUrlAggregateOrderField["HeaderContentType"] = "headerContentType";
    CrawlUrlAggregateOrderField["HttpStatusCode"] = "httpStatusCode";
    CrawlUrlAggregateOrderField["Https"] = "https";
    CrawlUrlAggregateOrderField["Indexable"] = "indexable";
    CrawlUrlAggregateOrderField["IsImage"] = "isImage";
    CrawlUrlAggregateOrderField["IsSelfCanonical"] = "isSelfCanonical";
    CrawlUrlAggregateOrderField["IsWebLinked"] = "isWebLinked";
    CrawlUrlAggregateOrderField["Js"] = "js";
    CrawlUrlAggregateOrderField["Level"] = "level";
    CrawlUrlAggregateOrderField["MaxAccessibilityAIssuesCount"] = "maxAccessibilityAIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityAaIssuesCount"] = "maxAccessibilityAaIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityAaaIssuesCount"] = "maxAccessibilityAaaIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityBpIssuesCount"] = "maxAccessibilityBpIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityCriticalIssuesCount"] = "maxAccessibilityCriticalIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityIncompleteIssuesCount"] = "maxAccessibilityIncompleteIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityIssuesCount"] = "maxAccessibilityIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityMinorIssuesCount"] = "maxAccessibilityMinorIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilityModerateIssuesCount"] = "maxAccessibilityModerateIssuesCount";
    CrawlUrlAggregateOrderField["MaxAccessibilitySeriousIssuesCount"] = "maxAccessibilitySeriousIssuesCount";
    CrawlUrlAggregateOrderField["MaxBacklinkCount"] = "maxBacklinkCount";
    CrawlUrlAggregateOrderField["MaxBacklinkDomainCount"] = "maxBacklinkDomainCount";
    CrawlUrlAggregateOrderField["MaxBodyTagsInHeadCount"] = "maxBodyTagsInHeadCount";
    CrawlUrlAggregateOrderField["MaxBreadcrumbCount"] = "maxBreadcrumbCount";
    CrawlUrlAggregateOrderField["MaxBreadcrumbSchemaCount"] = "maxBreadcrumbSchemaCount";
    CrawlUrlAggregateOrderField["MaxCanonicalLinksInCount"] = "maxCanonicalLinksInCount";
    CrawlUrlAggregateOrderField["MaxContentHtmlRatio"] = "maxContentHtmlRatio";
    CrawlUrlAggregateOrderField["MaxContentSize"] = "maxContentSize";
    CrawlUrlAggregateOrderField["MaxCumulativeLayoutShift"] = "maxCumulativeLayoutShift";
    CrawlUrlAggregateOrderField["MaxCumulativeLayoutShiftMainFrame"] = "maxCumulativeLayoutShiftMainFrame";
    CrawlUrlAggregateOrderField["MaxCumulativeLayoutShiftScore"] = "maxCumulativeLayoutShiftScore";
    CrawlUrlAggregateOrderField["MaxDeeprank"] = "maxDeeprank";
    CrawlUrlAggregateOrderField["MaxDescriptionLength"] = "maxDescriptionLength";
    CrawlUrlAggregateOrderField["MaxDescriptionLengthPx"] = "maxDescriptionLengthPx";
    CrawlUrlAggregateOrderField["MaxDuplicateBodyCount"] = "maxDuplicateBodyCount";
    CrawlUrlAggregateOrderField["MaxDuplicateDescriptionCount"] = "maxDuplicateDescriptionCount";
    CrawlUrlAggregateOrderField["MaxDuplicatePageCount"] = "maxDuplicatePageCount";
    CrawlUrlAggregateOrderField["MaxDuplicateTitleCount"] = "maxDuplicateTitleCount";
    CrawlUrlAggregateOrderField["MaxDuplicateUrlCount"] = "maxDuplicateUrlCount";
    CrawlUrlAggregateOrderField["MaxEventSchemaCount"] = "maxEventSchemaCount";
    CrawlUrlAggregateOrderField["MaxExternalLinksCount"] = "maxExternalLinksCount";
    CrawlUrlAggregateOrderField["MaxFailedAuditsCount"] = "maxFailedAuditsCount";
    CrawlUrlAggregateOrderField["MaxFaqpageSchemaCount"] = "maxFaqpageSchemaCount";
    CrawlUrlAggregateOrderField["MaxFetchTime"] = "maxFetchTime";
    CrawlUrlAggregateOrderField["MaxFirstContentfulPaint"] = "maxFirstContentfulPaint";
    CrawlUrlAggregateOrderField["MaxFirstContentfulPaintScore"] = "maxFirstContentfulPaintScore";
    CrawlUrlAggregateOrderField["MaxFirstMeaningfulPaint"] = "maxFirstMeaningfulPaint";
    CrawlUrlAggregateOrderField["MaxFolderCount"] = "maxFolderCount";
    CrawlUrlAggregateOrderField["MaxFollowedExternalLinksOutCount"] = "maxFollowedExternalLinksOutCount";
    CrawlUrlAggregateOrderField["MaxFollowedLinksInCount"] = "maxFollowedLinksInCount";
    CrawlUrlAggregateOrderField["MaxGaAvgPageLoadTime"] = "maxGaAvgPageLoadTime";
    CrawlUrlAggregateOrderField["MaxGaAvgTimeOnPage"] = "maxGaAvgTimeOnPage";
    CrawlUrlAggregateOrderField["MaxGaPageviewsPerVisits"] = "maxGaPageviewsPerVisits";
    CrawlUrlAggregateOrderField["MaxGaVisitBounceRate"] = "maxGaVisitBounceRate";
    CrawlUrlAggregateOrderField["MaxGaVisits"] = "maxGaVisits";
    CrawlUrlAggregateOrderField["MaxH1Count"] = "maxH1Count";
    CrawlUrlAggregateOrderField["MaxH1Length"] = "maxH1Length";
    CrawlUrlAggregateOrderField["MaxHeadTagsInBodyCount"] = "maxHeadTagsInBodyCount";
    CrawlUrlAggregateOrderField["MaxHowtoSchemaCount"] = "maxHowtoSchemaCount";
    CrawlUrlAggregateOrderField["MaxHreflangLinksInCount"] = "maxHreflangLinksInCount";
    CrawlUrlAggregateOrderField["MaxHtmlSize"] = "maxHtmlSize";
    CrawlUrlAggregateOrderField["MaxInfoAuditsCount"] = "maxInfoAuditsCount";
    CrawlUrlAggregateOrderField["MaxInteractive"] = "maxInteractive";
    CrawlUrlAggregateOrderField["MaxInternalLinksCount"] = "maxInternalLinksCount";
    CrawlUrlAggregateOrderField["MaxLargestContentfulPaint"] = "maxLargestContentfulPaint";
    CrawlUrlAggregateOrderField["MaxLargestContentfulPaintScore"] = "maxLargestContentfulPaintScore";
    CrawlUrlAggregateOrderField["MaxLcpLoadEnd"] = "maxLcpLoadEnd";
    CrawlUrlAggregateOrderField["MaxLcpLoadStart"] = "maxLcpLoadStart";
    CrawlUrlAggregateOrderField["MaxLinksInCount"] = "maxLinksInCount";
    CrawlUrlAggregateOrderField["MaxLinksOutCount"] = "maxLinksOutCount";
    CrawlUrlAggregateOrderField["MaxLogRequestsDesktop"] = "maxLogRequestsDesktop";
    CrawlUrlAggregateOrderField["MaxLogRequestsMobile"] = "maxLogRequestsMobile";
    CrawlUrlAggregateOrderField["MaxLogRequestsTotal"] = "maxLogRequestsTotal";
    CrawlUrlAggregateOrderField["MaxMaxPotentialFid"] = "maxMaxPotentialFid";
    CrawlUrlAggregateOrderField["MaxMobileDesktopContentDifference"] = "maxMobileDesktopContentDifference";
    CrawlUrlAggregateOrderField["MaxMobileDesktopLinksInDifference"] = "maxMobileDesktopLinksInDifference";
    CrawlUrlAggregateOrderField["MaxMobileDesktopLinksOutDifference"] = "maxMobileDesktopLinksOutDifference";
    CrawlUrlAggregateOrderField["MaxMobileLinksInCount"] = "maxMobileLinksInCount";
    CrawlUrlAggregateOrderField["MaxNewsarticleSchemaCount"] = "maxNewsarticleSchemaCount";
    CrawlUrlAggregateOrderField["MaxNonReciprocateHreflangOutCount"] = "maxNonReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["MaxPageTitleLength"] = "maxPageTitleLength";
    CrawlUrlAggregateOrderField["MaxPageTitleLengthPx"] = "maxPageTitleLengthPx";
    CrawlUrlAggregateOrderField["MaxPaginatedLinksInCount"] = "maxPaginatedLinksInCount";
    CrawlUrlAggregateOrderField["MaxParamCount"] = "maxParamCount";
    CrawlUrlAggregateOrderField["MaxPassedAuditsCount"] = "maxPassedAuditsCount";
    CrawlUrlAggregateOrderField["MaxPerformanceCls"] = "maxPerformanceCls";
    CrawlUrlAggregateOrderField["MaxPerformanceDcl"] = "maxPerformanceDcl";
    CrawlUrlAggregateOrderField["MaxPerformanceFcp"] = "maxPerformanceFcp";
    CrawlUrlAggregateOrderField["MaxPerformanceLcp"] = "maxPerformanceLcp";
    CrawlUrlAggregateOrderField["MaxPerformanceTtfb"] = "maxPerformanceTtfb";
    CrawlUrlAggregateOrderField["MaxPerformanceTti"] = "maxPerformanceTti";
    CrawlUrlAggregateOrderField["MaxProductOfSavingsKibAndPageviews"] = "maxProductOfSavingsKibAndPageviews";
    CrawlUrlAggregateOrderField["MaxProductOfSavingsSecsAndPageviews"] = "maxProductOfSavingsSecsAndPageviews";
    CrawlUrlAggregateOrderField["MaxProductPriceSchemaValue"] = "maxProductPriceSchemaValue";
    CrawlUrlAggregateOrderField["MaxProductRatingSchemaValue"] = "maxProductRatingSchemaValue";
    CrawlUrlAggregateOrderField["MaxProductSchemaCount"] = "maxProductSchemaCount";
    CrawlUrlAggregateOrderField["MaxQapageSchemaCount"] = "maxQapageSchemaCount";
    CrawlUrlAggregateOrderField["MaxRecipeSchemaCount"] = "maxRecipeSchemaCount";
    CrawlUrlAggregateOrderField["MaxReciprocateHreflangOutCount"] = "maxReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["MaxRedirectCount"] = "maxRedirectCount";
    CrawlUrlAggregateOrderField["MaxRedirectsInCount"] = "maxRedirectsInCount";
    CrawlUrlAggregateOrderField["MaxRelLinksInCount"] = "maxRelLinksInCount";
    CrawlUrlAggregateOrderField["MaxRenderedLinkCountDifference"] = "maxRenderedLinkCountDifference";
    CrawlUrlAggregateOrderField["MaxRenderedWordCountDifference"] = "maxRenderedWordCountDifference";
    CrawlUrlAggregateOrderField["MaxResourceLinksInCount"] = "maxResourceLinksInCount";
    CrawlUrlAggregateOrderField["MaxReviewSchemaCount"] = "maxReviewSchemaCount";
    CrawlUrlAggregateOrderField["MaxSavingsKib"] = "maxSavingsKib";
    CrawlUrlAggregateOrderField["MaxSavingsScore"] = "maxSavingsScore";
    CrawlUrlAggregateOrderField["MaxSavingsSecs"] = "maxSavingsSecs";
    CrawlUrlAggregateOrderField["MaxSearchConsoleDesktopClicks"] = "maxSearchConsoleDesktopClicks";
    CrawlUrlAggregateOrderField["MaxSearchConsoleDesktopCtr"] = "maxSearchConsoleDesktopCtr";
    CrawlUrlAggregateOrderField["MaxSearchConsoleDesktopImpressions"] = "maxSearchConsoleDesktopImpressions";
    CrawlUrlAggregateOrderField["MaxSearchConsoleDesktopPosition"] = "maxSearchConsoleDesktopPosition";
    CrawlUrlAggregateOrderField["MaxSearchConsoleMobileClicks"] = "maxSearchConsoleMobileClicks";
    CrawlUrlAggregateOrderField["MaxSearchConsoleMobileCtr"] = "maxSearchConsoleMobileCtr";
    CrawlUrlAggregateOrderField["MaxSearchConsoleMobileImpressions"] = "maxSearchConsoleMobileImpressions";
    CrawlUrlAggregateOrderField["MaxSearchConsoleMobilePosition"] = "maxSearchConsoleMobilePosition";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTabletClicks"] = "maxSearchConsoleTabletClicks";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTabletCtr"] = "maxSearchConsoleTabletCtr";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTabletImpressions"] = "maxSearchConsoleTabletImpressions";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTabletPosition"] = "maxSearchConsoleTabletPosition";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTotalClicks"] = "maxSearchConsoleTotalClicks";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTotalCtr"] = "maxSearchConsoleTotalCtr";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTotalImpressions"] = "maxSearchConsoleTotalImpressions";
    CrawlUrlAggregateOrderField["MaxSearchConsoleTotalPosition"] = "maxSearchConsoleTotalPosition";
    CrawlUrlAggregateOrderField["MaxSitemapsInCount"] = "maxSitemapsInCount";
    CrawlUrlAggregateOrderField["MaxSitespeedCls"] = "maxSitespeedCls";
    CrawlUrlAggregateOrderField["MaxSitespeedClsMainFrame"] = "maxSitespeedClsMainFrame";
    CrawlUrlAggregateOrderField["MaxSitespeedClsScore"] = "maxSitespeedClsScore";
    CrawlUrlAggregateOrderField["MaxSitespeedFcp"] = "maxSitespeedFcp";
    CrawlUrlAggregateOrderField["MaxSitespeedFcpScore"] = "maxSitespeedFcpScore";
    CrawlUrlAggregateOrderField["MaxSitespeedFmp"] = "maxSitespeedFmp";
    CrawlUrlAggregateOrderField["MaxSitespeedLcp"] = "maxSitespeedLcp";
    CrawlUrlAggregateOrderField["MaxSitespeedLcpScore"] = "maxSitespeedLcpScore";
    CrawlUrlAggregateOrderField["MaxSitespeedMaxPotentialFid"] = "maxSitespeedMaxPotentialFid";
    CrawlUrlAggregateOrderField["MaxSitespeedScore"] = "maxSitespeedScore";
    CrawlUrlAggregateOrderField["MaxSitespeedSpeedIndex"] = "maxSitespeedSpeedIndex";
    CrawlUrlAggregateOrderField["MaxSitespeedSpeedIndexScore"] = "maxSitespeedSpeedIndexScore";
    CrawlUrlAggregateOrderField["MaxSitespeedTbt"] = "maxSitespeedTbt";
    CrawlUrlAggregateOrderField["MaxSitespeedTbtScore"] = "maxSitespeedTbtScore";
    CrawlUrlAggregateOrderField["MaxSitespeedTtfb"] = "maxSitespeedTtfb";
    CrawlUrlAggregateOrderField["MaxSitespeedTti"] = "maxSitespeedTti";
    CrawlUrlAggregateOrderField["MaxSpeedIndex"] = "maxSpeedIndex";
    CrawlUrlAggregateOrderField["MaxSpeedIndexScore"] = "maxSpeedIndexScore";
    CrawlUrlAggregateOrderField["MaxTimeToFirstByte"] = "maxTimeToFirstByte";
    CrawlUrlAggregateOrderField["MaxTotalBlockingTime"] = "maxTotalBlockingTime";
    CrawlUrlAggregateOrderField["MaxTotalBlockingTimeScore"] = "maxTotalBlockingTimeScore";
    CrawlUrlAggregateOrderField["MaxUniqueLinksInCount"] = "maxUniqueLinksInCount";
    CrawlUrlAggregateOrderField["MaxVideoobjectSchemaCount"] = "maxVideoobjectSchemaCount";
    CrawlUrlAggregateOrderField["MaxWarningAuditsCount"] = "maxWarningAuditsCount";
    CrawlUrlAggregateOrderField["MaxWordCount"] = "maxWordCount";
    CrawlUrlAggregateOrderField["MetaContentType"] = "metaContentType";
    CrawlUrlAggregateOrderField["MinAccessibilityAIssuesCount"] = "minAccessibilityAIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityAaIssuesCount"] = "minAccessibilityAaIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityAaaIssuesCount"] = "minAccessibilityAaaIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityBpIssuesCount"] = "minAccessibilityBpIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityCriticalIssuesCount"] = "minAccessibilityCriticalIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityIncompleteIssuesCount"] = "minAccessibilityIncompleteIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityIssuesCount"] = "minAccessibilityIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityMinorIssuesCount"] = "minAccessibilityMinorIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilityModerateIssuesCount"] = "minAccessibilityModerateIssuesCount";
    CrawlUrlAggregateOrderField["MinAccessibilitySeriousIssuesCount"] = "minAccessibilitySeriousIssuesCount";
    CrawlUrlAggregateOrderField["MinBacklinkCount"] = "minBacklinkCount";
    CrawlUrlAggregateOrderField["MinBacklinkDomainCount"] = "minBacklinkDomainCount";
    CrawlUrlAggregateOrderField["MinBodyTagsInHeadCount"] = "minBodyTagsInHeadCount";
    CrawlUrlAggregateOrderField["MinBreadcrumbCount"] = "minBreadcrumbCount";
    CrawlUrlAggregateOrderField["MinBreadcrumbSchemaCount"] = "minBreadcrumbSchemaCount";
    CrawlUrlAggregateOrderField["MinCanonicalLinksInCount"] = "minCanonicalLinksInCount";
    CrawlUrlAggregateOrderField["MinContentHtmlRatio"] = "minContentHtmlRatio";
    CrawlUrlAggregateOrderField["MinContentSize"] = "minContentSize";
    CrawlUrlAggregateOrderField["MinCumulativeLayoutShift"] = "minCumulativeLayoutShift";
    CrawlUrlAggregateOrderField["MinCumulativeLayoutShiftMainFrame"] = "minCumulativeLayoutShiftMainFrame";
    CrawlUrlAggregateOrderField["MinCumulativeLayoutShiftScore"] = "minCumulativeLayoutShiftScore";
    CrawlUrlAggregateOrderField["MinDeeprank"] = "minDeeprank";
    CrawlUrlAggregateOrderField["MinDescriptionLength"] = "minDescriptionLength";
    CrawlUrlAggregateOrderField["MinDescriptionLengthPx"] = "minDescriptionLengthPx";
    CrawlUrlAggregateOrderField["MinDuplicateBodyCount"] = "minDuplicateBodyCount";
    CrawlUrlAggregateOrderField["MinDuplicateDescriptionCount"] = "minDuplicateDescriptionCount";
    CrawlUrlAggregateOrderField["MinDuplicatePageCount"] = "minDuplicatePageCount";
    CrawlUrlAggregateOrderField["MinDuplicateTitleCount"] = "minDuplicateTitleCount";
    CrawlUrlAggregateOrderField["MinDuplicateUrlCount"] = "minDuplicateUrlCount";
    CrawlUrlAggregateOrderField["MinEventSchemaCount"] = "minEventSchemaCount";
    CrawlUrlAggregateOrderField["MinExternalLinksCount"] = "minExternalLinksCount";
    CrawlUrlAggregateOrderField["MinFailedAuditsCount"] = "minFailedAuditsCount";
    CrawlUrlAggregateOrderField["MinFaqpageSchemaCount"] = "minFaqpageSchemaCount";
    CrawlUrlAggregateOrderField["MinFetchTime"] = "minFetchTime";
    CrawlUrlAggregateOrderField["MinFirstContentfulPaint"] = "minFirstContentfulPaint";
    CrawlUrlAggregateOrderField["MinFirstContentfulPaintScore"] = "minFirstContentfulPaintScore";
    CrawlUrlAggregateOrderField["MinFirstMeaningfulPaint"] = "minFirstMeaningfulPaint";
    CrawlUrlAggregateOrderField["MinFolderCount"] = "minFolderCount";
    CrawlUrlAggregateOrderField["MinFollowedExternalLinksOutCount"] = "minFollowedExternalLinksOutCount";
    CrawlUrlAggregateOrderField["MinFollowedLinksInCount"] = "minFollowedLinksInCount";
    CrawlUrlAggregateOrderField["MinGaAvgPageLoadTime"] = "minGaAvgPageLoadTime";
    CrawlUrlAggregateOrderField["MinGaAvgTimeOnPage"] = "minGaAvgTimeOnPage";
    CrawlUrlAggregateOrderField["MinGaPageviewsPerVisits"] = "minGaPageviewsPerVisits";
    CrawlUrlAggregateOrderField["MinGaVisitBounceRate"] = "minGaVisitBounceRate";
    CrawlUrlAggregateOrderField["MinGaVisits"] = "minGaVisits";
    CrawlUrlAggregateOrderField["MinH1Count"] = "minH1Count";
    CrawlUrlAggregateOrderField["MinH1Length"] = "minH1Length";
    CrawlUrlAggregateOrderField["MinHeadTagsInBodyCount"] = "minHeadTagsInBodyCount";
    CrawlUrlAggregateOrderField["MinHowtoSchemaCount"] = "minHowtoSchemaCount";
    CrawlUrlAggregateOrderField["MinHreflangLinksInCount"] = "minHreflangLinksInCount";
    CrawlUrlAggregateOrderField["MinHtmlSize"] = "minHtmlSize";
    CrawlUrlAggregateOrderField["MinInfoAuditsCount"] = "minInfoAuditsCount";
    CrawlUrlAggregateOrderField["MinInteractive"] = "minInteractive";
    CrawlUrlAggregateOrderField["MinInternalLinksCount"] = "minInternalLinksCount";
    CrawlUrlAggregateOrderField["MinLargestContentfulPaint"] = "minLargestContentfulPaint";
    CrawlUrlAggregateOrderField["MinLargestContentfulPaintScore"] = "minLargestContentfulPaintScore";
    CrawlUrlAggregateOrderField["MinLcpLoadEnd"] = "minLcpLoadEnd";
    CrawlUrlAggregateOrderField["MinLcpLoadStart"] = "minLcpLoadStart";
    CrawlUrlAggregateOrderField["MinLinksInCount"] = "minLinksInCount";
    CrawlUrlAggregateOrderField["MinLinksOutCount"] = "minLinksOutCount";
    CrawlUrlAggregateOrderField["MinLogRequestsDesktop"] = "minLogRequestsDesktop";
    CrawlUrlAggregateOrderField["MinLogRequestsMobile"] = "minLogRequestsMobile";
    CrawlUrlAggregateOrderField["MinLogRequestsTotal"] = "minLogRequestsTotal";
    CrawlUrlAggregateOrderField["MinMaxPotentialFid"] = "minMaxPotentialFid";
    CrawlUrlAggregateOrderField["MinMobileDesktopContentDifference"] = "minMobileDesktopContentDifference";
    CrawlUrlAggregateOrderField["MinMobileDesktopLinksInDifference"] = "minMobileDesktopLinksInDifference";
    CrawlUrlAggregateOrderField["MinMobileDesktopLinksOutDifference"] = "minMobileDesktopLinksOutDifference";
    CrawlUrlAggregateOrderField["MinMobileLinksInCount"] = "minMobileLinksInCount";
    CrawlUrlAggregateOrderField["MinNewsarticleSchemaCount"] = "minNewsarticleSchemaCount";
    CrawlUrlAggregateOrderField["MinNonReciprocateHreflangOutCount"] = "minNonReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["MinPageTitleLength"] = "minPageTitleLength";
    CrawlUrlAggregateOrderField["MinPageTitleLengthPx"] = "minPageTitleLengthPx";
    CrawlUrlAggregateOrderField["MinPaginatedLinksInCount"] = "minPaginatedLinksInCount";
    CrawlUrlAggregateOrderField["MinParamCount"] = "minParamCount";
    CrawlUrlAggregateOrderField["MinPassedAuditsCount"] = "minPassedAuditsCount";
    CrawlUrlAggregateOrderField["MinPerformanceCls"] = "minPerformanceCls";
    CrawlUrlAggregateOrderField["MinPerformanceDcl"] = "minPerformanceDcl";
    CrawlUrlAggregateOrderField["MinPerformanceFcp"] = "minPerformanceFcp";
    CrawlUrlAggregateOrderField["MinPerformanceLcp"] = "minPerformanceLcp";
    CrawlUrlAggregateOrderField["MinPerformanceTtfb"] = "minPerformanceTtfb";
    CrawlUrlAggregateOrderField["MinPerformanceTti"] = "minPerformanceTti";
    CrawlUrlAggregateOrderField["MinProductOfSavingsKibAndPageviews"] = "minProductOfSavingsKibAndPageviews";
    CrawlUrlAggregateOrderField["MinProductOfSavingsSecsAndPageviews"] = "minProductOfSavingsSecsAndPageviews";
    CrawlUrlAggregateOrderField["MinProductPriceSchemaValue"] = "minProductPriceSchemaValue";
    CrawlUrlAggregateOrderField["MinProductRatingSchemaValue"] = "minProductRatingSchemaValue";
    CrawlUrlAggregateOrderField["MinProductSchemaCount"] = "minProductSchemaCount";
    CrawlUrlAggregateOrderField["MinQapageSchemaCount"] = "minQapageSchemaCount";
    CrawlUrlAggregateOrderField["MinRecipeSchemaCount"] = "minRecipeSchemaCount";
    CrawlUrlAggregateOrderField["MinReciprocateHreflangOutCount"] = "minReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["MinRedirectCount"] = "minRedirectCount";
    CrawlUrlAggregateOrderField["MinRedirectsInCount"] = "minRedirectsInCount";
    CrawlUrlAggregateOrderField["MinRelLinksInCount"] = "minRelLinksInCount";
    CrawlUrlAggregateOrderField["MinRenderedLinkCountDifference"] = "minRenderedLinkCountDifference";
    CrawlUrlAggregateOrderField["MinRenderedWordCountDifference"] = "minRenderedWordCountDifference";
    CrawlUrlAggregateOrderField["MinResourceLinksInCount"] = "minResourceLinksInCount";
    CrawlUrlAggregateOrderField["MinReviewSchemaCount"] = "minReviewSchemaCount";
    CrawlUrlAggregateOrderField["MinSavingsKib"] = "minSavingsKib";
    CrawlUrlAggregateOrderField["MinSavingsScore"] = "minSavingsScore";
    CrawlUrlAggregateOrderField["MinSavingsSecs"] = "minSavingsSecs";
    CrawlUrlAggregateOrderField["MinSearchConsoleDesktopClicks"] = "minSearchConsoleDesktopClicks";
    CrawlUrlAggregateOrderField["MinSearchConsoleDesktopCtr"] = "minSearchConsoleDesktopCtr";
    CrawlUrlAggregateOrderField["MinSearchConsoleDesktopImpressions"] = "minSearchConsoleDesktopImpressions";
    CrawlUrlAggregateOrderField["MinSearchConsoleDesktopPosition"] = "minSearchConsoleDesktopPosition";
    CrawlUrlAggregateOrderField["MinSearchConsoleMobileClicks"] = "minSearchConsoleMobileClicks";
    CrawlUrlAggregateOrderField["MinSearchConsoleMobileCtr"] = "minSearchConsoleMobileCtr";
    CrawlUrlAggregateOrderField["MinSearchConsoleMobileImpressions"] = "minSearchConsoleMobileImpressions";
    CrawlUrlAggregateOrderField["MinSearchConsoleMobilePosition"] = "minSearchConsoleMobilePosition";
    CrawlUrlAggregateOrderField["MinSearchConsoleTabletClicks"] = "minSearchConsoleTabletClicks";
    CrawlUrlAggregateOrderField["MinSearchConsoleTabletCtr"] = "minSearchConsoleTabletCtr";
    CrawlUrlAggregateOrderField["MinSearchConsoleTabletImpressions"] = "minSearchConsoleTabletImpressions";
    CrawlUrlAggregateOrderField["MinSearchConsoleTabletPosition"] = "minSearchConsoleTabletPosition";
    CrawlUrlAggregateOrderField["MinSearchConsoleTotalClicks"] = "minSearchConsoleTotalClicks";
    CrawlUrlAggregateOrderField["MinSearchConsoleTotalCtr"] = "minSearchConsoleTotalCtr";
    CrawlUrlAggregateOrderField["MinSearchConsoleTotalImpressions"] = "minSearchConsoleTotalImpressions";
    CrawlUrlAggregateOrderField["MinSearchConsoleTotalPosition"] = "minSearchConsoleTotalPosition";
    CrawlUrlAggregateOrderField["MinSitemapsInCount"] = "minSitemapsInCount";
    CrawlUrlAggregateOrderField["MinSitespeedCls"] = "minSitespeedCls";
    CrawlUrlAggregateOrderField["MinSitespeedClsMainFrame"] = "minSitespeedClsMainFrame";
    CrawlUrlAggregateOrderField["MinSitespeedClsScore"] = "minSitespeedClsScore";
    CrawlUrlAggregateOrderField["MinSitespeedFcp"] = "minSitespeedFcp";
    CrawlUrlAggregateOrderField["MinSitespeedFcpScore"] = "minSitespeedFcpScore";
    CrawlUrlAggregateOrderField["MinSitespeedFmp"] = "minSitespeedFmp";
    CrawlUrlAggregateOrderField["MinSitespeedLcp"] = "minSitespeedLcp";
    CrawlUrlAggregateOrderField["MinSitespeedLcpScore"] = "minSitespeedLcpScore";
    CrawlUrlAggregateOrderField["MinSitespeedMaxPotentialFid"] = "minSitespeedMaxPotentialFid";
    CrawlUrlAggregateOrderField["MinSitespeedScore"] = "minSitespeedScore";
    CrawlUrlAggregateOrderField["MinSitespeedSpeedIndex"] = "minSitespeedSpeedIndex";
    CrawlUrlAggregateOrderField["MinSitespeedSpeedIndexScore"] = "minSitespeedSpeedIndexScore";
    CrawlUrlAggregateOrderField["MinSitespeedTbt"] = "minSitespeedTbt";
    CrawlUrlAggregateOrderField["MinSitespeedTbtScore"] = "minSitespeedTbtScore";
    CrawlUrlAggregateOrderField["MinSitespeedTtfb"] = "minSitespeedTtfb";
    CrawlUrlAggregateOrderField["MinSitespeedTti"] = "minSitespeedTti";
    CrawlUrlAggregateOrderField["MinSpeedIndex"] = "minSpeedIndex";
    CrawlUrlAggregateOrderField["MinSpeedIndexScore"] = "minSpeedIndexScore";
    CrawlUrlAggregateOrderField["MinTimeToFirstByte"] = "minTimeToFirstByte";
    CrawlUrlAggregateOrderField["MinTotalBlockingTime"] = "minTotalBlockingTime";
    CrawlUrlAggregateOrderField["MinTotalBlockingTimeScore"] = "minTotalBlockingTimeScore";
    CrawlUrlAggregateOrderField["MinUniqueLinksInCount"] = "minUniqueLinksInCount";
    CrawlUrlAggregateOrderField["MinVideoobjectSchemaCount"] = "minVideoobjectSchemaCount";
    CrawlUrlAggregateOrderField["MinWarningAuditsCount"] = "minWarningAuditsCount";
    CrawlUrlAggregateOrderField["MinWordCount"] = "minWordCount";
    CrawlUrlAggregateOrderField["NofollowedPage"] = "nofollowedPage";
    CrawlUrlAggregateOrderField["Noindex"] = "noindex";
    CrawlUrlAggregateOrderField["OgLocale"] = "ogLocale";
    CrawlUrlAggregateOrderField["OgType"] = "ogType";
    CrawlUrlAggregateOrderField["PageGroup"] = "pageGroup";
    CrawlUrlAggregateOrderField["Path0"] = "path0";
    CrawlUrlAggregateOrderField["Path1"] = "path1";
    CrawlUrlAggregateOrderField["Path2"] = "path2";
    CrawlUrlAggregateOrderField["Path3"] = "path3";
    CrawlUrlAggregateOrderField["Path4"] = "path4";
    CrawlUrlAggregateOrderField["Path5"] = "path5";
    CrawlUrlAggregateOrderField["Path6"] = "path6";
    CrawlUrlAggregateOrderField["Path7"] = "path7";
    CrawlUrlAggregateOrderField["Path8"] = "path8";
    CrawlUrlAggregateOrderField["Path9"] = "path9";
    CrawlUrlAggregateOrderField["SumAccessibilityAIssuesCount"] = "sumAccessibilityAIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityAaIssuesCount"] = "sumAccessibilityAaIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityAaaIssuesCount"] = "sumAccessibilityAaaIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityBpIssuesCount"] = "sumAccessibilityBpIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityCriticalIssuesCount"] = "sumAccessibilityCriticalIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityIncompleteIssuesCount"] = "sumAccessibilityIncompleteIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityIssuesCount"] = "sumAccessibilityIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityMinorIssuesCount"] = "sumAccessibilityMinorIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilityModerateIssuesCount"] = "sumAccessibilityModerateIssuesCount";
    CrawlUrlAggregateOrderField["SumAccessibilitySeriousIssuesCount"] = "sumAccessibilitySeriousIssuesCount";
    CrawlUrlAggregateOrderField["SumBacklinkCount"] = "sumBacklinkCount";
    CrawlUrlAggregateOrderField["SumBacklinkDomainCount"] = "sumBacklinkDomainCount";
    CrawlUrlAggregateOrderField["SumBodyTagsInHeadCount"] = "sumBodyTagsInHeadCount";
    CrawlUrlAggregateOrderField["SumBreadcrumbCount"] = "sumBreadcrumbCount";
    CrawlUrlAggregateOrderField["SumBreadcrumbSchemaCount"] = "sumBreadcrumbSchemaCount";
    CrawlUrlAggregateOrderField["SumCanonicalLinksInCount"] = "sumCanonicalLinksInCount";
    CrawlUrlAggregateOrderField["SumContentHtmlRatio"] = "sumContentHtmlRatio";
    CrawlUrlAggregateOrderField["SumContentSize"] = "sumContentSize";
    CrawlUrlAggregateOrderField["SumCumulativeLayoutShift"] = "sumCumulativeLayoutShift";
    CrawlUrlAggregateOrderField["SumCumulativeLayoutShiftMainFrame"] = "sumCumulativeLayoutShiftMainFrame";
    CrawlUrlAggregateOrderField["SumCumulativeLayoutShiftScore"] = "sumCumulativeLayoutShiftScore";
    CrawlUrlAggregateOrderField["SumDeeprank"] = "sumDeeprank";
    CrawlUrlAggregateOrderField["SumDescriptionLength"] = "sumDescriptionLength";
    CrawlUrlAggregateOrderField["SumDescriptionLengthPx"] = "sumDescriptionLengthPx";
    CrawlUrlAggregateOrderField["SumDuplicateBodyCount"] = "sumDuplicateBodyCount";
    CrawlUrlAggregateOrderField["SumDuplicateDescriptionCount"] = "sumDuplicateDescriptionCount";
    CrawlUrlAggregateOrderField["SumDuplicatePageCount"] = "sumDuplicatePageCount";
    CrawlUrlAggregateOrderField["SumDuplicateTitleCount"] = "sumDuplicateTitleCount";
    CrawlUrlAggregateOrderField["SumDuplicateUrlCount"] = "sumDuplicateUrlCount";
    CrawlUrlAggregateOrderField["SumEventSchemaCount"] = "sumEventSchemaCount";
    CrawlUrlAggregateOrderField["SumExternalLinksCount"] = "sumExternalLinksCount";
    CrawlUrlAggregateOrderField["SumFailedAuditsCount"] = "sumFailedAuditsCount";
    CrawlUrlAggregateOrderField["SumFaqpageSchemaCount"] = "sumFaqpageSchemaCount";
    CrawlUrlAggregateOrderField["SumFetchTime"] = "sumFetchTime";
    CrawlUrlAggregateOrderField["SumFirstContentfulPaint"] = "sumFirstContentfulPaint";
    CrawlUrlAggregateOrderField["SumFirstContentfulPaintScore"] = "sumFirstContentfulPaintScore";
    CrawlUrlAggregateOrderField["SumFirstMeaningfulPaint"] = "sumFirstMeaningfulPaint";
    CrawlUrlAggregateOrderField["SumFolderCount"] = "sumFolderCount";
    CrawlUrlAggregateOrderField["SumFollowedExternalLinksOutCount"] = "sumFollowedExternalLinksOutCount";
    CrawlUrlAggregateOrderField["SumFollowedLinksInCount"] = "sumFollowedLinksInCount";
    CrawlUrlAggregateOrderField["SumGaAvgPageLoadTime"] = "sumGaAvgPageLoadTime";
    CrawlUrlAggregateOrderField["SumGaAvgTimeOnPage"] = "sumGaAvgTimeOnPage";
    CrawlUrlAggregateOrderField["SumGaPageviewsPerVisits"] = "sumGaPageviewsPerVisits";
    CrawlUrlAggregateOrderField["SumGaVisitBounceRate"] = "sumGaVisitBounceRate";
    CrawlUrlAggregateOrderField["SumGaVisits"] = "sumGaVisits";
    CrawlUrlAggregateOrderField["SumH1Count"] = "sumH1Count";
    CrawlUrlAggregateOrderField["SumH1Length"] = "sumH1Length";
    CrawlUrlAggregateOrderField["SumHeadTagsInBodyCount"] = "sumHeadTagsInBodyCount";
    CrawlUrlAggregateOrderField["SumHowtoSchemaCount"] = "sumHowtoSchemaCount";
    CrawlUrlAggregateOrderField["SumHreflangLinksInCount"] = "sumHreflangLinksInCount";
    CrawlUrlAggregateOrderField["SumHtmlSize"] = "sumHtmlSize";
    CrawlUrlAggregateOrderField["SumInfoAuditsCount"] = "sumInfoAuditsCount";
    CrawlUrlAggregateOrderField["SumInteractive"] = "sumInteractive";
    CrawlUrlAggregateOrderField["SumInternalLinksCount"] = "sumInternalLinksCount";
    CrawlUrlAggregateOrderField["SumLargestContentfulPaint"] = "sumLargestContentfulPaint";
    CrawlUrlAggregateOrderField["SumLargestContentfulPaintScore"] = "sumLargestContentfulPaintScore";
    CrawlUrlAggregateOrderField["SumLcpLoadEnd"] = "sumLcpLoadEnd";
    CrawlUrlAggregateOrderField["SumLcpLoadStart"] = "sumLcpLoadStart";
    CrawlUrlAggregateOrderField["SumLinksInCount"] = "sumLinksInCount";
    CrawlUrlAggregateOrderField["SumLinksOutCount"] = "sumLinksOutCount";
    CrawlUrlAggregateOrderField["SumLogRequestsDesktop"] = "sumLogRequestsDesktop";
    CrawlUrlAggregateOrderField["SumLogRequestsMobile"] = "sumLogRequestsMobile";
    CrawlUrlAggregateOrderField["SumLogRequestsTotal"] = "sumLogRequestsTotal";
    CrawlUrlAggregateOrderField["SumMaxPotentialFid"] = "sumMaxPotentialFid";
    CrawlUrlAggregateOrderField["SumMobileDesktopContentDifference"] = "sumMobileDesktopContentDifference";
    CrawlUrlAggregateOrderField["SumMobileDesktopLinksInDifference"] = "sumMobileDesktopLinksInDifference";
    CrawlUrlAggregateOrderField["SumMobileDesktopLinksOutDifference"] = "sumMobileDesktopLinksOutDifference";
    CrawlUrlAggregateOrderField["SumMobileLinksInCount"] = "sumMobileLinksInCount";
    CrawlUrlAggregateOrderField["SumNewsarticleSchemaCount"] = "sumNewsarticleSchemaCount";
    CrawlUrlAggregateOrderField["SumNonReciprocateHreflangOutCount"] = "sumNonReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["SumPageTitleLength"] = "sumPageTitleLength";
    CrawlUrlAggregateOrderField["SumPageTitleLengthPx"] = "sumPageTitleLengthPx";
    CrawlUrlAggregateOrderField["SumPaginatedLinksInCount"] = "sumPaginatedLinksInCount";
    CrawlUrlAggregateOrderField["SumParamCount"] = "sumParamCount";
    CrawlUrlAggregateOrderField["SumPassedAuditsCount"] = "sumPassedAuditsCount";
    CrawlUrlAggregateOrderField["SumPerformanceCls"] = "sumPerformanceCls";
    CrawlUrlAggregateOrderField["SumPerformanceDcl"] = "sumPerformanceDcl";
    CrawlUrlAggregateOrderField["SumPerformanceFcp"] = "sumPerformanceFcp";
    CrawlUrlAggregateOrderField["SumPerformanceLcp"] = "sumPerformanceLcp";
    CrawlUrlAggregateOrderField["SumPerformanceTtfb"] = "sumPerformanceTtfb";
    CrawlUrlAggregateOrderField["SumPerformanceTti"] = "sumPerformanceTti";
    CrawlUrlAggregateOrderField["SumProductOfSavingsKibAndPageviews"] = "sumProductOfSavingsKibAndPageviews";
    CrawlUrlAggregateOrderField["SumProductOfSavingsSecsAndPageviews"] = "sumProductOfSavingsSecsAndPageviews";
    CrawlUrlAggregateOrderField["SumProductPriceSchemaValue"] = "sumProductPriceSchemaValue";
    CrawlUrlAggregateOrderField["SumProductRatingSchemaValue"] = "sumProductRatingSchemaValue";
    CrawlUrlAggregateOrderField["SumProductSchemaCount"] = "sumProductSchemaCount";
    CrawlUrlAggregateOrderField["SumQapageSchemaCount"] = "sumQapageSchemaCount";
    CrawlUrlAggregateOrderField["SumRecipeSchemaCount"] = "sumRecipeSchemaCount";
    CrawlUrlAggregateOrderField["SumReciprocateHreflangOutCount"] = "sumReciprocateHreflangOutCount";
    CrawlUrlAggregateOrderField["SumRedirectCount"] = "sumRedirectCount";
    CrawlUrlAggregateOrderField["SumRedirectsInCount"] = "sumRedirectsInCount";
    CrawlUrlAggregateOrderField["SumRelLinksInCount"] = "sumRelLinksInCount";
    CrawlUrlAggregateOrderField["SumRenderedLinkCountDifference"] = "sumRenderedLinkCountDifference";
    CrawlUrlAggregateOrderField["SumRenderedWordCountDifference"] = "sumRenderedWordCountDifference";
    CrawlUrlAggregateOrderField["SumResourceLinksInCount"] = "sumResourceLinksInCount";
    CrawlUrlAggregateOrderField["SumReviewSchemaCount"] = "sumReviewSchemaCount";
    CrawlUrlAggregateOrderField["SumSavingsKib"] = "sumSavingsKib";
    CrawlUrlAggregateOrderField["SumSavingsScore"] = "sumSavingsScore";
    CrawlUrlAggregateOrderField["SumSavingsSecs"] = "sumSavingsSecs";
    CrawlUrlAggregateOrderField["SumSearchConsoleDesktopClicks"] = "sumSearchConsoleDesktopClicks";
    CrawlUrlAggregateOrderField["SumSearchConsoleDesktopCtr"] = "sumSearchConsoleDesktopCtr";
    CrawlUrlAggregateOrderField["SumSearchConsoleDesktopImpressions"] = "sumSearchConsoleDesktopImpressions";
    CrawlUrlAggregateOrderField["SumSearchConsoleDesktopPosition"] = "sumSearchConsoleDesktopPosition";
    CrawlUrlAggregateOrderField["SumSearchConsoleMobileClicks"] = "sumSearchConsoleMobileClicks";
    CrawlUrlAggregateOrderField["SumSearchConsoleMobileCtr"] = "sumSearchConsoleMobileCtr";
    CrawlUrlAggregateOrderField["SumSearchConsoleMobileImpressions"] = "sumSearchConsoleMobileImpressions";
    CrawlUrlAggregateOrderField["SumSearchConsoleMobilePosition"] = "sumSearchConsoleMobilePosition";
    CrawlUrlAggregateOrderField["SumSearchConsoleTabletClicks"] = "sumSearchConsoleTabletClicks";
    CrawlUrlAggregateOrderField["SumSearchConsoleTabletCtr"] = "sumSearchConsoleTabletCtr";
    CrawlUrlAggregateOrderField["SumSearchConsoleTabletImpressions"] = "sumSearchConsoleTabletImpressions";
    CrawlUrlAggregateOrderField["SumSearchConsoleTabletPosition"] = "sumSearchConsoleTabletPosition";
    CrawlUrlAggregateOrderField["SumSearchConsoleTotalClicks"] = "sumSearchConsoleTotalClicks";
    CrawlUrlAggregateOrderField["SumSearchConsoleTotalCtr"] = "sumSearchConsoleTotalCtr";
    CrawlUrlAggregateOrderField["SumSearchConsoleTotalImpressions"] = "sumSearchConsoleTotalImpressions";
    CrawlUrlAggregateOrderField["SumSearchConsoleTotalPosition"] = "sumSearchConsoleTotalPosition";
    CrawlUrlAggregateOrderField["SumSitemapsInCount"] = "sumSitemapsInCount";
    CrawlUrlAggregateOrderField["SumSitespeedCls"] = "sumSitespeedCls";
    CrawlUrlAggregateOrderField["SumSitespeedClsMainFrame"] = "sumSitespeedClsMainFrame";
    CrawlUrlAggregateOrderField["SumSitespeedClsScore"] = "sumSitespeedClsScore";
    CrawlUrlAggregateOrderField["SumSitespeedFcp"] = "sumSitespeedFcp";
    CrawlUrlAggregateOrderField["SumSitespeedFcpScore"] = "sumSitespeedFcpScore";
    CrawlUrlAggregateOrderField["SumSitespeedFmp"] = "sumSitespeedFmp";
    CrawlUrlAggregateOrderField["SumSitespeedLcp"] = "sumSitespeedLcp";
    CrawlUrlAggregateOrderField["SumSitespeedLcpScore"] = "sumSitespeedLcpScore";
    CrawlUrlAggregateOrderField["SumSitespeedMaxPotentialFid"] = "sumSitespeedMaxPotentialFid";
    CrawlUrlAggregateOrderField["SumSitespeedScore"] = "sumSitespeedScore";
    CrawlUrlAggregateOrderField["SumSitespeedSpeedIndex"] = "sumSitespeedSpeedIndex";
    CrawlUrlAggregateOrderField["SumSitespeedSpeedIndexScore"] = "sumSitespeedSpeedIndexScore";
    CrawlUrlAggregateOrderField["SumSitespeedTbt"] = "sumSitespeedTbt";
    CrawlUrlAggregateOrderField["SumSitespeedTbtScore"] = "sumSitespeedTbtScore";
    CrawlUrlAggregateOrderField["SumSitespeedTtfb"] = "sumSitespeedTtfb";
    CrawlUrlAggregateOrderField["SumSitespeedTti"] = "sumSitespeedTti";
    CrawlUrlAggregateOrderField["SumSpeedIndex"] = "sumSpeedIndex";
    CrawlUrlAggregateOrderField["SumSpeedIndexScore"] = "sumSpeedIndexScore";
    CrawlUrlAggregateOrderField["SumTimeToFirstByte"] = "sumTimeToFirstByte";
    CrawlUrlAggregateOrderField["SumTotalBlockingTime"] = "sumTotalBlockingTime";
    CrawlUrlAggregateOrderField["SumTotalBlockingTimeScore"] = "sumTotalBlockingTimeScore";
    CrawlUrlAggregateOrderField["SumUniqueLinksInCount"] = "sumUniqueLinksInCount";
    CrawlUrlAggregateOrderField["SumVideoobjectSchemaCount"] = "sumVideoobjectSchemaCount";
    CrawlUrlAggregateOrderField["SumWarningAuditsCount"] = "sumWarningAuditsCount";
    CrawlUrlAggregateOrderField["SumWordCount"] = "sumWordCount";
    CrawlUrlAggregateOrderField["UrlContainsRepeatedPaths"] = "urlContainsRepeatedPaths";
    CrawlUrlAggregateOrderField["UrlContainsUnderscores"] = "urlContainsUnderscores";
    CrawlUrlAggregateOrderField["UrlDoubleEncoded"] = "urlDoubleEncoded";
    CrawlUrlAggregateOrderField["UrlPathContainsDoubleSlashes"] = "urlPathContainsDoubleSlashes";
    CrawlUrlAggregateOrderField["Viewport"] = "viewport";
})(CrawlUrlAggregateOrderField || (CrawlUrlAggregateOrderField = {}));
export var CrawlUrlOrderField;
(function (CrawlUrlOrderField) {
    CrawlUrlOrderField["AccessibilityAIssuesCount"] = "accessibilityAIssuesCount";
    CrawlUrlOrderField["AccessibilityAaIssuesCount"] = "accessibilityAaIssuesCount";
    CrawlUrlOrderField["AccessibilityAaaIssuesCount"] = "accessibilityAaaIssuesCount";
    CrawlUrlOrderField["AccessibilityBpIssuesCount"] = "accessibilityBpIssuesCount";
    CrawlUrlOrderField["AccessibilityCriticalIssuesCount"] = "accessibilityCriticalIssuesCount";
    CrawlUrlOrderField["AccessibilityEnabled"] = "accessibilityEnabled";
    CrawlUrlOrderField["AccessibilityIncompleteIssuesCount"] = "accessibilityIncompleteIssuesCount";
    CrawlUrlOrderField["AccessibilityIssuesCount"] = "accessibilityIssuesCount";
    CrawlUrlOrderField["AccessibilityIssuesCountByRuleIdCount"] = "accessibilityIssuesCountByRuleIdCount";
    CrawlUrlOrderField["AccessibilityMinorIssuesCount"] = "accessibilityMinorIssuesCount";
    CrawlUrlOrderField["AccessibilityModerateIssuesCount"] = "accessibilityModerateIssuesCount";
    CrawlUrlOrderField["AccessibilitySeriousIssuesCount"] = "accessibilitySeriousIssuesCount";
    CrawlUrlOrderField["AdultRating"] = "adultRating";
    CrawlUrlOrderField["AiBotBlockedCount"] = "aiBotBlockedCount";
    CrawlUrlOrderField["Amphtml"] = "amphtml";
    CrawlUrlOrderField["AmphtmlReciprocate"] = "amphtmlReciprocate";
    CrawlUrlOrderField["AmphtmlValid"] = "amphtmlValid";
    CrawlUrlOrderField["BacklinkCount"] = "backlinkCount";
    CrawlUrlOrderField["BacklinkDomainCount"] = "backlinkDomainCount";
    CrawlUrlOrderField["BodyTagsInHeadCount"] = "bodyTagsInHeadCount";
    CrawlUrlOrderField["Breadcrumb01"] = "breadcrumb01";
    CrawlUrlOrderField["Breadcrumb02"] = "breadcrumb02";
    CrawlUrlOrderField["Breadcrumb03"] = "breadcrumb03";
    CrawlUrlOrderField["Breadcrumb04"] = "breadcrumb04";
    CrawlUrlOrderField["Breadcrumb05"] = "breadcrumb05";
    CrawlUrlOrderField["Breadcrumb06"] = "breadcrumb06";
    CrawlUrlOrderField["Breadcrumb07"] = "breadcrumb07";
    CrawlUrlOrderField["Breadcrumb08"] = "breadcrumb08";
    CrawlUrlOrderField["BreadcrumbCount"] = "breadcrumbCount";
    CrawlUrlOrderField["BreadcrumbSchemaCount"] = "breadcrumbSchemaCount";
    CrawlUrlOrderField["CanonicalHeaderUrl"] = "canonicalHeaderUrl";
    CrawlUrlOrderField["CanonicalHtmlUrl"] = "canonicalHtmlUrl";
    CrawlUrlOrderField["CanonicalLinksInCount"] = "canonicalLinksInCount";
    CrawlUrlOrderField["CanonicalUrl"] = "canonicalUrl";
    CrawlUrlOrderField["CanonicalUrlChangedFrom"] = "canonicalUrlChangedFrom";
    CrawlUrlOrderField["CanonicalUrlIsConsistent"] = "canonicalUrlIsConsistent";
    CrawlUrlOrderField["CanonicalizedPage"] = "canonicalizedPage";
    CrawlUrlOrderField["ChangesCount"] = "changesCount";
    CrawlUrlOrderField["ContainerExecutionFailuresCount"] = "containerExecutionFailuresCount";
    CrawlUrlOrderField["ContentHtmlRatio"] = "contentHtmlRatio";
    CrawlUrlOrderField["ContentSize"] = "contentSize";
    CrawlUrlOrderField["CrawlDatetime"] = "crawlDatetime";
    CrawlUrlOrderField["Css"] = "css";
    CrawlUrlOrderField["CssLinksInCount"] = "cssLinksInCount";
    CrawlUrlOrderField["CumulativeLayoutShift"] = "cumulativeLayoutShift";
    CrawlUrlOrderField["CumulativeLayoutShiftMainFrame"] = "cumulativeLayoutShiftMainFrame";
    CrawlUrlOrderField["CumulativeLayoutShiftScore"] = "cumulativeLayoutShiftScore";
    CrawlUrlOrderField["CustomExtraction1Count"] = "customExtraction1Count";
    CrawlUrlOrderField["CustomExtraction2Count"] = "customExtraction2Count";
    CrawlUrlOrderField["CustomExtraction3Count"] = "customExtraction3Count";
    CrawlUrlOrderField["CustomExtraction4Count"] = "customExtraction4Count";
    CrawlUrlOrderField["CustomExtraction5Count"] = "customExtraction5Count";
    CrawlUrlOrderField["CustomExtraction6Count"] = "customExtraction6Count";
    CrawlUrlOrderField["CustomExtraction7Count"] = "customExtraction7Count";
    CrawlUrlOrderField["CustomExtraction8Count"] = "customExtraction8Count";
    CrawlUrlOrderField["CustomExtraction9Count"] = "customExtraction9Count";
    CrawlUrlOrderField["CustomExtraction10Count"] = "customExtraction10Count";
    CrawlUrlOrderField["CustomExtraction11Count"] = "customExtraction11Count";
    CrawlUrlOrderField["CustomExtraction12Count"] = "customExtraction12Count";
    CrawlUrlOrderField["CustomExtraction13Count"] = "customExtraction13Count";
    CrawlUrlOrderField["CustomExtraction14Count"] = "customExtraction14Count";
    CrawlUrlOrderField["CustomExtraction15Count"] = "customExtraction15Count";
    CrawlUrlOrderField["CustomExtraction16Count"] = "customExtraction16Count";
    CrawlUrlOrderField["CustomExtraction17Count"] = "customExtraction17Count";
    CrawlUrlOrderField["CustomExtraction18Count"] = "customExtraction18Count";
    CrawlUrlOrderField["CustomExtraction19Count"] = "customExtraction19Count";
    CrawlUrlOrderField["CustomExtraction20Count"] = "customExtraction20Count";
    CrawlUrlOrderField["CustomExtraction21Count"] = "customExtraction21Count";
    CrawlUrlOrderField["CustomExtraction22Count"] = "customExtraction22Count";
    CrawlUrlOrderField["CustomExtraction23Count"] = "customExtraction23Count";
    CrawlUrlOrderField["CustomExtraction24Count"] = "customExtraction24Count";
    CrawlUrlOrderField["CustomExtraction25Count"] = "customExtraction25Count";
    CrawlUrlOrderField["CustomExtraction26Count"] = "customExtraction26Count";
    CrawlUrlOrderField["CustomExtraction27Count"] = "customExtraction27Count";
    CrawlUrlOrderField["CustomExtraction28Count"] = "customExtraction28Count";
    CrawlUrlOrderField["CustomExtraction29Count"] = "customExtraction29Count";
    CrawlUrlOrderField["CustomExtraction30Count"] = "customExtraction30Count";
    CrawlUrlOrderField["CustomExtractionMatch"] = "customExtractionMatch";
    CrawlUrlOrderField["Deeprank"] = "deeprank";
    CrawlUrlOrderField["Description"] = "description";
    CrawlUrlOrderField["DescriptionChangedFrom"] = "descriptionChangedFrom";
    CrawlUrlOrderField["DescriptionLength"] = "descriptionLength";
    CrawlUrlOrderField["DescriptionLengthPx"] = "descriptionLengthPx";
    CrawlUrlOrderField["DesktopUrl"] = "desktopUrl";
    CrawlUrlOrderField["DisallowedPage"] = "disallowedPage";
    CrawlUrlOrderField["DuplicateBody"] = "duplicateBody";
    CrawlUrlOrderField["DuplicateBodyCount"] = "duplicateBodyCount";
    CrawlUrlOrderField["DuplicateBodyCountIsApproximation"] = "duplicateBodyCountIsApproximation";
    CrawlUrlOrderField["DuplicateBodyNonIndexable"] = "duplicateBodyNonIndexable";
    CrawlUrlOrderField["DuplicateBodyPrimaryUrl"] = "duplicateBodyPrimaryUrl";
    CrawlUrlOrderField["DuplicateDescription"] = "duplicateDescription";
    CrawlUrlOrderField["DuplicateDescriptionCount"] = "duplicateDescriptionCount";
    CrawlUrlOrderField["DuplicateDescriptionCountIsApproximation"] = "duplicateDescriptionCountIsApproximation";
    CrawlUrlOrderField["DuplicateDescriptionNonIndexable"] = "duplicateDescriptionNonIndexable";
    CrawlUrlOrderField["DuplicateDescriptionPrimaryUrl"] = "duplicateDescriptionPrimaryUrl";
    CrawlUrlOrderField["DuplicatePage"] = "duplicatePage";
    CrawlUrlOrderField["DuplicatePageCount"] = "duplicatePageCount";
    CrawlUrlOrderField["DuplicatePageCountIsApproximation"] = "duplicatePageCountIsApproximation";
    CrawlUrlOrderField["DuplicatePageNonIndexable"] = "duplicatePageNonIndexable";
    CrawlUrlOrderField["DuplicateTitle"] = "duplicateTitle";
    CrawlUrlOrderField["DuplicateTitleCount"] = "duplicateTitleCount";
    CrawlUrlOrderField["DuplicateTitleCountIsApproximation"] = "duplicateTitleCountIsApproximation";
    CrawlUrlOrderField["DuplicateTitleNonIndexable"] = "duplicateTitleNonIndexable";
    CrawlUrlOrderField["DuplicateTitlePrimaryUrl"] = "duplicateTitlePrimaryUrl";
    CrawlUrlOrderField["DuplicateUrl"] = "duplicateUrl";
    CrawlUrlOrderField["DuplicateUrlCount"] = "duplicateUrlCount";
    CrawlUrlOrderField["DuplicateUrlCountIsApproximation"] = "duplicateUrlCountIsApproximation";
    CrawlUrlOrderField["DuplicateUrlNonIndexable"] = "duplicateUrlNonIndexable";
    CrawlUrlOrderField["DuplicateUrlPrimaryUrl"] = "duplicateUrlPrimaryUrl";
    CrawlUrlOrderField["EncodingHeader"] = "encodingHeader";
    CrawlUrlOrderField["EventSchemaCount"] = "eventSchemaCount";
    CrawlUrlOrderField["ExternalLinksCount"] = "externalLinksCount";
    CrawlUrlOrderField["FailedAuditsCount"] = "failedAuditsCount";
    CrawlUrlOrderField["FailedReason"] = "failedReason";
    CrawlUrlOrderField["FaqpageSchemaCount"] = "faqpageSchemaCount";
    CrawlUrlOrderField["FbAdmins"] = "fbAdmins";
    CrawlUrlOrderField["FbAppId"] = "fbAppId";
    CrawlUrlOrderField["FetchTime"] = "fetchTime";
    CrawlUrlOrderField["FileExtension"] = "fileExtension";
    CrawlUrlOrderField["FirstContentfulPaint"] = "firstContentfulPaint";
    CrawlUrlOrderField["FirstContentfulPaintScore"] = "firstContentfulPaintScore";
    CrawlUrlOrderField["FirstMeaningfulPaint"] = "firstMeaningfulPaint";
    CrawlUrlOrderField["FolderCount"] = "folderCount";
    CrawlUrlOrderField["FollowedExternalLinksOutCount"] = "followedExternalLinksOutCount";
    CrawlUrlOrderField["FollowedLinksInCount"] = "followedLinksInCount";
    CrawlUrlOrderField["FoundAtSitemap"] = "foundAtSitemap";
    CrawlUrlOrderField["FoundAtUrl"] = "foundAtUrl";
    CrawlUrlOrderField["FoundInBacklinks"] = "foundInBacklinks";
    CrawlUrlOrderField["FoundInCustomMetricsUpload"] = "foundInCustomMetricsUpload";
    CrawlUrlOrderField["FoundInGoogleAnalytics"] = "foundInGoogleAnalytics";
    CrawlUrlOrderField["FoundInGoogleSearchConsole"] = "foundInGoogleSearchConsole";
    CrawlUrlOrderField["FoundInList"] = "foundInList";
    CrawlUrlOrderField["FoundInLogSummary"] = "foundInLogSummary";
    CrawlUrlOrderField["FoundInSitemap"] = "foundInSitemap";
    CrawlUrlOrderField["FoundInTask"] = "foundInTask";
    CrawlUrlOrderField["FoundInWebCrawl"] = "foundInWebCrawl";
    CrawlUrlOrderField["GaAvgPageLoadTime"] = "gaAvgPageLoadTime";
    CrawlUrlOrderField["GaAvgTimeOnPage"] = "gaAvgTimeOnPage";
    CrawlUrlOrderField["GaPageviews"] = "gaPageviews";
    CrawlUrlOrderField["GaPageviewsPerVisits"] = "gaPageviewsPerVisits";
    CrawlUrlOrderField["GaVisitBounceRate"] = "gaVisitBounceRate";
    CrawlUrlOrderField["GaVisits"] = "gaVisits";
    CrawlUrlOrderField["H1Count"] = "h1Count";
    CrawlUrlOrderField["H1Length"] = "h1Length";
    CrawlUrlOrderField["H1TagChangedFromCount"] = "h1TagChangedFromCount";
    CrawlUrlOrderField["H1TagCount"] = "h1TagCount";
    CrawlUrlOrderField["H2TagCount"] = "h2TagCount";
    CrawlUrlOrderField["H3TagCount"] = "h3TagCount";
    CrawlUrlOrderField["HasStructuredData"] = "hasStructuredData";
    CrawlUrlOrderField["HeadTagsInBodyCount"] = "headTagsInBodyCount";
    CrawlUrlOrderField["HeaderContentType"] = "headerContentType";
    CrawlUrlOrderField["HeaderNoarchive"] = "headerNoarchive";
    CrawlUrlOrderField["HeaderNofollow"] = "headerNofollow";
    CrawlUrlOrderField["HeaderNoindex"] = "headerNoindex";
    CrawlUrlOrderField["HeaderNoodp"] = "headerNoodp";
    CrawlUrlOrderField["HeaderNosnippet"] = "headerNosnippet";
    CrawlUrlOrderField["HeaderNoydir"] = "headerNoydir";
    CrawlUrlOrderField["HowtoSchemaCount"] = "howtoSchemaCount";
    CrawlUrlOrderField["HreflangCombinationChangedFromCount"] = "hreflangCombinationChangedFromCount";
    CrawlUrlOrderField["HreflangCombinationCount"] = "hreflangCombinationCount";
    CrawlUrlOrderField["HreflangLanguagesInCount"] = "hreflangLanguagesInCount";
    CrawlUrlOrderField["HreflangLanguagesInMetaCount"] = "hreflangLanguagesInMetaCount";
    CrawlUrlOrderField["HreflangLinksInCount"] = "hreflangLinksInCount";
    CrawlUrlOrderField["HreflangLinksOutCount"] = "hreflangLinksOutCount";
    CrawlUrlOrderField["HreflangOnHeader"] = "hreflangOnHeader";
    CrawlUrlOrderField["HreflangOnPage"] = "hreflangOnPage";
    CrawlUrlOrderField["HreflangOnSitemap"] = "hreflangOnSitemap";
    CrawlUrlOrderField["HreflangUrlCount"] = "hreflangUrlCount";
    CrawlUrlOrderField["Hsts"] = "hsts";
    CrawlUrlOrderField["HtmlSize"] = "htmlSize";
    CrawlUrlOrderField["HttpStatusCode"] = "httpStatusCode";
    CrawlUrlOrderField["HttpStatusCodeChangedFrom"] = "httpStatusCodeChangedFrom";
    CrawlUrlOrderField["Https"] = "https";
    CrawlUrlOrderField["IdentifiedLanguage"] = "identifiedLanguage";
    CrawlUrlOrderField["ImgLinksInCount"] = "imgLinksInCount";
    CrawlUrlOrderField["InconsistentHreflangsInCount"] = "inconsistentHreflangsInCount";
    CrawlUrlOrderField["Indexable"] = "indexable";
    CrawlUrlOrderField["InfoAuditsCount"] = "infoAuditsCount";
    CrawlUrlOrderField["Interactive"] = "interactive";
    CrawlUrlOrderField["Internal"] = "internal";
    CrawlUrlOrderField["InternalLinksCount"] = "internalLinksCount";
    CrawlUrlOrderField["IsImage"] = "isImage";
    CrawlUrlOrderField["IsRedirect"] = "isRedirect";
    CrawlUrlOrderField["IsRedirectLoop"] = "isRedirectLoop";
    CrawlUrlOrderField["IsSelfCanonical"] = "isSelfCanonical";
    CrawlUrlOrderField["IsWebLinked"] = "isWebLinked";
    CrawlUrlOrderField["Js"] = "js";
    CrawlUrlOrderField["JsLinksInCount"] = "jsLinksInCount";
    CrawlUrlOrderField["LargestContentfulPaint"] = "largestContentfulPaint";
    CrawlUrlOrderField["LargestContentfulPaintScore"] = "largestContentfulPaintScore";
    CrawlUrlOrderField["LcpLoadEnd"] = "lcpLoadEnd";
    CrawlUrlOrderField["LcpLoadStart"] = "lcpLoadStart";
    CrawlUrlOrderField["Level"] = "level";
    CrawlUrlOrderField["LevelChangedFrom"] = "levelChangedFrom";
    CrawlUrlOrderField["LinksInCount"] = "linksInCount";
    CrawlUrlOrderField["LinksOutCount"] = "linksOutCount";
    CrawlUrlOrderField["LogRequestsDesktop"] = "logRequestsDesktop";
    CrawlUrlOrderField["LogRequestsMobile"] = "logRequestsMobile";
    CrawlUrlOrderField["LogRequestsTotal"] = "logRequestsTotal";
    CrawlUrlOrderField["MaxPotentialFid"] = "maxPotentialFid";
    CrawlUrlOrderField["MetaCharset"] = "metaCharset";
    CrawlUrlOrderField["MetaContentType"] = "metaContentType";
    CrawlUrlOrderField["MetaDisabledSitelinks"] = "metaDisabledSitelinks";
    CrawlUrlOrderField["MetaNoarchive"] = "metaNoarchive";
    CrawlUrlOrderField["MetaNofollow"] = "metaNofollow";
    CrawlUrlOrderField["MetaNoindex"] = "metaNoindex";
    CrawlUrlOrderField["MetaNoodp"] = "metaNoodp";
    CrawlUrlOrderField["MetaNosnippet"] = "metaNosnippet";
    CrawlUrlOrderField["MetaNoydir"] = "metaNoydir";
    CrawlUrlOrderField["MetaRedirect"] = "metaRedirect";
    CrawlUrlOrderField["MixedContentWarningsCount"] = "mixedContentWarningsCount";
    CrawlUrlOrderField["MobileDesktopContentDifference"] = "mobileDesktopContentDifference";
    CrawlUrlOrderField["MobileDesktopContentMismatch"] = "mobileDesktopContentMismatch";
    CrawlUrlOrderField["MobileDesktopLinksInDifference"] = "mobileDesktopLinksInDifference";
    CrawlUrlOrderField["MobileDesktopLinksOutDifference"] = "mobileDesktopLinksOutDifference";
    CrawlUrlOrderField["MobileLinksInCount"] = "mobileLinksInCount";
    CrawlUrlOrderField["MobileReciprocate"] = "mobileReciprocate";
    CrawlUrlOrderField["MobileRelAlternate"] = "mobileRelAlternate";
    CrawlUrlOrderField["MobileRelAlternateHeaderUrl"] = "mobileRelAlternateHeaderUrl";
    CrawlUrlOrderField["MobileRelAlternateHtmlUrl"] = "mobileRelAlternateHtmlUrl";
    CrawlUrlOrderField["MobileRelAlternateUrl"] = "mobileRelAlternateUrl";
    CrawlUrlOrderField["MobileRelAlternateUrlIsConsistent"] = "mobileRelAlternateUrlIsConsistent";
    CrawlUrlOrderField["MobileRelAmphtml"] = "mobileRelAmphtml";
    CrawlUrlOrderField["MobileRelAmphtmlUrl"] = "mobileRelAmphtmlUrl";
    CrawlUrlOrderField["NewsarticleAuthorSchemaValue"] = "newsarticleAuthorSchemaValue";
    CrawlUrlOrderField["NewsarticleSchemaCount"] = "newsarticleSchemaCount";
    CrawlUrlOrderField["NewsarticleSchemaValid"] = "newsarticleSchemaValid";
    CrawlUrlOrderField["Noarchive"] = "noarchive";
    CrawlUrlOrderField["NofollowedPage"] = "nofollowedPage";
    CrawlUrlOrderField["Noindex"] = "noindex";
    CrawlUrlOrderField["NonReciprocateHreflangOutCount"] = "nonReciprocateHreflangOutCount";
    CrawlUrlOrderField["Noodp"] = "noodp";
    CrawlUrlOrderField["Nosnippet"] = "nosnippet";
    CrawlUrlOrderField["Noydir"] = "noydir";
    CrawlUrlOrderField["OgDescription"] = "ogDescription";
    CrawlUrlOrderField["OgImage"] = "ogImage";
    CrawlUrlOrderField["OgLocale"] = "ogLocale";
    CrawlUrlOrderField["OgSiteName"] = "ogSiteName";
    CrawlUrlOrderField["OgTitle"] = "ogTitle";
    CrawlUrlOrderField["OgType"] = "ogType";
    CrawlUrlOrderField["OgUrl"] = "ogUrl";
    CrawlUrlOrderField["Page1"] = "page1";
    CrawlUrlOrderField["PageGroup"] = "pageGroup";
    CrawlUrlOrderField["PageTitle"] = "pageTitle";
    CrawlUrlOrderField["PageTitleChangedFrom"] = "pageTitleChangedFrom";
    CrawlUrlOrderField["PageTitleLength"] = "pageTitleLength";
    CrawlUrlOrderField["PageTitleLengthPx"] = "pageTitleLengthPx";
    CrawlUrlOrderField["PaginatedLinksInCount"] = "paginatedLinksInCount";
    CrawlUrlOrderField["PaginatedPage"] = "paginatedPage";
    CrawlUrlOrderField["ParamCount"] = "paramCount";
    CrawlUrlOrderField["PassedAuditsCount"] = "passedAuditsCount";
    CrawlUrlOrderField["Path0"] = "path0";
    CrawlUrlOrderField["Path1"] = "path1";
    CrawlUrlOrderField["Path2"] = "path2";
    CrawlUrlOrderField["Path3"] = "path3";
    CrawlUrlOrderField["Path4"] = "path4";
    CrawlUrlOrderField["Path5"] = "path5";
    CrawlUrlOrderField["Path6"] = "path6";
    CrawlUrlOrderField["Path7"] = "path7";
    CrawlUrlOrderField["Path8"] = "path8";
    CrawlUrlOrderField["Path9"] = "path9";
    CrawlUrlOrderField["PathCount"] = "pathCount";
    CrawlUrlOrderField["PerformanceCls"] = "performanceCls";
    CrawlUrlOrderField["PerformanceDcl"] = "performanceDcl";
    CrawlUrlOrderField["PerformanceFcp"] = "performanceFcp";
    CrawlUrlOrderField["PerformanceLcp"] = "performanceLcp";
    CrawlUrlOrderField["PerformanceTtfb"] = "performanceTtfb";
    CrawlUrlOrderField["PerformanceTti"] = "performanceTti";
    CrawlUrlOrderField["PreviousPath"] = "previousPath";
    CrawlUrlOrderField["PrimaryConversionsCount"] = "primaryConversionsCount";
    CrawlUrlOrderField["PrimaryConversionsPerSession"] = "primaryConversionsPerSession";
    CrawlUrlOrderField["PrimaryConversionsValue"] = "primaryConversionsValue";
    CrawlUrlOrderField["PrimaryPage"] = "primaryPage";
    CrawlUrlOrderField["PrimaryUrl"] = "primaryUrl";
    CrawlUrlOrderField["PrimaryUrlNonIndexable"] = "primaryUrlNonIndexable";
    CrawlUrlOrderField["ProductAvailabilitySchemaValue"] = "productAvailabilitySchemaValue";
    CrawlUrlOrderField["ProductBrandSchemaValue"] = "productBrandSchemaValue";
    CrawlUrlOrderField["ProductOfSavingsKibAndPageviews"] = "productOfSavingsKibAndPageviews";
    CrawlUrlOrderField["ProductOfSavingsSecsAndPageviews"] = "productOfSavingsSecsAndPageviews";
    CrawlUrlOrderField["ProductPriceSchemaValue"] = "productPriceSchemaValue";
    CrawlUrlOrderField["ProductRatingSchemaValue"] = "productRatingSchemaValue";
    CrawlUrlOrderField["ProductSchemaCount"] = "productSchemaCount";
    CrawlUrlOrderField["ProductSchemaValid"] = "productSchemaValid";
    CrawlUrlOrderField["ProductSkuSchemaValue"] = "productSkuSchemaValue";
    CrawlUrlOrderField["QapageSchemaCount"] = "qapageSchemaCount";
    CrawlUrlOrderField["RecipeSchemaCount"] = "recipeSchemaCount";
    CrawlUrlOrderField["ReciprocateHreflangOutCount"] = "reciprocateHreflangOutCount";
    CrawlUrlOrderField["RedirectChainCount"] = "redirectChainCount";
    CrawlUrlOrderField["RedirectCount"] = "redirectCount";
    CrawlUrlOrderField["RedirectedToStatusCode"] = "redirectedToStatusCode";
    CrawlUrlOrderField["RedirectedToUrl"] = "redirectedToUrl";
    CrawlUrlOrderField["RedirectedToUrlChangedFrom"] = "redirectedToUrlChangedFrom";
    CrawlUrlOrderField["RedirectsInCount"] = "redirectsInCount";
    CrawlUrlOrderField["RelLinksInCount"] = "relLinksInCount";
    CrawlUrlOrderField["RelNextHeaderUrl"] = "relNextHeaderUrl";
    CrawlUrlOrderField["RelNextHtmlUrl"] = "relNextHtmlUrl";
    CrawlUrlOrderField["RelNextUrl"] = "relNextUrl";
    CrawlUrlOrderField["RelNextUrlIsConsistent"] = "relNextUrlIsConsistent";
    CrawlUrlOrderField["RelPrevHeaderUrl"] = "relPrevHeaderUrl";
    CrawlUrlOrderField["RelPrevHtmlUrl"] = "relPrevHtmlUrl";
    CrawlUrlOrderField["RelPrevUrl"] = "relPrevUrl";
    CrawlUrlOrderField["RelPrevUrlIsConsistent"] = "relPrevUrlIsConsistent";
    CrawlUrlOrderField["RenderedCanonicalLinkMatch"] = "renderedCanonicalLinkMatch";
    CrawlUrlOrderField["RenderedLinkCountDifference"] = "renderedLinkCountDifference";
    CrawlUrlOrderField["RenderedWordCountDifference"] = "renderedWordCountDifference";
    CrawlUrlOrderField["ResourceLinksInCount"] = "resourceLinksInCount";
    CrawlUrlOrderField["Responsive"] = "responsive";
    CrawlUrlOrderField["ReviewSchemaCount"] = "reviewSchemaCount";
    CrawlUrlOrderField["RobotsNoindex"] = "robotsNoindex";
    CrawlUrlOrderField["RobotsTxtRuleMatch"] = "robotsTxtRuleMatch";
    CrawlUrlOrderField["SavingsKib"] = "savingsKib";
    CrawlUrlOrderField["SavingsScore"] = "savingsScore";
    CrawlUrlOrderField["SavingsSecs"] = "savingsSecs";
    CrawlUrlOrderField["SearchConsoleDesktopClicks"] = "searchConsoleDesktopClicks";
    CrawlUrlOrderField["SearchConsoleDesktopCtr"] = "searchConsoleDesktopCtr";
    CrawlUrlOrderField["SearchConsoleDesktopImpressions"] = "searchConsoleDesktopImpressions";
    CrawlUrlOrderField["SearchConsoleDesktopPosition"] = "searchConsoleDesktopPosition";
    CrawlUrlOrderField["SearchConsoleMobileClicks"] = "searchConsoleMobileClicks";
    CrawlUrlOrderField["SearchConsoleMobileCtr"] = "searchConsoleMobileCtr";
    CrawlUrlOrderField["SearchConsoleMobileImpressions"] = "searchConsoleMobileImpressions";
    CrawlUrlOrderField["SearchConsoleMobilePosition"] = "searchConsoleMobilePosition";
    CrawlUrlOrderField["SearchConsoleTabletClicks"] = "searchConsoleTabletClicks";
    CrawlUrlOrderField["SearchConsoleTabletCtr"] = "searchConsoleTabletCtr";
    CrawlUrlOrderField["SearchConsoleTabletImpressions"] = "searchConsoleTabletImpressions";
    CrawlUrlOrderField["SearchConsoleTabletPosition"] = "searchConsoleTabletPosition";
    CrawlUrlOrderField["SearchConsoleTotalClicks"] = "searchConsoleTotalClicks";
    CrawlUrlOrderField["SearchConsoleTotalCtr"] = "searchConsoleTotalCtr";
    CrawlUrlOrderField["SearchConsoleTotalImpressions"] = "searchConsoleTotalImpressions";
    CrawlUrlOrderField["SearchConsoleTotalPosition"] = "searchConsoleTotalPosition";
    CrawlUrlOrderField["SecureFormInputField"] = "secureFormInputField";
    CrawlUrlOrderField["SeparateDesktop"] = "separateDesktop";
    CrawlUrlOrderField["SeparateMobile"] = "separateMobile";
    CrawlUrlOrderField["SitemapsInCount"] = "sitemapsInCount";
    CrawlUrlOrderField["SitespeedAuditResultsCount"] = "sitespeedAuditResultsCount";
    CrawlUrlOrderField["SitespeedCls"] = "sitespeedCls";
    CrawlUrlOrderField["SitespeedClsAuditResultsCount"] = "sitespeedClsAuditResultsCount";
    CrawlUrlOrderField["SitespeedClsMainFrame"] = "sitespeedClsMainFrame";
    CrawlUrlOrderField["SitespeedClsScore"] = "sitespeedClsScore";
    CrawlUrlOrderField["SitespeedFcp"] = "sitespeedFcp";
    CrawlUrlOrderField["SitespeedFcpAuditResultsCount"] = "sitespeedFcpAuditResultsCount";
    CrawlUrlOrderField["SitespeedFcpScore"] = "sitespeedFcpScore";
    CrawlUrlOrderField["SitespeedFmp"] = "sitespeedFmp";
    CrawlUrlOrderField["SitespeedLcp"] = "sitespeedLcp";
    CrawlUrlOrderField["SitespeedLcpAuditResultsCount"] = "sitespeedLcpAuditResultsCount";
    CrawlUrlOrderField["SitespeedLcpScore"] = "sitespeedLcpScore";
    CrawlUrlOrderField["SitespeedMaxPotentialFid"] = "sitespeedMaxPotentialFid";
    CrawlUrlOrderField["SitespeedScore"] = "sitespeedScore";
    CrawlUrlOrderField["SitespeedSpeedIndex"] = "sitespeedSpeedIndex";
    CrawlUrlOrderField["SitespeedSpeedIndexScore"] = "sitespeedSpeedIndexScore";
    CrawlUrlOrderField["SitespeedTbt"] = "sitespeedTbt";
    CrawlUrlOrderField["SitespeedTbtAuditResultsCount"] = "sitespeedTbtAuditResultsCount";
    CrawlUrlOrderField["SitespeedTbtScore"] = "sitespeedTbtScore";
    CrawlUrlOrderField["SitespeedTtfb"] = "sitespeedTtfb";
    CrawlUrlOrderField["SitespeedTti"] = "sitespeedTti";
    CrawlUrlOrderField["SpeedIndex"] = "speedIndex";
    CrawlUrlOrderField["SpeedIndexScore"] = "speedIndexScore";
    CrawlUrlOrderField["TaskIdsCount"] = "taskIdsCount";
    CrawlUrlOrderField["TimeToFirstByte"] = "timeToFirstByte";
    CrawlUrlOrderField["TotalBlockingTime"] = "totalBlockingTime";
    CrawlUrlOrderField["TotalBlockingTimeScore"] = "totalBlockingTimeScore";
    CrawlUrlOrderField["TransferSize"] = "transferSize";
    CrawlUrlOrderField["TwitterCard"] = "twitterCard";
    CrawlUrlOrderField["TwitterDescription"] = "twitterDescription";
    CrawlUrlOrderField["TwitterDescriptionLength"] = "twitterDescriptionLength";
    CrawlUrlOrderField["TwitterImage"] = "twitterImage";
    CrawlUrlOrderField["TwitterSite"] = "twitterSite";
    CrawlUrlOrderField["TwitterTitle"] = "twitterTitle";
    CrawlUrlOrderField["UnavailableAfter"] = "unavailableAfter";
    CrawlUrlOrderField["UnavailableAfterDays"] = "unavailableAfterDays";
    CrawlUrlOrderField["UniqueLinksInCount"] = "uniqueLinksInCount";
    CrawlUrlOrderField["Url"] = "url";
    CrawlUrlOrderField["UrlAlias"] = "urlAlias";
    CrawlUrlOrderField["UrlContainsRepeatedPaths"] = "urlContainsRepeatedPaths";
    CrawlUrlOrderField["UrlContainsUnderscores"] = "urlContainsUnderscores";
    CrawlUrlOrderField["UrlDoubleEncoded"] = "urlDoubleEncoded";
    CrawlUrlOrderField["UrlLength"] = "urlLength";
    CrawlUrlOrderField["UrlNormalized"] = "urlNormalized";
    CrawlUrlOrderField["UrlPathContainsDoubleSlashes"] = "urlPathContainsDoubleSlashes";
    CrawlUrlOrderField["ValidTwitterCard"] = "validTwitterCard";
    CrawlUrlOrderField["VaryUserAgent"] = "varyUserAgent";
    CrawlUrlOrderField["VideoobjectSchemaCount"] = "videoobjectSchemaCount";
    CrawlUrlOrderField["Viewport"] = "viewport";
    CrawlUrlOrderField["WarningAuditsCount"] = "warningAuditsCount";
    CrawlUrlOrderField["WordCount"] = "wordCount";
})(CrawlUrlOrderField || (CrawlUrlOrderField = {}));
export var CrawlWebCrawlDepthOrderField;
(function (CrawlWebCrawlDepthOrderField) {
    CrawlWebCrawlDepthOrderField["DuplicatePages"] = "duplicatePages";
    CrawlWebCrawlDepthOrderField["FailedUrls"] = "failedUrls";
    CrawlWebCrawlDepthOrderField["Level"] = "level";
    CrawlWebCrawlDepthOrderField["Non200StatusCodePages"] = "non200StatusCodePages";
    CrawlWebCrawlDepthOrderField["NonIndexablePages"] = "nonIndexablePages";
    CrawlWebCrawlDepthOrderField["PaginatedPages"] = "paginatedPages";
    CrawlWebCrawlDepthOrderField["UniquePages"] = "uniquePages";
})(CrawlWebCrawlDepthOrderField || (CrawlWebCrawlDepthOrderField = {}));
export var CrawlerType;
(function (CrawlerType) {
    CrawlerType["Legacy"] = "Legacy";
    CrawlerType["SerpentBasic"] = "SerpentBasic";
    CrawlerType["SerpentMapped"] = "SerpentMapped";
    CrawlerType["SerpentModular"] = "SerpentModular";
})(CrawlerType || (CrawlerType = {}));
export var CreditAllocationOrderField;
(function (CreditAllocationOrderField) {
    CreditAllocationOrderField["EndDate"] = "endDate";
    CreditAllocationOrderField["StartDate"] = "startDate";
    CreditAllocationOrderField["Type"] = "type";
})(CreditAllocationOrderField || (CreditAllocationOrderField = {}));
export var CreditAllocationType;
(function (CreditAllocationType) {
    CreditAllocationType["Accessibility"] = "Accessibility";
    CreditAllocationType["Custom"] = "Custom";
    CreditAllocationType["Seo"] = "SEO";
    CreditAllocationType["SiteSpeed"] = "SiteSpeed";
    CreditAllocationType["Typeless"] = "Typeless";
})(CreditAllocationType || (CreditAllocationType = {}));
export var CreditReportExportOrderField;
(function (CreditReportExportOrderField) {
    CreditReportExportOrderField["CreatedAt"] = "createdAt";
    CreditReportExportOrderField["ExportedAt"] = "exportedAt";
    CreditReportExportOrderField["ExportingAt"] = "exportingAt";
    CreditReportExportOrderField["UpdatedAt"] = "updatedAt";
})(CreditReportExportOrderField || (CreditReportExportOrderField = {}));
export var CreditReportOrderField;
(function (CreditReportOrderField) {
    CreditReportOrderField["CrawlingAt"] = "crawlingAt";
    CreditReportOrderField["CreatedAt"] = "createdAt";
    CreditReportOrderField["CreditAllocationType"] = "creditAllocationType";
    CreditReportOrderField["CreditsUsed"] = "creditsUsed";
    CreditReportOrderField["FinishedAt"] = "finishedAt";
    CreditReportOrderField["Type"] = "type";
    CreditReportOrderField["UpdatedAt"] = "updatedAt";
    CreditReportOrderField["UrlsCrawled"] = "urlsCrawled";
})(CreditReportOrderField || (CreditReportOrderField = {}));
export var CreditReportType;
(function (CreditReportType) {
    CreditReportType["Crawl"] = "Crawl";
    CreditReportType["SinglePageRequester"] = "SinglePageRequester";
})(CreditReportType || (CreditReportType = {}));
export var Currency;
(function (Currency) {
    Currency["Eur"] = "EUR";
    Currency["Gbp"] = "GBP";
    Currency["Jpy"] = "JPY";
    Currency["Usd"] = "USD";
})(Currency || (Currency = {}));
export var CustomChartOrderField;
(function (CustomChartOrderField) {
    CustomChartOrderField["CreatedAt"] = "createdAt";
    CustomChartOrderField["Metric"] = "metric";
    CustomChartOrderField["Type"] = "type";
    CustomChartOrderField["UpdatedAt"] = "updatedAt";
})(CustomChartOrderField || (CustomChartOrderField = {}));
export var CustomChartType;
(function (CustomChartType) {
    CustomChartType["HealthScoreCrawlCompare"] = "HealthScoreCrawlCompare";
    CustomChartType["MonitorCrawlCompare"] = "MonitorCrawlCompare";
})(CustomChartType || (CustomChartType = {}));
export var CustomDashboardCollectionOrderField;
(function (CustomDashboardCollectionOrderField) {
    CustomDashboardCollectionOrderField["CreatedAt"] = "createdAt";
    CustomDashboardCollectionOrderField["Name"] = "name";
    CustomDashboardCollectionOrderField["Position"] = "position";
    CustomDashboardCollectionOrderField["UpdatedAt"] = "updatedAt";
})(CustomDashboardCollectionOrderField || (CustomDashboardCollectionOrderField = {}));
export var CustomDashboardOrderField;
(function (CustomDashboardOrderField) {
    CustomDashboardOrderField["CollectionPosition"] = "collectionPosition";
    CustomDashboardOrderField["CreatedAt"] = "createdAt";
    CustomDashboardOrderField["CustomDashboardCollectionId"] = "customDashboardCollectionId";
    CustomDashboardOrderField["Name"] = "name";
    CustomDashboardOrderField["Type"] = "type";
    CustomDashboardOrderField["UpdatedAt"] = "updatedAt";
})(CustomDashboardOrderField || (CustomDashboardOrderField = {}));
export var CustomDashboardType;
(function (CustomDashboardType) {
    CustomDashboardType["HealthScores"] = "HealthScores";
    CustomDashboardType["Monitor"] = "Monitor";
})(CustomDashboardType || (CustomDashboardType = {}));
export var CustomDashboardViewOrderField;
(function (CustomDashboardViewOrderField) {
    CustomDashboardViewOrderField["ProjectAllPagesTotal"] = "projectAllPagesTotal";
    CustomDashboardViewOrderField["ProjectCreatedAt"] = "projectCreatedAt";
    CustomDashboardViewOrderField["ProjectFinishedAt"] = "projectFinishedAt";
    CustomDashboardViewOrderField["ProjectLastCrawlCrawlingAt"] = "projectLastCrawlCrawlingAt";
    CustomDashboardViewOrderField["ProjectLastCrawlStatus"] = "projectLastCrawlStatus";
    CustomDashboardViewOrderField["ProjectName"] = "projectName";
    CustomDashboardViewOrderField["ProjectPrimaryDomain"] = "projectPrimaryDomain";
    CustomDashboardViewOrderField["SegmentName"] = "segmentName";
})(CustomDashboardViewOrderField || (CustomDashboardViewOrderField = {}));
export var CustomMetricContainerGenerationOrderField;
(function (CustomMetricContainerGenerationOrderField) {
    CustomMetricContainerGenerationOrderField["DeletedAt"] = "deletedAt";
    CustomMetricContainerGenerationOrderField["Description"] = "description";
    CustomMetricContainerGenerationOrderField["FailedAt"] = "failedAt";
    CustomMetricContainerGenerationOrderField["FailureReason"] = "failureReason";
    CustomMetricContainerGenerationOrderField["GeneratedAt"] = "generatedAt";
    CustomMetricContainerGenerationOrderField["Id"] = "id";
    CustomMetricContainerGenerationOrderField["Name"] = "name";
    CustomMetricContainerGenerationOrderField["RequestedAt"] = "requestedAt";
    CustomMetricContainerGenerationOrderField["StartedAt"] = "startedAt";
    CustomMetricContainerGenerationOrderField["Status"] = "status";
})(CustomMetricContainerGenerationOrderField || (CustomMetricContainerGenerationOrderField = {}));
export var CustomMetricContainerGenerationStatus;
(function (CustomMetricContainerGenerationStatus) {
    CustomMetricContainerGenerationStatus["Created"] = "Created";
    CustomMetricContainerGenerationStatus["Failed"] = "Failed";
    CustomMetricContainerGenerationStatus["Generated"] = "Generated";
    CustomMetricContainerGenerationStatus["Generating"] = "Generating";
    CustomMetricContainerGenerationStatus["GenerationRequested"] = "GenerationRequested";
})(CustomMetricContainerGenerationStatus || (CustomMetricContainerGenerationStatus = {}));
export var CustomMetricContainerInputType;
(function (CustomMetricContainerInputType) {
    CustomMetricContainerInputType["Dom"] = "DOM";
    CustomMetricContainerInputType["Puppeteer"] = "Puppeteer";
})(CustomMetricContainerInputType || (CustomMetricContainerInputType = {}));
export var CustomMetricContainerOrderField;
(function (CustomMetricContainerOrderField) {
    CustomMetricContainerOrderField["AccountId"] = "accountId";
    CustomMetricContainerOrderField["DisplayName"] = "displayName";
    CustomMetricContainerOrderField["Id"] = "id";
    CustomMetricContainerOrderField["InputType"] = "inputType";
    CustomMetricContainerOrderField["IsGlobal"] = "isGlobal";
    CustomMetricContainerOrderField["Name"] = "name";
    CustomMetricContainerOrderField["Scope"] = "scope";
})(CustomMetricContainerOrderField || (CustomMetricContainerOrderField = {}));
export var CustomMetricContainerOutputType;
(function (CustomMetricContainerOutputType) {
    CustomMetricContainerOutputType["MultiOutput"] = "MultiOutput";
    CustomMetricContainerOutputType["SingleOutput"] = "SingleOutput";
})(CustomMetricContainerOutputType || (CustomMetricContainerOutputType = {}));
export var CustomMetricContainerProjectOrderField;
(function (CustomMetricContainerProjectOrderField) {
    CustomMetricContainerProjectOrderField["CustomMetricContainerId"] = "customMetricContainerId";
    CustomMetricContainerProjectOrderField["Enabled"] = "enabled";
    CustomMetricContainerProjectOrderField["ProjectId"] = "projectId";
})(CustomMetricContainerProjectOrderField || (CustomMetricContainerProjectOrderField = {}));
export var CustomMetricContainerProjectSecretOrderField;
(function (CustomMetricContainerProjectSecretOrderField) {
    CustomMetricContainerProjectSecretOrderField["CustomMetricContainerId"] = "customMetricContainerId";
    CustomMetricContainerProjectSecretOrderField["Name"] = "name";
    CustomMetricContainerProjectSecretOrderField["ProjectId"] = "projectId";
})(CustomMetricContainerProjectSecretOrderField || (CustomMetricContainerProjectSecretOrderField = {}));
export var CustomMetricContainerRenderingResource;
(function (CustomMetricContainerRenderingResource) {
    CustomMetricContainerRenderingResource["Font"] = "Font";
    CustomMetricContainerRenderingResource["Image"] = "Image";
    CustomMetricContainerRenderingResource["TextTrack"] = "TextTrack";
})(CustomMetricContainerRenderingResource || (CustomMetricContainerRenderingResource = {}));
/** The type of source that the custom metric container is based on. */
export var CustomMetricContainerResourceType;
(function (CustomMetricContainerResourceType) {
    CustomMetricContainerResourceType["Document"] = "Document";
    CustomMetricContainerResourceType["Image"] = "Image";
    CustomMetricContainerResourceType["Script"] = "Script";
    CustomMetricContainerResourceType["Stylesheet"] = "Stylesheet";
})(CustomMetricContainerResourceType || (CustomMetricContainerResourceType = {}));
export var CustomMetricContainerScope;
(function (CustomMetricContainerScope) {
    CustomMetricContainerScope["Container"] = "Container";
    CustomMetricContainerScope["System"] = "System";
})(CustomMetricContainerScope || (CustomMetricContainerScope = {}));
export var CustomMetricContainerSecretOrderField;
(function (CustomMetricContainerSecretOrderField) {
    CustomMetricContainerSecretOrderField["CustomMetricContainerId"] = "customMetricContainerId";
    CustomMetricContainerSecretOrderField["Name"] = "name";
})(CustomMetricContainerSecretOrderField || (CustomMetricContainerSecretOrderField = {}));
export var CustomMetricContainerTableType;
(function (CustomMetricContainerTableType) {
    CustomMetricContainerTableType["Accessibility"] = "Accessibility";
    CustomMetricContainerTableType["AccessibilityOld"] = "AccessibilityOld";
    CustomMetricContainerTableType["Document"] = "Document";
    CustomMetricContainerTableType["DocumentOld"] = "DocumentOld";
    CustomMetricContainerTableType["ExternalCustom"] = "ExternalCustom";
    CustomMetricContainerTableType["Origin"] = "Origin";
    CustomMetricContainerTableType["SeoDocument"] = "SeoDocument";
    CustomMetricContainerTableType["SeoLink"] = "SeoLink";
    CustomMetricContainerTableType["SiteSpeedAudit"] = "SiteSpeedAudit";
    CustomMetricContainerTableType["SiteSpeedAuditItem"] = "SiteSpeedAuditItem";
    CustomMetricContainerTableType["SiteSpeedDocument"] = "SiteSpeedDocument";
    CustomMetricContainerTableType["SiteSpeedOrigin"] = "SiteSpeedOrigin";
})(CustomMetricContainerTableType || (CustomMetricContainerTableType = {}));
export var CustomMetricContainerVersionOrderField;
(function (CustomMetricContainerVersionOrderField) {
    CustomMetricContainerVersionOrderField["FailedAt"] = "failedAt";
    CustomMetricContainerVersionOrderField["Id"] = "id";
    CustomMetricContainerVersionOrderField["InputType"] = "inputType";
    CustomMetricContainerVersionOrderField["ProcessedAt"] = "processedAt";
    CustomMetricContainerVersionOrderField["ProcessingAt"] = "processingAt";
    CustomMetricContainerVersionOrderField["Scope"] = "scope";
})(CustomMetricContainerVersionOrderField || (CustomMetricContainerVersionOrderField = {}));
export var CustomMetricContainerVersionStatus;
(function (CustomMetricContainerVersionStatus) {
    CustomMetricContainerVersionStatus["Draft"] = "Draft";
    CustomMetricContainerVersionStatus["Errored"] = "Errored";
    CustomMetricContainerVersionStatus["Processed"] = "Processed";
    CustomMetricContainerVersionStatus["Processing"] = "Processing";
})(CustomMetricContainerVersionStatus || (CustomMetricContainerVersionStatus = {}));
export var CustomMetricType;
(function (CustomMetricType) {
    CustomMetricType["Boolean"] = "Boolean";
    CustomMetricType["Integer"] = "Integer";
    CustomMetricType["IntegerArray"] = "IntegerArray";
    CustomMetricType["Number"] = "Number";
    CustomMetricType["NumberArray"] = "NumberArray";
    CustomMetricType["String"] = "String";
    CustomMetricType["StringArray"] = "StringArray";
})(CustomMetricType || (CustomMetricType = {}));
export var CustomMetricUploadContainerOrderField;
(function (CustomMetricUploadContainerOrderField) {
    CustomMetricUploadContainerOrderField["Id"] = "id";
    CustomMetricUploadContainerOrderField["Name"] = "name";
})(CustomMetricUploadContainerOrderField || (CustomMetricUploadContainerOrderField = {}));
export var CustomReportTemplateOrderField;
(function (CustomReportTemplateOrderField) {
    CustomReportTemplateOrderField["Code"] = "code";
    CustomReportTemplateOrderField["CreatedAt"] = "createdAt";
    CustomReportTemplateOrderField["Name"] = "name";
    CustomReportTemplateOrderField["ReportTemplateCode"] = "reportTemplateCode";
    CustomReportTemplateOrderField["ReportTemplateQueryVersion"] = "reportTemplateQueryVersion";
    CustomReportTemplateOrderField["TotalSign"] = "totalSign";
    CustomReportTemplateOrderField["TotalWeight"] = "totalWeight";
    CustomReportTemplateOrderField["UpdatedAt"] = "updatedAt";
    CustomReportTemplateOrderField["Version"] = "version";
})(CustomReportTemplateOrderField || (CustomReportTemplateOrderField = {}));
export var CustomReportTemplateVersionOrderField;
(function (CustomReportTemplateVersionOrderField) {
    CustomReportTemplateVersionOrderField["CreatedAt"] = "createdAt";
    CustomReportTemplateVersionOrderField["ReportTemplateQueryVersion"] = "reportTemplateQueryVersion";
    CustomReportTemplateVersionOrderField["TotalSign"] = "totalSign";
    CustomReportTemplateVersionOrderField["TotalWeight"] = "totalWeight";
    CustomReportTemplateVersionOrderField["UpdatedAt"] = "updatedAt";
    CustomReportTemplateVersionOrderField["Version"] = "version";
})(CustomReportTemplateVersionOrderField || (CustomReportTemplateVersionOrderField = {}));
export var CustomTableOrderField;
(function (CustomTableOrderField) {
    CustomTableOrderField["CreatedAt"] = "createdAt";
    CustomTableOrderField["Type"] = "type";
    CustomTableOrderField["UpdatedAt"] = "updatedAt";
})(CustomTableOrderField || (CustomTableOrderField = {}));
export var CustomTableType;
(function (CustomTableType) {
    CustomTableType["HealthScores"] = "HealthScores";
    CustomTableType["MonitorProjects"] = "MonitorProjects";
})(CustomTableType || (CustomTableType = {}));
export var CustomViewOrderField;
(function (CustomViewOrderField) {
    CustomViewOrderField["AccountId"] = "accountId";
    CustomViewOrderField["Id"] = "id";
    CustomViewOrderField["ProjectId"] = "projectId";
    CustomViewOrderField["SegmentId"] = "segmentId";
})(CustomViewOrderField || (CustomViewOrderField = {}));
export var DatasourceCode;
(function (DatasourceCode) {
    DatasourceCode["CrawlAccessibilityIssueInstances"] = "CrawlAccessibilityIssueInstances";
    DatasourceCode["CrawlAccessibilityIssues"] = "CrawlAccessibilityIssues";
    DatasourceCode["CrawlDuplicateUrls"] = "CrawlDuplicateUrls";
    DatasourceCode["CrawlHreflangs"] = "CrawlHreflangs";
    DatasourceCode["CrawlLinkedDomains"] = "CrawlLinkedDomains";
    DatasourceCode["CrawlLinks"] = "CrawlLinks";
    DatasourceCode["CrawlOrigins"] = "CrawlOrigins";
    DatasourceCode["CrawlSearchQueries"] = "CrawlSearchQueries";
    DatasourceCode["CrawlSearchQueriesWithLandingPages"] = "CrawlSearchQueriesWithLandingPages";
    DatasourceCode["CrawlSiteSpeedAuditItems"] = "CrawlSiteSpeedAuditItems";
    DatasourceCode["CrawlSiteSpeedAuditOpportunities"] = "CrawlSiteSpeedAuditOpportunities";
    DatasourceCode["CrawlSiteSpeedAudits"] = "CrawlSiteSpeedAudits";
    /** @deprecated No longer in use. */
    DatasourceCode["CrawlSitemapDiscoveries"] = "CrawlSitemapDiscoveries";
    DatasourceCode["CrawlSitemaps"] = "CrawlSitemaps";
    DatasourceCode["CrawlUncrawledUrls"] = "CrawlUncrawledUrls";
    DatasourceCode["CrawlUniqueLinks"] = "CrawlUniqueLinks";
    DatasourceCode["CrawlUrls"] = "CrawlUrls";
    DatasourceCode["CrawlWebCrawlDepths"] = "CrawlWebCrawlDepths";
})(DatasourceCode || (DatasourceCode = {}));
export var DefaultCustomMetricContainerDataset;
(function (DefaultCustomMetricContainerDataset) {
    /** @deprecated No longer in use. */
    DefaultCustomMetricContainerDataset["Accessibility"] = "Accessibility";
})(DefaultCustomMetricContainerDataset || (DefaultCustomMetricContainerDataset = {}));
export var EmailAlertType;
(function (EmailAlertType) {
    EmailAlertType["Fail"] = "Fail";
    EmailAlertType["Pass"] = "Pass";
    EmailAlertType["Warning"] = "Warning";
})(EmailAlertType || (EmailAlertType = {}));
export var FeatureCostOrderField;
(function (FeatureCostOrderField) {
    FeatureCostOrderField["FeatureCode"] = "featureCode";
    FeatureCostOrderField["ProjectSetting"] = "projectSetting";
})(FeatureCostOrderField || (FeatureCostOrderField = {}));
export var FeatureFlagOrderField;
(function (FeatureFlagOrderField) {
    FeatureFlagOrderField["CreatedAt"] = "createdAt";
    FeatureFlagOrderField["Enabled"] = "enabled";
    FeatureFlagOrderField["Name"] = "name";
    FeatureFlagOrderField["UpdatedAt"] = "updatedAt";
})(FeatureFlagOrderField || (FeatureFlagOrderField = {}));
export var GoogleAnalytics4ConversionEventType;
(function (GoogleAnalytics4ConversionEventType) {
    GoogleAnalytics4ConversionEventType["Additional"] = "Additional";
    GoogleAnalytics4ConversionEventType["Primary"] = "Primary";
})(GoogleAnalytics4ConversionEventType || (GoogleAnalytics4ConversionEventType = {}));
export var GoogleAnalytics4SourceType;
(function (GoogleAnalytics4SourceType) {
    GoogleAnalytics4SourceType["All"] = "All";
    GoogleAnalytics4SourceType["BingOrganicSearchLandingPages"] = "BingOrganicSearchLandingPages";
    GoogleAnalytics4SourceType["GoogleOrganicSearchLandingPages"] = "GoogleOrganicSearchLandingPages";
    GoogleAnalytics4SourceType["OrganicSearchLandingPages"] = "OrganicSearchLandingPages";
})(GoogleAnalytics4SourceType || (GoogleAnalytics4SourceType = {}));
export var GoogleConnectionType;
(function (GoogleConnectionType) {
    GoogleConnectionType["ServiceAccount"] = "ServiceAccount";
    GoogleConnectionType["Web"] = "Web";
})(GoogleConnectionType || (GoogleConnectionType = {}));
export var GoogleSearchConsolePropertyOrderField;
(function (GoogleSearchConsolePropertyOrderField) {
    GoogleSearchConsolePropertyOrderField["CreatedAt"] = "createdAt";
    GoogleSearchConsolePropertyOrderField["SiteUrl"] = "siteUrl";
    GoogleSearchConsolePropertyOrderField["UpdatedAt"] = "updatedAt";
})(GoogleSearchConsolePropertyOrderField || (GoogleSearchConsolePropertyOrderField = {}));
export var GoogleSearchConsoleSearchType;
(function (GoogleSearchConsoleSearchType) {
    GoogleSearchConsoleSearchType["Image"] = "Image";
    GoogleSearchConsoleSearchType["Video"] = "Video";
    GoogleSearchConsoleSearchType["Web"] = "Web";
})(GoogleSearchConsoleSearchType || (GoogleSearchConsoleSearchType = {}));
export var HealthScoreTestOrderField;
(function (HealthScoreTestOrderField) {
    HealthScoreTestOrderField["AbsoluteThreshold"] = "absoluteThreshold";
    HealthScoreTestOrderField["AutomaticThresholdAcceptanceWhenTestResultIsBetter"] = "automaticThresholdAcceptanceWhenTestResultIsBetter";
    HealthScoreTestOrderField["AutomaticThresholdAcceptanceWhenTestResultIsWorse"] = "automaticThresholdAcceptanceWhenTestResultIsWorse";
    HealthScoreTestOrderField["CreatedAt"] = "createdAt";
    HealthScoreTestOrderField["RelativeThreshold"] = "relativeThreshold";
    HealthScoreTestOrderField["Severity"] = "severity";
    HealthScoreTestOrderField["ThresholdType"] = "thresholdType";
    HealthScoreTestOrderField["UpdatedAt"] = "updatedAt";
})(HealthScoreTestOrderField || (HealthScoreTestOrderField = {}));
export var HealthScoreTestResultOrderField;
(function (HealthScoreTestResultOrderField) {
    HealthScoreTestResultOrderField["AutomaticThresholdAcceptanceWhenTestResultIsBetter"] = "automaticThresholdAcceptanceWhenTestResultIsBetter";
    HealthScoreTestResultOrderField["AutomaticThresholdAcceptanceWhenTestResultIsWorse"] = "automaticThresholdAcceptanceWhenTestResultIsWorse";
    HealthScoreTestResultOrderField["CrawlId"] = "crawlId";
    HealthScoreTestResultOrderField["CreatedAt"] = "createdAt";
    HealthScoreTestResultOrderField["Id"] = "id";
    HealthScoreTestResultOrderField["Passed"] = "passed";
    HealthScoreTestResultOrderField["ReportCategoryCode"] = "reportCategoryCode";
    HealthScoreTestResultOrderField["Severity"] = "severity";
    HealthScoreTestResultOrderField["Status"] = "status";
    HealthScoreTestResultOrderField["SuggestedAbsoluteThresholdAcceptedAt"] = "suggestedAbsoluteThresholdAcceptedAt";
    HealthScoreTestResultOrderField["SuggestedAbsoluteThresholdRejectedAt"] = "suggestedAbsoluteThresholdRejectedAt";
})(HealthScoreTestResultOrderField || (HealthScoreTestResultOrderField = {}));
export var IndustryBenchmarkOrderField;
(function (IndustryBenchmarkOrderField) {
    IndustryBenchmarkOrderField["HealthScore"] = "healthScore";
    IndustryBenchmarkOrderField["IndustryCode"] = "industryCode";
    IndustryBenchmarkOrderField["ReportCategoryCode"] = "reportCategoryCode";
})(IndustryBenchmarkOrderField || (IndustryBenchmarkOrderField = {}));
export var IndustryOrderField;
(function (IndustryOrderField) {
    IndustryOrderField["Code"] = "code";
})(IndustryOrderField || (IndustryOrderField = {}));
export var LegacyTaskOrderField;
(function (LegacyTaskOrderField) {
    LegacyTaskOrderField["CreatedAt"] = "createdAt";
    LegacyTaskOrderField["DeadlineAt"] = "deadlineAt";
    LegacyTaskOrderField["Description"] = "description";
    LegacyTaskOrderField["DiscoveredAt"] = "discoveredAt";
    LegacyTaskOrderField["FixedAt"] = "fixedAt";
    LegacyTaskOrderField["Id"] = "id";
    LegacyTaskOrderField["Identified"] = "identified";
    LegacyTaskOrderField["Position"] = "position";
    LegacyTaskOrderField["Priority"] = "priority";
    LegacyTaskOrderField["Remaining"] = "remaining";
    LegacyTaskOrderField["Status"] = "status";
    LegacyTaskOrderField["Title"] = "title";
    LegacyTaskOrderField["UpdatedAt"] = "updatedAt";
})(LegacyTaskOrderField || (LegacyTaskOrderField = {}));
export var LegacyTaskPriority;
(function (LegacyTaskPriority) {
    LegacyTaskPriority["Critical"] = "Critical";
    LegacyTaskPriority["High"] = "High";
    LegacyTaskPriority["Low"] = "Low";
    LegacyTaskPriority["Medium"] = "Medium";
    LegacyTaskPriority["Note"] = "Note";
})(LegacyTaskPriority || (LegacyTaskPriority = {}));
export var LegacyTaskStatus;
(function (LegacyTaskStatus) {
    LegacyTaskStatus["Backlog"] = "Backlog";
    LegacyTaskStatus["Done"] = "Done";
    LegacyTaskStatus["InProgress"] = "InProgress";
    LegacyTaskStatus["Testing"] = "Testing";
    LegacyTaskStatus["ToDo"] = "ToDo";
})(LegacyTaskStatus || (LegacyTaskStatus = {}));
export var LocationCode;
(function (LocationCode) {
    LocationCode["Australia"] = "Australia";
    LocationCode["Austria"] = "Austria";
    LocationCode["Belgium"] = "Belgium";
    LocationCode["Brazil"] = "Brazil";
    LocationCode["Canada"] = "Canada";
    LocationCode["China"] = "China";
    LocationCode["Custom"] = "Custom";
    LocationCode["Default"] = "Default";
    LocationCode["Egypt"] = "Egypt";
    LocationCode["France"] = "France";
    LocationCode["Germany"] = "Germany";
    LocationCode["Global"] = "Global";
    LocationCode["GreatBritain"] = "GreatBritain";
    LocationCode["India"] = "India";
    LocationCode["Italy"] = "Italy";
    LocationCode["Japan"] = "Japan";
    LocationCode["Mexico"] = "Mexico";
    LocationCode["Netherlands"] = "Netherlands";
    LocationCode["Pakistan"] = "Pakistan";
    LocationCode["Poland"] = "Poland";
    LocationCode["Singapore"] = "Singapore";
    LocationCode["Spain"] = "Spain";
    LocationCode["Switzerland"] = "Switzerland";
    LocationCode["Turkey"] = "Turkey";
    LocationCode["UnitedStates"] = "UnitedStates";
    LocationCode["Uzbekistan"] = "Uzbekistan";
})(LocationCode || (LocationCode = {}));
export var LocationType;
(function (LocationType) {
    LocationType["Country"] = "Country";
    LocationType["CustomIp"] = "CustomIP";
    LocationType["DynamicIp"] = "DynamicIP";
    LocationType["Global"] = "Global";
    LocationType["StaticIp"] = "StaticIP";
})(LocationType || (LocationType = {}));
export var LogzioApiRegion;
(function (LogzioApiRegion) {
    LogzioApiRegion["AsiaPacificSydney"] = "AsiaPacificSydney";
    LogzioApiRegion["CanadaCentral"] = "CanadaCentral";
    LogzioApiRegion["EuropeFrankfurt"] = "EuropeFrankfurt";
    LogzioApiRegion["EuropeLondon"] = "EuropeLondon";
    LogzioApiRegion["UsEastNorthernVirginia"] = "USEastNorthernVirginia";
    LogzioApiRegion["WestEuropeNetherlands"] = "WestEuropeNetherlands";
    LogzioApiRegion["WestUs2Washington"] = "WestUS2Washington";
})(LogzioApiRegion || (LogzioApiRegion = {}));
export var LogzioConnectionOrderField;
(function (LogzioConnectionOrderField) {
    LogzioConnectionOrderField["CreatedAt"] = "createdAt";
    LogzioConnectionOrderField["Label"] = "label";
    LogzioConnectionOrderField["UpdatedAt"] = "updatedAt";
})(LogzioConnectionOrderField || (LogzioConnectionOrderField = {}));
export var LogzioProjectQueryOrderField;
(function (LogzioProjectQueryOrderField) {
    LogzioProjectQueryOrderField["CreatedAt"] = "createdAt";
    LogzioProjectQueryOrderField["DateRange"] = "dateRange";
    LogzioProjectQueryOrderField["Enabled"] = "enabled";
    LogzioProjectQueryOrderField["MaxRows"] = "maxRows";
    LogzioProjectQueryOrderField["UpdatedAt"] = "updatedAt";
    LogzioProjectQueryOrderField["UseLastCrawlDate"] = "useLastCrawlDate";
})(LogzioProjectQueryOrderField || (LogzioProjectQueryOrderField = {}));
export var MetricOrderField;
(function (MetricOrderField) {
    MetricOrderField["DatasourceCode"] = "datasourceCode";
    MetricOrderField["Internal"] = "internal";
    MetricOrderField["Type"] = "type";
})(MetricOrderField || (MetricOrderField = {}));
export var MetricType;
(function (MetricType) {
    MetricType["Array"] = "Array";
    MetricType["Bit"] = "Bit";
    MetricType["Boolean"] = "Boolean";
    MetricType["Date"] = "Date";
    MetricType["Decimal"] = "Decimal";
    MetricType["Integer"] = "Integer";
    MetricType["Json"] = "JSON";
    MetricType["String"] = "String";
    MetricType["StringifiedJson"] = "StringifiedJSON";
})(MetricType || (MetricType = {}));
export var MetricsServerId;
(function (MetricsServerId) {
    MetricsServerId["ElasticSearch7Cluster1"] = "ElasticSearch7Cluster1";
})(MetricsServerId || (MetricsServerId = {}));
export var ModuleCode;
(function (ModuleCode) {
    ModuleCode["Accessibility"] = "Accessibility";
    ModuleCode["Basic"] = "Basic";
    ModuleCode["Seo"] = "SEO";
    ModuleCode["SiteSpeed"] = "SiteSpeed";
})(ModuleCode || (ModuleCode = {}));
/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "ASC";
    OrderDirection["Desc"] = "DESC";
})(OrderDirection || (OrderDirection = {}));
export var PauseReason;
(function (PauseReason) {
    PauseReason["CreditLimit"] = "CreditLimit";
    PauseReason["CustomExtractionTimeout"] = "CustomExtractionTimeout";
    PauseReason["FailureRate"] = "FailureRate";
    PauseReason["LevelLimit"] = "LevelLimit";
    PauseReason["Manual"] = "Manual";
    PauseReason["PageGroupingTimeout"] = "PageGroupingTimeout";
    PauseReason["UrlLimit"] = "UrlLimit";
    PauseReason["UrlsExcludedTimeout"] = "UrlsExcludedTimeout";
    PauseReason["UrlsIncludedTimeout"] = "UrlsIncludedTimeout";
})(PauseReason || (PauseReason = {}));
export var PermissionCode;
(function (PermissionCode) {
    PermissionCode["Admin"] = "Admin";
    /** @deprecated No longer in use. */
    PermissionCode["AdminGrant"] = "AdminGrant";
    /** @deprecated No longer in use. */
    PermissionCode["Beta"] = "Beta";
    /** @deprecated No longer in use. */
    PermissionCode["Dev"] = "Dev";
    PermissionCode["SsoClient"] = "SsoClient";
})(PermissionCode || (PermissionCode = {}));
export var ProjectOrderField;
(function (ProjectOrderField) {
    ProjectOrderField["AllPagesTotal"] = "allPagesTotal";
    ProjectOrderField["ClonedAt"] = "clonedAt";
    /** @deprecated Renamed to maximumCrawlRate. */
    ProjectOrderField["CrawlRate"] = "crawlRate";
    ProjectOrderField["CrawlUrlsTotal"] = "crawlUrlsTotal";
    ProjectOrderField["CrawlingAt"] = "crawlingAt";
    ProjectOrderField["CrawlsTotalCount"] = "crawlsTotalCount";
    ProjectOrderField["CreatedAt"] = "createdAt";
    ProjectOrderField["DuplicatePrecision"] = "duplicatePrecision";
    ProjectOrderField["EmptyPageThreshold"] = "emptyPageThreshold";
    ProjectOrderField["EnableKeyValueStore"] = "enableKeyValueStore";
    ProjectOrderField["FinishedAt"] = "finishedAt";
    ProjectOrderField["FlattenIframes"] = "flattenIframes";
    ProjectOrderField["FlattenShadowDom"] = "flattenShadowDom";
    ProjectOrderField["HealthScoreTestsTotalCount"] = "healthScoreTestsTotalCount";
    /** @deprecated Renamed to logSummaryRequestsHigh. */
    ProjectOrderField["HighLogSummaryRequests"] = "highLogSummaryRequests";
    ProjectOrderField["Id"] = "id";
    ProjectOrderField["IncludeBestPractices"] = "includeBestPractices";
    ProjectOrderField["IndustryCode"] = "industryCode";
    ProjectOrderField["IsTestSuite"] = "isTestSuite";
    ProjectOrderField["LastCrawlCrawlingAt"] = "lastCrawlCrawlingAt";
    ProjectOrderField["LastCrawlStatus"] = "lastCrawlStatus";
    ProjectOrderField["LegacyTasksTotalCount"] = "legacyTasksTotalCount";
    /** @deprecated Renamed to limitUrlsMax. */
    ProjectOrderField["LimitPagesMax"] = "limitPagesMax";
    ProjectOrderField["LimitUrlsMax"] = "limitUrlsMax";
    ProjectOrderField["LogSummaryRequestsHigh"] = "logSummaryRequestsHigh";
    ProjectOrderField["LogSummaryRequestsLow"] = "logSummaryRequestsLow";
    /** @deprecated Renamed to logSummaryRequestsLow. */
    ProjectOrderField["LowLogSummaryRequests"] = "lowLogSummaryRequests";
    ProjectOrderField["MaxBodyContentLength"] = "maxBodyContentLength";
    /** @deprecated Renamed to maxBodyContentLength. */
    ProjectOrderField["MaxContentSize"] = "maxContentSize";
    ProjectOrderField["MaxDescriptionLength"] = "maxDescriptionLength";
    ProjectOrderField["MaxExternalLinks"] = "maxExternalLinks";
    ProjectOrderField["MaxHtmlSize"] = "maxHtmlSize";
    ProjectOrderField["MaxLinks"] = "maxLinks";
    ProjectOrderField["MaxLoadTime"] = "maxLoadTime";
    ProjectOrderField["MaxRedirections"] = "maxRedirections";
    ProjectOrderField["MaxTitleWidth"] = "maxTitleWidth";
    ProjectOrderField["MaxUrlLength"] = "maxUrlLength";
    ProjectOrderField["MaximumCrawlRate"] = "maximumCrawlRate";
    ProjectOrderField["MinContentRatio"] = "minContentRatio";
    ProjectOrderField["MinDescriptionLength"] = "minDescriptionLength";
    ProjectOrderField["MinTitleLength"] = "minTitleLength";
    ProjectOrderField["Name"] = "name";
    ProjectOrderField["PrimaryDomain"] = "primaryDomain";
    ProjectOrderField["RenderTimeout"] = "renderTimeout";
    ProjectOrderField["RenderWithImages"] = "renderWithImages";
    ProjectOrderField["RendererBlockAds"] = "rendererBlockAds";
    ProjectOrderField["RendererBlockAnalytics"] = "rendererBlockAnalytics";
    ProjectOrderField["RendererJsString"] = "rendererJsString";
    ProjectOrderField["RenderingRobotsCheckMode"] = "renderingRobotsCheckMode";
    ProjectOrderField["RobotsOverwrite"] = "robotsOverwrite";
    ProjectOrderField["SchedulesTotalCount"] = "schedulesTotalCount";
    ProjectOrderField["SegmentsTotalCount"] = "segmentsTotalCount";
    ProjectOrderField["SelectedWcagLevel"] = "selectedWcagLevel";
    ProjectOrderField["SelectedWcagVersion"] = "selectedWcagVersion";
    /** @deprecated Renamed to primaryDomain. */
    ProjectOrderField["SitePrimary"] = "sitePrimary";
    /** @deprecated Renamed to testSiteDomain. */
    ProjectOrderField["SiteTest"] = "siteTest";
    ProjectOrderField["TargetMaxUncrawledUrlsCount"] = "targetMaxUncrawledUrlsCount";
    ProjectOrderField["TestSiteDomain"] = "testSiteDomain";
    ProjectOrderField["TestsTotalCount"] = "testsTotalCount";
    ProjectOrderField["ThinPageThreshold"] = "thinPageThreshold";
    ProjectOrderField["TotalSteps"] = "totalSteps";
    ProjectOrderField["UpdatedAt"] = "updatedAt";
    ProjectOrderField["UseRenderer"] = "useRenderer";
    ProjectOrderField["UseRobotsOverwrite"] = "useRobotsOverwrite";
})(ProjectOrderField || (ProjectOrderField = {}));
export var ProjectUploadType;
(function (ProjectUploadType) {
    ProjectUploadType["AdobeAnalyticsCsv"] = "AdobeAnalyticsCSV";
    ProjectUploadType["AdwordsFinalUrlCsv"] = "AdwordsFinalUrlCSV";
    ProjectUploadType["AhrefsBacklinksCsv"] = "AhrefsBacklinksCSV";
    ProjectUploadType["AhrefsBacklinksCsv2"] = "AhrefsBacklinksCSV2";
    ProjectUploadType["AnalyticsCsv"] = "AnalyticsCSV";
    ProjectUploadType["BacklinksCsv"] = "BacklinksCSV";
    ProjectUploadType["Custom"] = "Custom";
    ProjectUploadType["CustomMetricsCsv"] = "CustomMetricsCsv";
    ProjectUploadType["GenericBacklinksCsv"] = "GenericBacklinksCSV";
    ProjectUploadType["GenericLogfileCsv"] = "GenericLogfileCSV";
    ProjectUploadType["GoogleAnalyticsCsv"] = "GoogleAnalyticsCSV";
    ProjectUploadType["ListTxt"] = "ListTxt";
    ProjectUploadType["LogzioLogfileDesktopCsv"] = "LogzioLogfileDesktopCSV";
    ProjectUploadType["LogzioLogfileMobileCsv"] = "LogzioLogfileMobileCSV";
    ProjectUploadType["MajesticBacklinksCsv"] = "MajesticBacklinksCSV";
    ProjectUploadType["MajesticBacklinksCsv2"] = "MajesticBacklinksCSV2";
    ProjectUploadType["OseBacklinksCsv"] = "OSEBacklinksCSV";
    ProjectUploadType["ReducedAdobeAnalyticsCsv"] = "ReducedAdobeAnalyticsCSV";
    ProjectUploadType["ScreamingFrogLogfileBaiduCsv"] = "ScreamingFrogLogfileBaiduCSV";
    ProjectUploadType["ScreamingFrogLogfileBingbotCsv"] = "ScreamingFrogLogfileBingbotCSV";
    ProjectUploadType["ScreamingFrogLogfileGooglebotCsv"] = "ScreamingFrogLogfileGooglebotCSV";
    ProjectUploadType["ScreamingFrogLogfileGooglebotOnlyCsv"] = "ScreamingFrogLogfileGooglebotOnlyCSV";
    ProjectUploadType["ScreamingFrogLogfileGooglebotOnlyCsv2"] = "ScreamingFrogLogfileGooglebotOnlyCSV2";
    ProjectUploadType["ScreamingFrogLogfileYandexCsv"] = "ScreamingFrogLogfileYandexCSV";
    ProjectUploadType["Sitemap"] = "Sitemap";
    ProjectUploadType["SplunkLogfileBingCsv"] = "SplunkLogfileBingCSV";
    ProjectUploadType["SplunkLogfileDesktopCsv"] = "SplunkLogfileDesktopCSV";
    ProjectUploadType["SplunkLogfileGoogleCsv"] = "SplunkLogfileGoogleCSV";
    ProjectUploadType["SplunkLogfileMobileCsv"] = "SplunkLogfileMobileCSV";
})(ProjectUploadType || (ProjectUploadType = {}));
export var ProxyOrderField;
(function (ProxyOrderField) {
    ProxyOrderField["Enabled"] = "enabled";
    ProxyOrderField["LocationCode"] = "locationCode";
    ProxyOrderField["Url"] = "url";
})(ProxyOrderField || (ProxyOrderField = {}));
export var RedirectExclusionReason;
(function (RedirectExclusionReason) {
    RedirectExclusionReason["Disallowed"] = "Disallowed";
    RedirectExclusionReason["ExcludedUrl"] = "ExcludedUrl";
    RedirectExclusionReason["External"] = "External";
    RedirectExclusionReason["Internal"] = "Internal";
    RedirectExclusionReason["MalformedUrl"] = "MalformedUrl";
    RedirectExclusionReason["NonHtmlFile"] = "NonHtmlFile";
    RedirectExclusionReason["NotIncludedUrl"] = "NotIncludedUrl";
    RedirectExclusionReason["Other"] = "Other";
    RedirectExclusionReason["PageGroupingRestricted"] = "PageGroupingRestricted";
    RedirectExclusionReason["TooManyRedirects"] = "TooManyRedirects";
})(RedirectExclusionReason || (RedirectExclusionReason = {}));
export var RenderingRobotsCheckMode;
(function (RenderingRobotsCheckMode) {
    RenderingRobotsCheckMode["BlockDisallowed"] = "BlockDisallowed";
    RenderingRobotsCheckMode["RequestDisallowed"] = "RequestDisallowed";
})(RenderingRobotsCheckMode || (RenderingRobotsCheckMode = {}));
export var ReportCategoryOrderField;
(function (ReportCategoryOrderField) {
    ReportCategoryOrderField["Code"] = "code";
    ReportCategoryOrderField["HealthScore"] = "healthScore";
    ReportCategoryOrderField["ParentCode"] = "parentCode";
    ReportCategoryOrderField["Position"] = "position";
})(ReportCategoryOrderField || (ReportCategoryOrderField = {}));
export var ReportDownloadOrderField;
(function (ReportDownloadOrderField) {
    ReportDownloadOrderField["CreatedAt"] = "createdAt";
    ReportDownloadOrderField["TotalRows"] = "totalRows";
    ReportDownloadOrderField["UpdatedAt"] = "updatedAt";
})(ReportDownloadOrderField || (ReportDownloadOrderField = {}));
export var ReportDownloadOutputType;
(function (ReportDownloadOutputType) {
    ReportDownloadOutputType["CompactCsvZip"] = "CompactCsvZip";
    ReportDownloadOutputType["CsvGzip"] = "CsvGzip";
    ReportDownloadOutputType["CsvTarGz"] = "CsvTarGz";
    ReportDownloadOutputType["CsvZip"] = "CsvZip";
    ReportDownloadOutputType["XmlZip"] = "XmlZip";
})(ReportDownloadOutputType || (ReportDownloadOutputType = {}));
export var ReportDownloadStatus;
(function (ReportDownloadStatus) {
    ReportDownloadStatus["Draft"] = "Draft";
    ReportDownloadStatus["Generated"] = "Generated";
    ReportDownloadStatus["Generating"] = "Generating";
})(ReportDownloadStatus || (ReportDownloadStatus = {}));
export var ReportOrderField;
(function (ReportOrderField) {
    ReportOrderField["AggregateValue"] = "aggregateValue";
    ReportOrderField["Change"] = "change";
    /** @deprecated Reports are created when Crawl finalizes */
    ReportOrderField["CreatedAt"] = "createdAt";
    ReportOrderField["DatasourceCode"] = "datasourceCode";
    ReportOrderField["DatasourceCodeEnum"] = "datasourceCodeEnum";
    /** @deprecated unused, admin only */
    ReportOrderField["GenerationTime"] = "generationTime";
    ReportOrderField["QueryVersion"] = "queryVersion";
    ReportOrderField["ReportTemplateCode"] = "reportTemplateCode";
    /** @deprecated Use reportTypeCodeEnum instead. */
    ReportOrderField["ReportTypeCode"] = "reportTypeCode";
    ReportOrderField["ReportTypeCodeEnum"] = "reportTypeCodeEnum";
    ReportOrderField["TotalRows"] = "totalRows";
    ReportOrderField["TotalWeight"] = "totalWeight";
})(ReportOrderField || (ReportOrderField = {}));
export var ReportTemplateOrderField;
(function (ReportTemplateOrderField) {
    ReportTemplateOrderField["BestPractice"] = "bestPractice";
    ReportTemplateOrderField["Beta"] = "beta";
    ReportTemplateOrderField["ChangeSign"] = "changeSign";
    ReportTemplateOrderField["ChangeWeight"] = "changeWeight";
    ReportTemplateOrderField["Code"] = "code";
    ReportTemplateOrderField["ContributesToHealthScores"] = "contributesToHealthScores";
    ReportTemplateOrderField["CreatedAt"] = "createdAt";
    /** @deprecated Use datasourceCodeEnum instead. */
    ReportTemplateOrderField["DatasourceCode"] = "datasourceCode";
    ReportTemplateOrderField["DatasourceCodeEnum"] = "datasourceCodeEnum";
    ReportTemplateOrderField["DeletedAt"] = "deletedAt";
    ReportTemplateOrderField["DeprecatedAt"] = "deprecatedAt";
    ReportTemplateOrderField["HealthScoreParentReportTemplateCode"] = "healthScoreParentReportTemplateCode";
    ReportTemplateOrderField["Id"] = "id";
    ReportTemplateOrderField["Name"] = "name";
    ReportTemplateOrderField["Position"] = "position";
    ReportTemplateOrderField["PrimaryReportCategoryCode"] = "primaryReportCategoryCode";
    ReportTemplateOrderField["ScopeReportTemplateCode"] = "scopeReportTemplateCode";
    ReportTemplateOrderField["TotalSign"] = "totalSign";
    ReportTemplateOrderField["TotalWeight"] = "totalWeight";
    ReportTemplateOrderField["UpdatedAt"] = "updatedAt";
    ReportTemplateOrderField["WcagLevel"] = "wcagLevel";
    ReportTemplateOrderField["WcagVersion"] = "wcagVersion";
})(ReportTemplateOrderField || (ReportTemplateOrderField = {}));
export var ReportTemplateOverrideOrderField;
(function (ReportTemplateOverrideOrderField) {
    ReportTemplateOverrideOrderField["CreatedAt"] = "createdAt";
    ReportTemplateOverrideOrderField["ReportTemplateCode"] = "reportTemplateCode";
    ReportTemplateOverrideOrderField["TotalSign"] = "totalSign";
    ReportTemplateOverrideOrderField["TotalWeight"] = "totalWeight";
    ReportTemplateOverrideOrderField["UpdatedAt"] = "updatedAt";
})(ReportTemplateOverrideOrderField || (ReportTemplateOverrideOrderField = {}));
export var ReportTemplateUnit;
(function (ReportTemplateUnit) {
    ReportTemplateUnit["Instances"] = "Instances";
    ReportTemplateUnit["Issues"] = "Issues";
    ReportTemplateUnit["Items"] = "Items";
    ReportTemplateUnit["Links"] = "Links";
    ReportTemplateUnit["Opportunities"] = "Opportunities";
    ReportTemplateUnit["Sets"] = "Sets";
    ReportTemplateUnit["Sitemaps"] = "Sitemaps";
    ReportTemplateUnit["UrLs"] = "URLs";
})(ReportTemplateUnit || (ReportTemplateUnit = {}));
export var ReportTemplateVersionOrderField;
(function (ReportTemplateVersionOrderField) {
    ReportTemplateVersionOrderField["Code"] = "code";
    ReportTemplateVersionOrderField["ContributesToHealthScores"] = "contributesToHealthScores";
    ReportTemplateVersionOrderField["CreatedAt"] = "createdAt";
    ReportTemplateVersionOrderField["DeprecatedAt"] = "deprecatedAt";
    ReportTemplateVersionOrderField["QueryVersion"] = "queryVersion";
    ReportTemplateVersionOrderField["UpdatedAt"] = "updatedAt";
})(ReportTemplateVersionOrderField || (ReportTemplateVersionOrderField = {}));
export var ReportTypeCode;
(function (ReportTypeCode) {
    ReportTypeCode["Added"] = "Added";
    ReportTypeCode["Basic"] = "Basic";
    ReportTypeCode["Missing"] = "Missing";
    ReportTypeCode["Removed"] = "Removed";
})(ReportTypeCode || (ReportTypeCode = {}));
export var ResolvedHttpStatusCode;
(function (ResolvedHttpStatusCode) {
    ResolvedHttpStatusCode["Permanent"] = "Permanent";
    ResolvedHttpStatusCode["Temporary"] = "Temporary";
})(ResolvedHttpStatusCode || (ResolvedHttpStatusCode = {}));
export var RoleCode;
(function (RoleCode) {
    RoleCode["Admin"] = "Admin";
    RoleCode["Editor"] = "Editor";
    RoleCode["Viewer"] = "Viewer";
})(RoleCode || (RoleCode = {}));
export var ScheduleFrequencyCode;
(function (ScheduleFrequencyCode) {
    ScheduleFrequencyCode["Bimonthly"] = "Bimonthly";
    ScheduleFrequencyCode["Daily"] = "Daily";
    ScheduleFrequencyCode["Fortnightly"] = "Fortnightly";
    ScheduleFrequencyCode["Hourly"] = "Hourly";
    ScheduleFrequencyCode["MondayFriday"] = "MondayFriday";
    ScheduleFrequencyCode["Monthly"] = "Monthly";
    ScheduleFrequencyCode["OneTime"] = "OneTime";
    ScheduleFrequencyCode["Quarterly"] = "Quarterly";
    ScheduleFrequencyCode["Weekly"] = "Weekly";
})(ScheduleFrequencyCode || (ScheduleFrequencyCode = {}));
export var ScheduleOrderField;
(function (ScheduleOrderField) {
    ScheduleOrderField["CreatedAt"] = "createdAt";
    ScheduleOrderField["Id"] = "id";
    ScheduleOrderField["LatestRunTime"] = "latestRunTime";
    ScheduleOrderField["NextRunTime"] = "nextRunTime";
    ScheduleOrderField["UpdatedAt"] = "updatedAt";
})(ScheduleOrderField || (ScheduleOrderField = {}));
export var SegmentOrderField;
(function (SegmentOrderField) {
    SegmentOrderField["CreatedAt"] = "createdAt";
    SegmentOrderField["Name"] = "name";
    SegmentOrderField["UpdatedAt"] = "updatedAt";
})(SegmentOrderField || (SegmentOrderField = {}));
export var SegmentedProjectOrderField;
(function (SegmentedProjectOrderField) {
    SegmentedProjectOrderField["ProjectFinishedAt"] = "projectFinishedAt";
    SegmentedProjectOrderField["ProjectName"] = "projectName";
    SegmentedProjectOrderField["ProjectPrimaryDomain"] = "projectPrimaryDomain";
    SegmentedProjectOrderField["SegmentName"] = "segmentName";
})(SegmentedProjectOrderField || (SegmentedProjectOrderField = {}));
export var SerpentCrawlerQueryGraphType;
(function (SerpentCrawlerQueryGraphType) {
    SerpentCrawlerQueryGraphType["Basic"] = "Basic";
    SerpentCrawlerQueryGraphType["Mapped"] = "Mapped";
    SerpentCrawlerQueryGraphType["Modular"] = "Modular";
})(SerpentCrawlerQueryGraphType || (SerpentCrawlerQueryGraphType = {}));
export var Severity;
(function (Severity) {
    Severity["Fail"] = "Fail";
    Severity["Warning"] = "Warning";
})(Severity || (Severity = {}));
export var SinglePageRequesterRequestOrderField;
(function (SinglePageRequesterRequestOrderField) {
    SinglePageRequesterRequestOrderField["CreatedAt"] = "createdAt";
    SinglePageRequesterRequestOrderField["FailedAt"] = "failedAt";
    SinglePageRequesterRequestOrderField["FinishedAt"] = "finishedAt";
    SinglePageRequesterRequestOrderField["Id"] = "id";
    SinglePageRequesterRequestOrderField["RequestId"] = "requestId";
    SinglePageRequesterRequestOrderField["StartedAt"] = "startedAt";
    SinglePageRequesterRequestOrderField["Status"] = "status";
    SinglePageRequesterRequestOrderField["UpdatedAt"] = "updatedAt";
    SinglePageRequesterRequestOrderField["Url"] = "url";
})(SinglePageRequesterRequestOrderField || (SinglePageRequesterRequestOrderField = {}));
export var SinglePageRequesterRequestStatus;
(function (SinglePageRequesterRequestStatus) {
    SinglePageRequesterRequestStatus["Failed"] = "Failed";
    SinglePageRequesterRequestStatus["Finished"] = "Finished";
    SinglePageRequesterRequestStatus["Queued"] = "Queued";
    SinglePageRequesterRequestStatus["Started"] = "Started";
})(SinglePageRequesterRequestStatus || (SinglePageRequesterRequestStatus = {}));
export var SitemapStatus;
(function (SitemapStatus) {
    SitemapStatus["Malformed"] = "Malformed";
    SitemapStatus["Valid"] = "Valid";
})(SitemapStatus || (SitemapStatus = {}));
export var SitemapType;
(function (SitemapType) {
    SitemapType["Custom"] = "Custom";
    SitemapType["Discovered"] = "Discovered";
})(SitemapType || (SitemapType = {}));
export var SitespeedAuditResultType;
(function (SitespeedAuditResultType) {
    SitespeedAuditResultType["Failed"] = "Failed";
    SitespeedAuditResultType["NeedsReview"] = "NeedsReview";
    SitespeedAuditResultType["Passed"] = "Passed";
    SitespeedAuditResultType["Warning"] = "Warning";
})(SitespeedAuditResultType || (SitespeedAuditResultType = {}));
export var SlackAlertType;
(function (SlackAlertType) {
    SlackAlertType["Fail"] = "Fail";
    SlackAlertType["Pass"] = "Pass";
    SlackAlertType["Warning"] = "Warning";
})(SlackAlertType || (SlackAlertType = {}));
export var SlackWebhookOrderField;
(function (SlackWebhookOrderField) {
    SlackWebhookOrderField["CreatedAt"] = "createdAt";
})(SlackWebhookOrderField || (SlackWebhookOrderField = {}));
export var SplunkConnectionOrderField;
(function (SplunkConnectionOrderField) {
    SplunkConnectionOrderField["CreatedAt"] = "createdAt";
    SplunkConnectionOrderField["UpdatedAt"] = "updatedAt";
    SplunkConnectionOrderField["Url"] = "url";
    SplunkConnectionOrderField["Username"] = "username";
})(SplunkConnectionOrderField || (SplunkConnectionOrderField = {}));
export var SplunkProjectQueryOrderField;
(function (SplunkProjectQueryOrderField) {
    SplunkProjectQueryOrderField["CreatedAt"] = "createdAt";
    SplunkProjectQueryOrderField["DateRange"] = "dateRange";
    SplunkProjectQueryOrderField["Enabled"] = "enabled";
    SplunkProjectQueryOrderField["UpdatedAt"] = "updatedAt";
    SplunkProjectQueryOrderField["UseLastCrawlDate"] = "useLastCrawlDate";
})(SplunkProjectQueryOrderField || (SplunkProjectQueryOrderField = {}));
export var SplunkProxyCode;
(function (SplunkProxyCode) {
    SplunkProxyCode["Custom"] = "Custom";
})(SplunkProxyCode || (SplunkProxyCode = {}));
export var SystemSettingType;
(function (SystemSettingType) {
    SystemSettingType["Date"] = "Date";
    SystemSettingType["Int"] = "Int";
    SystemSettingType["String"] = "String";
})(SystemSettingType || (SystemSettingType = {}));
export var TestAutoThresholdAcceptance;
(function (TestAutoThresholdAcceptance) {
    TestAutoThresholdAcceptance["AutoAcceptAbsolute"] = "AutoAcceptAbsolute";
    TestAutoThresholdAcceptance["None"] = "None";
    TestAutoThresholdAcceptance["Suggest"] = "Suggest";
})(TestAutoThresholdAcceptance || (TestAutoThresholdAcceptance = {}));
export var TestOrderField;
(function (TestOrderField) {
    TestOrderField["AbsoluteThreshold"] = "absoluteThreshold";
    TestOrderField["AutomaticThresholdAcceptanceWhenTestResultIsBetter"] = "automaticThresholdAcceptanceWhenTestResultIsBetter";
    TestOrderField["AutomaticThresholdAcceptanceWhenTestResultIsWorse"] = "automaticThresholdAcceptanceWhenTestResultIsWorse";
    TestOrderField["CreatedAt"] = "createdAt";
    TestOrderField["RelativeThreshold"] = "relativeThreshold";
    TestOrderField["Severity"] = "severity";
    TestOrderField["ThresholdType"] = "thresholdType";
    TestOrderField["UpdatedAt"] = "updatedAt";
})(TestOrderField || (TestOrderField = {}));
export var TestResultOrderField;
(function (TestResultOrderField) {
    TestResultOrderField["AutomaticThresholdAcceptanceWhenTestResultIsBetter"] = "automaticThresholdAcceptanceWhenTestResultIsBetter";
    TestResultOrderField["AutomaticThresholdAcceptanceWhenTestResultIsWorse"] = "automaticThresholdAcceptanceWhenTestResultIsWorse";
    TestResultOrderField["CrawlId"] = "crawlId";
    TestResultOrderField["CreatedAt"] = "createdAt";
    TestResultOrderField["Id"] = "id";
    TestResultOrderField["Passed"] = "passed";
    TestResultOrderField["ReportTemplateCode"] = "reportTemplateCode";
    TestResultOrderField["Severity"] = "severity";
    TestResultOrderField["Status"] = "status";
    TestResultOrderField["SuggestedAbsoluteThresholdAcceptedAt"] = "suggestedAbsoluteThresholdAcceptedAt";
    TestResultOrderField["SuggestedAbsoluteThresholdRejectedAt"] = "suggestedAbsoluteThresholdRejectedAt";
})(TestResultOrderField || (TestResultOrderField = {}));
export var TestResultStatus;
(function (TestResultStatus) {
    TestResultStatus["Done"] = "Done";
    TestResultStatus["Read"] = "Read";
    TestResultStatus["Unresolved"] = "Unresolved";
    TestResultStatus["WorkingOnIt"] = "WorkingOnIt";
})(TestResultStatus || (TestResultStatus = {}));
export var TestSuiteCrawlType;
(function (TestSuiteCrawlType) {
    TestSuiteCrawlType["Backlinks"] = "Backlinks";
    TestSuiteCrawlType["GoogleAnalytics"] = "GoogleAnalytics";
    TestSuiteCrawlType["GoogleSearchConsole"] = "GoogleSearchConsole";
    TestSuiteCrawlType["List"] = "List";
    TestSuiteCrawlType["LogSummary"] = "LogSummary";
    TestSuiteCrawlType["Sitemap"] = "Sitemap";
    TestSuiteCrawlType["Task"] = "Task";
    TestSuiteCrawlType["Web"] = "Web";
})(TestSuiteCrawlType || (TestSuiteCrawlType = {}));
export var TestSuiteEmailAlertOrderField;
(function (TestSuiteEmailAlertOrderField) {
    TestSuiteEmailAlertOrderField["CreatedAt"] = "createdAt";
})(TestSuiteEmailAlertOrderField || (TestSuiteEmailAlertOrderField = {}));
export var TestSuiteLocationCode;
(function (TestSuiteLocationCode) {
    TestSuiteLocationCode["Custom"] = "Custom";
    TestSuiteLocationCode["Default"] = "Default";
})(TestSuiteLocationCode || (TestSuiteLocationCode = {}));
export var TestSuiteOrderField;
(function (TestSuiteOrderField) {
    TestSuiteOrderField["AllPagesTotal"] = "allPagesTotal";
    TestSuiteOrderField["ClonedAt"] = "clonedAt";
    /** @deprecated Renamed to maximumCrawlRate. */
    TestSuiteOrderField["CrawlRate"] = "crawlRate";
    TestSuiteOrderField["CrawlUrlsTotal"] = "crawlUrlsTotal";
    TestSuiteOrderField["CrawlingAt"] = "crawlingAt";
    TestSuiteOrderField["CrawlsTotalCount"] = "crawlsTotalCount";
    TestSuiteOrderField["CreatedAt"] = "createdAt";
    TestSuiteOrderField["DuplicatePrecision"] = "duplicatePrecision";
    TestSuiteOrderField["EmptyPageThreshold"] = "emptyPageThreshold";
    TestSuiteOrderField["EnableKeyValueStore"] = "enableKeyValueStore";
    TestSuiteOrderField["FinishedAt"] = "finishedAt";
    TestSuiteOrderField["FlattenIframes"] = "flattenIframes";
    TestSuiteOrderField["FlattenShadowDom"] = "flattenShadowDom";
    TestSuiteOrderField["HealthScoreTestsTotalCount"] = "healthScoreTestsTotalCount";
    /** @deprecated Renamed to logSummaryRequestsHigh. */
    TestSuiteOrderField["HighLogSummaryRequests"] = "highLogSummaryRequests";
    TestSuiteOrderField["Id"] = "id";
    TestSuiteOrderField["IncludeBestPractices"] = "includeBestPractices";
    TestSuiteOrderField["IndustryCode"] = "industryCode";
    TestSuiteOrderField["IsTestSuite"] = "isTestSuite";
    TestSuiteOrderField["LastCrawlCrawlingAt"] = "lastCrawlCrawlingAt";
    TestSuiteOrderField["LastCrawlStatus"] = "lastCrawlStatus";
    TestSuiteOrderField["LegacyTasksTotalCount"] = "legacyTasksTotalCount";
    /** @deprecated Renamed to limitUrlsMax. */
    TestSuiteOrderField["LimitPagesMax"] = "limitPagesMax";
    TestSuiteOrderField["LimitUrlsMax"] = "limitUrlsMax";
    TestSuiteOrderField["LogSummaryRequestsHigh"] = "logSummaryRequestsHigh";
    TestSuiteOrderField["LogSummaryRequestsLow"] = "logSummaryRequestsLow";
    /** @deprecated Renamed to logSummaryRequestsLow. */
    TestSuiteOrderField["LowLogSummaryRequests"] = "lowLogSummaryRequests";
    TestSuiteOrderField["MaxBodyContentLength"] = "maxBodyContentLength";
    /** @deprecated Renamed to maxBodyContentLength. */
    TestSuiteOrderField["MaxContentSize"] = "maxContentSize";
    TestSuiteOrderField["MaxDescriptionLength"] = "maxDescriptionLength";
    TestSuiteOrderField["MaxExternalLinks"] = "maxExternalLinks";
    TestSuiteOrderField["MaxHtmlSize"] = "maxHtmlSize";
    TestSuiteOrderField["MaxLinks"] = "maxLinks";
    TestSuiteOrderField["MaxLoadTime"] = "maxLoadTime";
    TestSuiteOrderField["MaxRedirections"] = "maxRedirections";
    TestSuiteOrderField["MaxTitleWidth"] = "maxTitleWidth";
    TestSuiteOrderField["MaxUrlLength"] = "maxUrlLength";
    TestSuiteOrderField["MaximumCrawlRate"] = "maximumCrawlRate";
    TestSuiteOrderField["MinContentRatio"] = "minContentRatio";
    TestSuiteOrderField["MinDescriptionLength"] = "minDescriptionLength";
    TestSuiteOrderField["MinTitleLength"] = "minTitleLength";
    TestSuiteOrderField["Name"] = "name";
    TestSuiteOrderField["ParentLinkedAt"] = "parentLinkedAt";
    TestSuiteOrderField["PrimaryDomain"] = "primaryDomain";
    TestSuiteOrderField["RenderTimeout"] = "renderTimeout";
    TestSuiteOrderField["RenderWithImages"] = "renderWithImages";
    TestSuiteOrderField["RendererBlockAds"] = "rendererBlockAds";
    TestSuiteOrderField["RendererBlockAnalytics"] = "rendererBlockAnalytics";
    TestSuiteOrderField["RendererJsString"] = "rendererJsString";
    TestSuiteOrderField["RenderingRobotsCheckMode"] = "renderingRobotsCheckMode";
    TestSuiteOrderField["RobotsOverwrite"] = "robotsOverwrite";
    TestSuiteOrderField["SchedulesTotalCount"] = "schedulesTotalCount";
    TestSuiteOrderField["SegmentsTotalCount"] = "segmentsTotalCount";
    TestSuiteOrderField["SelectedWcagLevel"] = "selectedWcagLevel";
    TestSuiteOrderField["SelectedWcagVersion"] = "selectedWcagVersion";
    /** @deprecated Renamed to primaryDomain. */
    TestSuiteOrderField["SitePrimary"] = "sitePrimary";
    /** @deprecated Renamed to testSiteDomain. */
    TestSuiteOrderField["SiteTest"] = "siteTest";
    TestSuiteOrderField["TargetMaxUncrawledUrlsCount"] = "targetMaxUncrawledUrlsCount";
    TestSuiteOrderField["TestSiteDomain"] = "testSiteDomain";
    TestSuiteOrderField["TestsTotalCount"] = "testsTotalCount";
    TestSuiteOrderField["ThinPageThreshold"] = "thinPageThreshold";
    TestSuiteOrderField["TotalSteps"] = "totalSteps";
    TestSuiteOrderField["UpdatedAt"] = "updatedAt";
    TestSuiteOrderField["UseRenderer"] = "useRenderer";
    TestSuiteOrderField["UseRobotsOverwrite"] = "useRobotsOverwrite";
})(TestSuiteOrderField || (TestSuiteOrderField = {}));
export var ThresholdChangeType;
(function (ThresholdChangeType) {
    ThresholdChangeType["Better"] = "Better";
    ThresholdChangeType["Worse"] = "Worse";
})(ThresholdChangeType || (ThresholdChangeType = {}));
export var ThresholdPredicate;
(function (ThresholdPredicate) {
    ThresholdPredicate["GreaterThanOrEqual"] = "GreaterThanOrEqual";
    ThresholdPredicate["LessThan"] = "LessThan";
})(ThresholdPredicate || (ThresholdPredicate = {}));
export var ThresholdType;
(function (ThresholdType) {
    ThresholdType["Absolute"] = "Absolute";
    ThresholdType["Relative"] = "Relative";
})(ThresholdType || (ThresholdType = {}));
export var TrendsComputedType;
(function (TrendsComputedType) {
    TrendsComputedType["AbsDiff"] = "absDiff";
    TrendsComputedType["Diff"] = "diff";
    TrendsComputedType["Last"] = "last";
})(TrendsComputedType || (TrendsComputedType = {}));
export var TrendsTableSortableField;
(function (TrendsTableSortableField) {
    TrendsTableSortableField["ProjectFinishedAt"] = "projectFinishedAt";
    TrendsTableSortableField["ProjectName"] = "projectName";
    TrendsTableSortableField["SegmentName"] = "segmentName";
    TrendsTableSortableField["Trend"] = "trend";
})(TrendsTableSortableField || (TrendsTableSortableField = {}));
export var UrlFileUploadStatus;
(function (UrlFileUploadStatus) {
    UrlFileUploadStatus["Draft"] = "Draft";
    UrlFileUploadStatus["Errored"] = "Errored";
    UrlFileUploadStatus["Processed"] = "Processed";
    UrlFileUploadStatus["Processing"] = "Processing";
})(UrlFileUploadStatus || (UrlFileUploadStatus = {}));
export var UrlFileUploadTypeOrderField;
(function (UrlFileUploadTypeOrderField) {
    UrlFileUploadTypeOrderField["Name"] = "name";
})(UrlFileUploadTypeOrderField || (UrlFileUploadTypeOrderField = {}));
export var UrlRewriteCaseOption;
(function (UrlRewriteCaseOption) {
    UrlRewriteCaseOption["RewriteToLowercase"] = "RewriteToLowercase";
    UrlRewriteCaseOption["RewriteToUppercase"] = "RewriteToUppercase";
})(UrlRewriteCaseOption || (UrlRewriteCaseOption = {}));
export var UserAgentOrderField;
(function (UserAgentOrderField) {
    UserAgentOrderField["Code"] = "code";
    UserAgentOrderField["IsDeprecated"] = "isDeprecated";
    UserAgentOrderField["IsMobile"] = "isMobile";
    UserAgentOrderField["IsPublic"] = "isPublic";
    UserAgentOrderField["Name"] = "name";
})(UserAgentOrderField || (UserAgentOrderField = {}));
export var UserInviteOrderField;
(function (UserInviteOrderField) {
    UserInviteOrderField["CreatedAt"] = "createdAt";
    UserInviteOrderField["Email"] = "email";
    UserInviteOrderField["Id"] = "id";
    UserInviteOrderField["UpdatedAt"] = "updatedAt";
})(UserInviteOrderField || (UserInviteOrderField = {}));
export var UserKeyOrderField;
(function (UserKeyOrderField) {
    UserKeyOrderField["CreatedAt"] = "createdAt";
})(UserKeyOrderField || (UserKeyOrderField = {}));
export var UserOrderField;
(function (UserOrderField) {
    UserOrderField["Auth0UserId"] = "auth0UserId";
    UserOrderField["CreatedAt"] = "createdAt";
    UserOrderField["Email"] = "email";
    UserOrderField["EmailValidatedAt"] = "emailValidatedAt";
    UserOrderField["Id"] = "id";
    UserOrderField["IsEnabled"] = "isEnabled";
    UserOrderField["UpdatedAt"] = "updatedAt";
    UserOrderField["Username"] = "username";
})(UserOrderField || (UserOrderField = {}));
export var WcagLevel;
(function (WcagLevel) {
    WcagLevel["A"] = "A";
    WcagLevel["Aa"] = "AA";
    WcagLevel["Aaa"] = "AAA";
})(WcagLevel || (WcagLevel = {}));
export var WebhookOrderField;
(function (WebhookOrderField) {
    WebhookOrderField["CreatedAt"] = "createdAt";
})(WebhookOrderField || (WebhookOrderField = {}));
export var WebhookTemplateType;
(function (WebhookTemplateType) {
    WebhookTemplateType["MsTeams"] = "MSTeams";
})(WebhookTemplateType || (WebhookTemplateType = {}));
export const AccountInfoFragmentDoc = gql `
    fragment AccountInfo on Account {
  id
  name
  featureFlags {
    name
    enabled
  }
  subscription {
    addons {
      settings {
        name
        code
      }
      prices {
        price
      }
    }
    segmentationAvailable
  }
}
    `;
export const GetNotificationsCountDocument = gql `
    query GetNotificationsCount($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
    reportNotifications: monitorNotifications(
      filter: {passed: {eq: false}, status: {notIn: [Read, WorkingOnIt]}}
    ) {
      totalCount
    }
    healthScoreNotifications: monitorHealthScoreNotifications(
      filter: {passed: {eq: false}, status: {notIn: [Read, WorkingOnIt]}}
    ) {
      totalCount
    }
  }
}
    `;
/**
 * __useGetNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsCountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetNotificationsCountQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(GetNotificationsCountDocument, options);
}
export function useGetNotificationsCountLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(GetNotificationsCountDocument, options);
}
export function useGetNotificationsCountSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(GetNotificationsCountDocument, options);
}
export const GetAllHealthScoresDocument = gql `
    query GetAllHealthScores($crawlId: ObjectID!, $cursor: String) {
  getHealthScoreTrendForCrawl(crawlId: $crawlId, first: 100, after: $cursor) {
    edges {
      node {
        reportCategoryCode
        healthScores {
          healthScore
          createdAt
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
/**
 * __useGetAllHealthScoresQuery__
 *
 * To run a query within a React component, call `useGetAllHealthScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHealthScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHealthScoresQuery({
 *   variables: {
 *      crawlId: // value for 'crawlId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAllHealthScoresQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(GetAllHealthScoresDocument, options);
}
export function useGetAllHealthScoresLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(GetAllHealthScoresDocument, options);
}
export function useGetAllHealthScoresSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(GetAllHealthScoresDocument, options);
}
export const GetAllHealthScoresSegmentDocument = gql `
    query GetAllHealthScoresSegment($crawlId: ObjectID!, $segmentId: ObjectID!, $cursor: String) {
  getHealthScoreTrendForCrawlSegment(
    crawlId: $crawlId
    segmentId: $segmentId
    first: 100
    after: $cursor
  ) {
    edges {
      node {
        reportCategoryCode
        healthScores {
          healthScore
          createdAt
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
/**
 * __useGetAllHealthScoresSegmentQuery__
 *
 * To run a query within a React component, call `useGetAllHealthScoresSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHealthScoresSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHealthScoresSegmentQuery({
 *   variables: {
 *      crawlId: // value for 'crawlId'
 *      segmentId: // value for 'segmentId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAllHealthScoresSegmentQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(GetAllHealthScoresSegmentDocument, options);
}
export function useGetAllHealthScoresSegmentLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(GetAllHealthScoresSegmentDocument, options);
}
export function useGetAllHealthScoresSegmentSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(GetAllHealthScoresSegmentDocument, options);
}
export const AccountGuardDocument = gql `
    query AccountGuard($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
  }
}
    `;
/**
 * __useAccountGuardQuery__
 *
 * To run a query within a React component, call `useAccountGuardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountGuardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountGuardQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountGuardQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(AccountGuardDocument, options);
}
export function useAccountGuardLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(AccountGuardDocument, options);
}
export function useAccountGuardSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(AccountGuardDocument, options);
}
export const CrawlGuardDocument = gql `
    query CrawlGuard($crawlId: ObjectID!) {
  getCrawl(id: $crawlId) {
    id
  }
}
    `;
/**
 * __useCrawlGuardQuery__
 *
 * To run a query within a React component, call `useCrawlGuardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrawlGuardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrawlGuardQuery({
 *   variables: {
 *      crawlId: // value for 'crawlId'
 *   },
 * });
 */
export function useCrawlGuardQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(CrawlGuardDocument, options);
}
export function useCrawlGuardLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(CrawlGuardDocument, options);
}
export function useCrawlGuardSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(CrawlGuardDocument, options);
}
export const ProjectGuardDocument = gql `
    query ProjectGuard($projectId: ObjectID!) {
  getProject(id: $projectId) {
    id
  }
}
    `;
/**
 * __useProjectGuardQuery__
 *
 * To run a query within a React component, call `useProjectGuardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGuardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGuardQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectGuardQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(ProjectGuardDocument, options);
}
export function useProjectGuardLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(ProjectGuardDocument, options);
}
export function useProjectGuardSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(ProjectGuardDocument, options);
}
export const AcceptTermsAndConditionsDocument = gql `
    mutation AcceptTermsAndConditions {
  acceptTermsAndConditions {
    termsAgreed
    id
  }
}
    `;
/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(AcceptTermsAndConditionsDocument, options);
}
export const GetAccountsDocument = gql `
    query GetAccounts($filter: String!) {
  internalGetAccounts(
    filter: {name: {contains: $filter}, active: {eq: true}}
    orderBy: {direction: ASC, field: createdAt}
    first: 50
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(GetAccountsDocument, options);
}
export function useGetAccountsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(GetAccountsDocument, options);
}
export function useGetAccountsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(GetAccountsDocument, options);
}
export const AdminAdditionalAccountDocument = gql `
    query AdminAdditionalAccount($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
    name
    packagePlan
    accountManagers
    availableCredits
    active
    aiFeaturesEnabled
    featureFlags {
      name
      enabled
    }
    subscription {
      addons {
        settings {
          name
          code
        }
        prices {
          price
        }
      }
      currentBillingPeriod {
        start
        end
      }
      plan {
        name
        status
        minCommitmentPeriod
        period
      }
      status
      segmentationAvailable
    }
    primaryAccountPackage {
      credits
      creditsSiteSpeed
      creditsAccessibility
    }
    availableApps: subscription {
      automateAvailable
      analyzeAvailable
      impactAvailable
      monitorAvailable
    }
    customLogo
    maxCrawlRate
    limitLevelsMax
    limitPagesMax
    maxCustomReportsPerProject
    disableUpselling
  }
}
    `;
/**
 * __useAdminAdditionalAccountQuery__
 *
 * To run a query within a React component, call `useAdminAdditionalAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAdditionalAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAdditionalAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAdminAdditionalAccountQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(AdminAdditionalAccountDocument, options);
}
export function useAdminAdditionalAccountLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(AdminAdditionalAccountDocument, options);
}
export function useAdminAdditionalAccountSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(AdminAdditionalAccountDocument, options);
}
export const SessionDocument = gql `
    query Session($cursor: String) {
  me {
    id
    email
    username
    firstName
    jobTitle
    lastName
    permissions {
      code
    }
    ssoClientId
    termsAgreed
    adminDisabled: userData(key: "adminDisabled")
    timeZone: userData(key: "timeZone")
    accountsUsers(after: $cursor, first: 100) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          role {
            name
            code
          }
          account {
            ...AccountInfo
            packagePlan
            customLogo
            maxCrawlRate
            limitLevelsMax
            limitPagesMax
            maxCustomReportsPerProject
            accountManagers
            availableCredits
            active
            aiFeaturesEnabled
            subscription {
              currentBillingPeriod {
                start
                end
              }
              plan {
                name
                status
                minCommitmentPeriod
                period
              }
              status
            }
            primaryAccountPackage {
              credits
              creditsSiteSpeed
              creditsAccessibility
            }
            availableApps: subscription {
              automateAvailable
              analyzeAvailable
              impactAvailable
              monitorAvailable
            }
            contractTerminationDate
            contractStartDate
            disableUpselling
          }
        }
      }
    }
  }
  sharelink {
    id
    account {
      ...AccountInfo
    }
  }
}
    ${AccountInfoFragmentDoc}`;
/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSessionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(SessionDocument, options);
}
export function useSessionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(SessionDocument, options);
}
export function useSessionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useSuspenseQuery(SessionDocument, options);
}
export const SetAdminDisabledDocument = gql `
    mutation SetAdminDisabled($disabled: JSON!) {
  setUserData(input: {key: "adminDisabled", value: $disabled})
}
    `;
/**
 * __useSetAdminDisabledMutation__
 *
 * To run a mutation, you first call `useSetAdminDisabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdminDisabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdminDisabledMutation, { data, loading, error }] = useSetAdminDisabledMutation({
 *   variables: {
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useSetAdminDisabledMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(SetAdminDisabledDocument, options);
}
export const SetTimeZoneDocument = gql `
    mutation SetTimeZone($timeZone: JSON!) {
  setUserData(input: {key: "timeZone", value: $timeZone})
}
    `;
/**
 * __useSetTimeZoneMutation__
 *
 * To run a mutation, you first call `useSetTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTimeZoneMutation, { data, loading, error }] = useSetTimeZoneMutation({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useSetTimeZoneMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(SetTimeZoneDocument, options);
}
